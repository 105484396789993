import React from 'react'
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography, Button, Badge, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        left: -18,
        top: 10,
        border: `2px solid ${theme.palette.background.paper}`,
        width: 10,
        height: 10,
        borderRadius: '100%'
    },
}));

export const IntegrationCard = ({ data }) => {
    const navigate = useNavigate();
    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                m: 1
            }}
        >
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 3
                    }}
                >
                    <Avatar
                        alt="Product"
                        src={data.image}
                        variant="square"
                    />
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                >
                    {data.name}
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="body1"
                >
                    {data.description}
                </Typography>
            </CardContent>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <Box sx={{ p: 2 }}>
                <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Button variant="text" onClick={() => { data?.url ? navigate(data?.url) : navigate(`/integration/${data?.id}`) }}>Edytuj</Button>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Typography
                            color="textSecondary"
                            display="inline"
                            sx={{ pl: 1 }}
                            variant="body2"
                        >

                            {/* <StyledBadge color={(data.isActive === true) ? 'success' : 'error'} badgeContent=" " variant="dot" >
                                {(data.isActive === true) ? 'Aktywny' : 'Nieaktywny'}
                            </StyledBadge> */}


                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}


