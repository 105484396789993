import { token, host } from '../Connnection';
import { toast } from 'react-toastify';

export default function createNewUser(firstname, lastname, email, password, role) {
    const dataObject =
    {
        firstName: firstname,
        lastName: lastname,
        password: password,
        email: email,
        roleName: role
    };
    fetch(`${host}/api/Account/Register`, {
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })
        .then((response) => {
            if (response.ok) {
                toast.success("Dodano nowego użytkownika 🎉");
            } else {
                toast.warn('Wprowadzony adres email już istnieje');
            }
        })
}