import { styled } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

export const StyledHeadCell = styled((props) => <TableCell {...props} />)(
    {
        color: "#374151",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: ".8rem",
    },
);
