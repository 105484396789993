import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import PopupTable from './PriceHisotryGrid'

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function DataGridPopup({ data, handleClose, open, productName, websiteId }) {
    return (
        <Dialog
            onClose={handleClose}
            open={open}
            // PaperComponent={PaperComponent}
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Hisotria cen - {productName}
            </DialogTitle >
            <DialogContent dividers >
                <PopupTable products={data} websiteId={websiteId} />
            </DialogContent>
        </Dialog >
    )
}
