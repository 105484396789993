
const categoryList = {
    id: 'root',
    label: 'Kategoria główna',
    isActive: true,
    isChecked: true,
    child: [
        {
            id: '1',
            label: 'Zagęszczarki',
            attribute: 'zageszczarki',
            isActive: true,
            isChecked: true,
            child: [
                {
                    id: '2',
                    label: 'Zagęszczarki jednokierunkowe',
                    attribute: 'zageszczarki_jednokierunkowe',
                    isActive: true,
                    isChecked: true,
                },
                {
                    id: '3',
                    label: 'Zagęszczarki dwukierunkowe',
                    attribute: 'zageszczarki_dwukierunkowe',
                    isActive: true,
                    isChecked: false,
                    child: [
                        {
                            id: '4',
                            label: 'Zagęszczarki 200 kg',
                            attribute: 'zageszczarki_200kg',
                            isActive: true,
                            isChecked: true,
                        },
                        {
                            id: '5',
                            label: 'Zagęszczarki 300 kg',
                            attribute: 'zageszczarki_300kg',
                            isActive: true,
                            isChecked: false,
                        }
                    ]
                }
            ]
        },
        {
            id: '6',
            label: 'Osuszacze',
            attribute: 'Osuszacze',
            isActive: true,
            isChecked: true,
            child: [
                {
                    id: '7',
                    label: 'Osuszacze absorbsyjne',
                    attribute: 'Osuszacze-absorpcyjne',
                    isActive: true,
                    isChecked: true,
                },
                {
                    id: '8',
                    label: 'Remko',
                    attribute: 'Remko',
                    isActive: false,
                    isChecked: false,
                }
            ]
        }
    ]
}



export {
    categoryList,
}