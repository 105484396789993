import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { closeModal } from './addNewBundleBtn'
import { style } from '../../common/modalStyle'
import BundleItemInfo from './bundleStepperContainer'

export default function TransitionsModal(state) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(state.isOpen);
    }, [state.isOpen])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={closeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>


                    <Box sx={style}>
                        <Box sx={{ backgroundColor: '#1e1f21', color: '#fff', p: 2 }}>
                            <Typography variant="h6" component="h2">
                                Dodaj nowy zestaw
                            </Typography>
                        </Box>
                        <Box sx={{ p: 4 }}>
                            <BundleItemInfo isOpen={open}/>
                        </Box>

                    </Box>

                </Fade>
            </Modal>
        </div>
    );
}
