
import { bundleHost } from '../Connnection';
import { fetchLoading } from '../../components/productBundles/productBundlesList'

export default async function getBundleList(value, size, pageNumber, fetchLoad) {

  try {
    let res = await fetch(`${bundleHost}/api/app/bundle?Filter=${value}${(size) ? `&MaxResultCount=${size}` : ''}${(pageNumber) ? `&SkipCount=${pageNumber}` : ''}`, {
      method: 'GET',
    })
    if (fetchLoad === true) {
      if (res.status === 200) {
        setTimeout(() => {
          fetchLoading(false)
        }, 200)
      } else {
        fetchLoading(true)
      }
    }
    return await res.json();

  } catch (error) {
    console.log(error);
  }

}