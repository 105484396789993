import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import getAllSuppliers from '../../../../Services/suppliers/getAllSuppliers'




export default function SuppliersList(props) {
    let [types, setTypes] = React.useState([])
    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState(props.data);

    React.useEffect(() => {
        props.suppliers(value)
    }, [value])


    React.useEffect(() => {
        let suppliers = []
        getAllSuppliers().then((data) => {

            data.forEach((d) => {
                let s = {
                    'id': d.id,
                    'label': d.name
                }
                suppliers.push(s)
            })
        })
        setTypes(suppliers)
    }, [])

  
    return (
        <Autocomplete
            disablePortal
            id="suppliersList"
            options={types}
            ListboxProps={{ style: { maxHeight: '200px' } }}
            // sx={{ minWidth: 300 }}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}

            value={(props.action === 'edit') ? inputValue : undefined}
            isOptionEqualToValue={(props.action === 'edit') ? (option, value) => option.label === value : undefined}
            inputValue={(props.action === 'edit') ? inputValue : undefined}
            
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={(option.id) ? option.id : 0}>
                        {option.label}
                    </li>
                );
            }}
            renderInput={(params) => <TextField  required {...params} label="Wybierz dostawcę dla tej grupy rabatowej" />}
        />
    );
}

