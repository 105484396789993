import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';


const SaveViewButton = (props) => {
    const { label, onLabelChange, onSubmit, isValid } = props;
    const [isAddingView, setIsAddingView] = React.useState(false);

    const handleSubmitForm = (e) => {
        onSubmit();
        setIsAddingView(false);
        e.preventDefault();
    };

    return (
        <React.Fragment>
            <Button
                endIcon={<AddIcon />}
                size="small"
                onClick={() => setIsAddingView(true)}
            >
                Zapisz widok
            </Button>
            <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
                <form onSubmit={handleSubmitForm}>
                    <DialogTitle>Dodaj nowy widok</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            value={label}
                            onChange={onLabelChange}
                            margin="dense"
                            size="small"
                            label="Wprowadź nazwę"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={() => setIsAddingView(false)}>
                            Anuluj
                        </Button>
                        <Button type="submit" disabled={!isValid}>
                            Utwórz widok
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
};


export { 
    SaveViewButton
}