import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const EditSupplierFields = ({ handleChange, data }: any) => (
  <Box
    component="form"
    sx={{
      "& > :not(style)": { m: 1, width: "25ch" },
    }}
    noValidate
    autoComplete="off"
  >
    <TextField
      required
      onChange={handleChange}
      value={data.name}
      name="name"
      size="small"
      label="Nazwa"
      placeholder="Nazwa"
    />
    <TextField
      required
      onChange={handleChange}
      value={data.nip}
      name="nip"
      size="small"
      label="Nip"
      placeholder="Nip"
    />
  </Box>
);

export default EditSupplierFields;
