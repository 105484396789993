import { token, host } from '../Connnection';
import { toast } from 'react-toastify';

export default async function editSupplier(dataObject) {
    try {
        let res = await fetch(`${host}/api/Supplier`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObject)
        })
        return await res.json();
    } catch (error) {
        console.log(error);
    }
}
