import React from 'react'
import { TextField, Radio, Switch, FormGroup, FormControlLabel } from '@mui/material'

const fieldProps = {
    fullWidth: true,
    sx: {
        my: 1,
        fontSize: ".8rem"
    },
    // variant: "contained",
    size: "small",
    InputProps: { style: { fontSize: '.9rem' } },
    InputLabelProps: { style: { fontSize: '.9rem' } }
}

const CustomTextField = React.memo(({ label, val, name, type, change }) => (
    <TextField
        {...fieldProps}
        label={label}
        value={(val) ? val : ''}
        type={type}
        name={name}

        onChange={(e) => { change(e, type) }}
    />
))

const CustomTextArea = React.memo(({ type, label, val, name, change }) => (
    <TextField
        {...fieldProps}
        label={label}
        value={(val) ? val : ''}
        name={name}
        multiline
        rows={4}
        onChange={(e) => { change(e, type) }}
    />
))

const CustomCheckBox = React.memo(({ type, label, val, name, change }) => (
    <FormGroup>
        <FormControlLabel control={<Switch
            sx={{ my: 1 }}
            checked={(val) ? val : false}
            name={name}
            size="small"
            onChange={(e) => { change(e, type) }}
        />} label={label} />
    </FormGroup>
))

const CustomRadio = React.memo(({ type, val, name, change, label }) => (
    <Radio
        label={label}
        sx={{ my: 1 }}
        checked={(val) ? val : false}
        name={name}
        onChange={(e) => { change(e, type) }}
    />
))

const renderFields = (field, exmapleData, handleChange) => {
    const props = {
        label: field?.label,
        val: exmapleData?.[field?.attribute],
        type: field?.type,
        name: field?.attribute,
        change: (e) => { handleChange(e, field?.type) }
    }

    // eslint-disable-next-line default-case
    switch (field?.component) {
        case 'text-field':
            return <CustomTextField {...props} />;
        case 'text-area':
            return <CustomTextArea {...props} />;
        case 'checkbox':
            return <CustomCheckBox {...props} />;
        case 'radio':
            return <CustomRadio {...props} />
    }
}

export {
    renderFields
}