import React from 'react';
import Router from './routes';
import license from './dataGridLicense';
import AlertsContext from './context/alerts'
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { plPL } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/pl';

const queryClient = new QueryClient()

function App() {
  window.scrollTo(0, 1);

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <AlertsContext>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
            adapterLocale="pl"
          >
            <div className="wrapper">
              <Router />
            </div>
          </LocalizationProvider>
        </AlertsContext>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
