import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid";
import { ProductManagoApiService } from "../ApiServices/ApiService";
import { IDataService } from "./IDataService";
import { Filter } from '../../models/Filter'
import { Checked } from "../../models/Checked";

export class ProductManagoDataService implements IDataService {
    apiService: any;

    constructor() {
        this.apiService = new ProductManagoApiService();
    }

    capitalizeFirstLetter(str: string): string {
        return str?.charAt(0).toUpperCase() + str?.slice(1)
    }

    translateOperator(operator: string | undefined): string {
        switch (operator) {
            case 'contains':
                return 'Contain'
            case 'equals':
                return 'Exact'
        }
        return 'Contain'
    }

    filterObject(filterValue: GridFilterModel): Filter[] {
        let filters: Filter[] = [];
        if (filterValue) {
            (filterValue.items?.length > 0) &&
                filterValue.items.forEach((data: GridFilterItem) => {
                    let object: Filter = {
                        column: this.capitalizeFirstLetter(data.columnField),
                        filter: data.value,
                        filterType: this.translateOperator(data.operatorValue)
                    };
                    filters.push(object)
                })
        }
        return filters
    }

    toStringArray = (columns: any) => {
        let stringArray: string[] = []
        columns?.forEach((d: any) => {
            (d.checked) &&
                stringArray.push(d.field)
        })

        return stringArray
    }


    async ExportProducts(params: GridFilterModel, searchValue: string, checked: Checked, columns: any): Promise<void> {
        let product = await this.apiService.ExportProducts({
            name: (checked?.name) ? searchValue : '',
            symbol: (checked?.symbol) ? searchValue : '',
            additionalSymbol: (checked?.additionalSymbol) ? searchValue : '',
            brand: (checked?.brand) ? searchValue : '',
            ean: (checked?.ean) ? searchValue : '',
            createdSource: '',
            exactSearch: checked?.exactSearch,
            filters: this.filterObject(params),
            exportColumnNames: this.toStringArray(columns)
        })
        return this.exportToCsv(product)

    }


    exportToCsv(data: string): void {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', 'export-products.csv');
        a.click()
    }

}