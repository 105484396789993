
import { token, host, userID } from '../../../../Services/Connnection';

async function SET_ColumnSettings(object = {}) {
    let res = await fetch(`${host}/api/ColumnSettings`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(object)
    })
    return await res.json();
}

function objectToFetch(userID, type, group, name, pinned, order, visible, columnWidth, sort) {
    this.userID = userID
    this.type = type
    this.group = group
    this.name = name
    this.pinned = pinned
    this.order = order
    this.visible = visible
    this.columnWidth = columnWidth
    this.sort = sort
}

const createObject = (view, pageType) => {
    let label = view?.label;
    let orderedColumns = view?.value?.columns?.orderedFields
    let pagination = view?.value?.pagination?.pageSize
    let pinnedLeft = view?.value?.pinnedColumns?.left
    let pinnedRight = view?.value?.pinnedColumns?.right
    let columnVisibility = view?.value?.columns?.columnVisibilityModel
    let columnWidth = view?.value?.columns?.dimensions
    let sorting = view?.value?.sorting?.sortModel
    let cols = [];
    let count = 0;

    orderedColumns?.forEach(name => {
        count++;
        cols.push(new objectToFetch(userID, pageType, label, name, null, count, columnVisibility[name], columnWidth[name]?.width, null))
    });


    let page = new objectToFetch(userID, pageType, label, '@pagination', null, (pagination !== undefined) ? pagination : 100, null, null, null);
    cols.push(page)

    pinnedLeft?.forEach(pl => {
        let findL = cols?.find(element => element.name === pl)
        if (findL !== undefined)
            findL.pinned = findL && 'left'
    })

    pinnedRight?.forEach(pr => {
        let findR = cols?.find(element => element.name === pr)
        if (findR !== undefined)
            findR.pinned = findR && 'right'
    })

    sorting?.forEach(sm => {
        let sort = cols?.find(element => element.name === sm.field)
        if (sort !== undefined)
            sort.sort = sort && sm.sort
    })

    SET_ColumnSettings({
        'columns': cols
    })
}

export {
    createObject
}