import { Box, Container, Grid, Typography } from '@mui/material';
import AccountProfile from '../userInfo/userAvatar'
import AccountProfileDetails from '../userInfo/userFields'
import { useEffect, useState } from 'react';
import { token, host } from '../../Services/Connnection';
import { useNavigate } from 'react-router-dom'

function Account() {
  const [notes, setNotes] = useState({
    profilePicture: "",
    firstName: "",
    lastName: "",
    roleName: ""
  });
  useNavigate();
  const currentURL = window.location.href
  let url = new URL(currentURL);
  let search_params = url.searchParams;
  let firstname = search_params.get('changeData');
  let imageName = search_params.get('imageName');

  const fetchData = () => {

    let retrievedObject = localStorage.getItem('accessStorage')
    let storageData = JSON.parse(retrievedObject)
    fetch(`${host}/api/User/${storageData.id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setNotes({
          profilePicture: data.profilePicture,
          firstName: data.firstName,
          lastName: data.lastName,
          roleName: data.roleName,
          email: data.email
        })
      });
  };

  useEffect(() => {
    fetchData([])
  }, [firstname, imageName]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
          >
          </Typography>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <AccountProfile dataObj={notes} />
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xs={12}
            >
              <AccountProfileDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default Account;