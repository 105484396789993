import * as React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context';
import CustomToolbar from './datagrid.toolbar'

export default function SymbolsList() {
    const { newPrinciple, HandleRowDelete, handleSelectedSymbols } = React.useContext(CeneoPrincipleContext);


    const columns = [
        { field: 'symbolProductId', headerName: 'ID', width: 90 },
        {
            field: 'symbol',
            headerName: 'Symbol',
        },
        {
            field: 'deleteSymbol',
            headerName: 'Usuń symbol',
            renderCell: (params) => <HandleRowDelete {...params} />
        }
    ];


    return (
        <div style={{ height: 520, width: '100%', paddingTop: '20px' }} >
            <DataGridPro
                rows={newPrinciple?.symbols}
                columns={columns}
                getRowId={row => row.symbolProductId}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = newPrinciple?.symbols.filter((row) =>
                        selectedIDs.has(row.symbolProductId),
                    );
                    handleSelectedSymbols(selectedRows);
                }}
                components={{
                    Toolbar: CustomToolbar,
                }}
                checkboxSelection
                disableSelectionOnClick
            />
        </div>
    )
}
