/* eslint-disable default-case */
export const getAlert = (actionType) => {
    switch (actionType) {
        case 'Duplicate':
            return 'warning'
        case 'Create':
            return 'success'
        case 'Update':
            return 'info'
        case 'Error':
            return 'error'
    }
}

export const getHeaderColor = (data) => {
    switch (getAlert(data)) {
        case 'error':
            return 'rgb(253, 237, 237)'
        case 'warning':
            return 'rgb(255, 244, 229)'
        case 'success':
            return 'rgb(237, 247, 237)'
        case 'info':
            return 'rgb(229, 246, 253)'
    }
}