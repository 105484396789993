import { IExportColumns } from "./IExportColumns"

let columns: IExportColumns[] = [
    {
        headerName: "Id produktu",
        field: "id",
        checked: false
    },
    {
        headerName: "Edytuj",
        field: "ProductEdit",
        checked: false
    },
    {
        headerName: "Źródło utworzenia",
        field: "CreatedSource",
        checked: false
    },
    {
        headerName: `Symbol GK`,
        field: "Symbol",
        checked: true
    },
    {
        headerName: "Dodatkowy Symbol GK",
        field: 'AdditionalSymbol',
        checked: false
    },
    {
        headerName: `Nazwa produktu`,
        field: "Name",
        checked: false
    },
    {
        headerName: `EAN`,
        field: "Ean",
        checked: false
    },
    {
        headerName: `Symbol CN`,
        field: "CnSymbol",
        checked: false
    },
    {
        headerName: `Symbol PKWIU`,
        field: "PkwiuSymbol",
        checked: false
    },
    {
        headerName: `Komentarz`,
        field: "Comments",
        checked: false
    },
    {
        headerName: "Marka",
        field: "Brand.Name",
        checked: false
    },
    {
        headerName: "Kategoria Asortymentowa",
        field: "Categories",
        checked: false
    },
    {
        headerName: `Cena katalogowa netto`,
        field: "ListPrice.Net",
        checked: false
    },
    {
        headerName: `Cena katalogowa brutto`,
        field: "ListPrice.Gross",
        checked: false
    },
    {
        headerName: `Cena katalogowa - Kod waluty`,
        field: "ListPrice.Currency.IsoCode",
        checked: false
    },
    {
        headerName: `Cena katalogowa - Ostatnia zmiana`,
        field: "ListPrice.LastModified",
        checked: false
    },
    {
        headerName: `Cena hurtowa netto`,
        field: 'DealersPrice.Net',
        checked: false
    },
    {
        headerName: `Cena hurtowa brutto`,
        field: "DealersPrice.Gross",
        checked: false
    },
    {
        headerName: `Cena hurtowa - kod waluty`,
        field: "DealersPrice.Currency.IsoCode",
        checked: false
    },
    {
        headerName: `Cena hurtowa - Ostatnia zmiana`,
        field: "DealersPrice.LastModified",
        checked: false
    },
    {
        headerName: `Stawka vat`,
        field: "Tax",
        checked: false
    },
    {
        headerName: `Produkt wycofany`,
        field: "IsWithdrawn",
        checked: false
    },
    {
        headerName: "Zamiennik",
        field: "ReplacementsText",
        checked: false
    },
    {
        headerName: `PD: NIP dostawcy`,
        field: "DefaultSuppliedProduct.Supplier.Nip",
        checked: false
    },
    {
        headerName: `PD: Nazwa dostawcy`,
        field: "DefaultSuppliedProduct.Supplier.Name",
        checked: false
    },
    {
        headerName: `PD: Symbol produktu u dostawcy`,
        field: "DefaultSuppliedProduct.SupplierSymbol",
        checked: false
    },
    {
        headerName: `PD: Grupa Rabatowa - Nazwa`,
        field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.Name",
        checked: false
    },
    {
        headerName: `PD: Grupa Rabatowa - Wartość rabatu podstawowego`,
        field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.PercentageValue",
        checked: false
    },
    {
        headerName: `PD: Sposób obliczania ceny zakupu`,
        field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.CalculationType.Name",
        checked: false
    },
    {
        headerName: `PD: Cena Zakupu Netto`,
        field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Net",
        checked: false
    },
    {
        headerName: `PD: Cena Zakupu Brutto`,
        field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Gross",
        checked: false
    },
    {
        headerName: "Data utworzenia",
        field: "Created",
        checked: false
    }
]

export {
    columns
}