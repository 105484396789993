import React from 'react'
import HistoryIcon from '@mui/icons-material/History';
import { IconButton, Typography } from '@mui/material';
import { ProductHunterDataService } from '../../../Pages/ProductHunter/Services/DataServices/DataService.tsx';
import DataGridPopup from './PriceHisotryPopup'
import { useParams } from 'react-router';

export default function DataGridPriceHistoryPopup(params) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([])
  let queryParam = useParams();
  let websiteId = queryParam?.id || '0';

  const DataService = React.useMemo(() => {
    return new ProductHunterDataService();
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
    let active = true;

    (async () => {
      let apiHisotoricalData = await DataService.HistoricalData(params.row.id)
      if (!active) {
        return
      }
      setData(apiHisotoricalData)
    })()
    return () => {
      active = false
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {(params.row?.historyEntriesCount !== 0) && (
        <IconButton onClick={handleClickOpen} aria-label="delete" size="small">
          <>
            <HistoryIcon fontSize="medium" />
            <Typography variant="overline" >
              ({params.row?.historyEntriesCount})
            </Typography>
          </>
        </IconButton>
      )}

      <DataGridPopup data={data}
        handleClose={handleClose}
        open={open}
        productName={params.row.name}
        websiteId={websiteId} />
    </>
  )
}
