import React from 'react'
import {
    TextField
} from '@mui/material';


const SearchBar = (props) => {
    const { search } = props

    const handleKeyDown = (ev) => {
        if (ev.key === 'Enter') search(ev)
    }

    return (
        <TextField
            label="Wyszukaj produkt"
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            fullWidth
            sx={{background: "#fff", borderRadius: '10px'}}
        />
    )
}

export default SearchBar