import getData from '../../Services/wareHouse/getSearchProduct';

export default async function renderData(value) {
  var actualProd = [];
  let getDataProd = await getData(value);
  let dataProducts = getDataProd.items;
  dataProducts.forEach(prod => {
    let row = {};
    row.id = prod.id;
    row.additionalSymbol = prod.additionalSymbol
    row.brand = prod.brand
    row.warehouseDataProcessed = prod.warehouseDataProcessed
    row.createdDate = prod.createdDate
    row.description = prod.description
    row.ean = prod.ean
    row.isWithdrawn = prod.isWithdrawn
    row.name = prod.name
    row.symbol = prod.symbol
    actualProd.push(row);
  });
  return actualProd;
}