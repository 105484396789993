import {
  DataGridPro,
  GridRowClassNameParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import React from "react";
import { DataGridModelData } from "../../../Pages/DiscountingSystem/SupplierList/models/DataGridModelData";
import { Supplier } from "../../../Pages/DiscountingSystem/SupplierList/models/Supplier";
import AddNewRecord from "./DataGridToolBar";

export default function SupplierListGrid({
  data,
  handleAdd,
}: {
  data: DataGridModelData;
  handleAdd: (data: Supplier) => void;
}) {
  let apiRef = useGridApiRef();
  return (
    <DataGridPro
      apiRef={apiRef}
      rows={data.rows}
      columns={data.cols}
      rowHeight={35}
      getRowClassName={(_params: GridRowClassNameParams<any>) => `odd-gray`}
      components={{
        Toolbar: AddNewRecord,
      }}
      componentsProps={{
        toolbar: { handleAdd, apiRef },
      }}
    />
  );
}
