import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography
} from '@mui/material';

let formatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
});

const Cell = ({ data }) => {
    const { id, stock, stockNetPrice, warehouseName } = data
    return (
        <TableRow
            key={id}
        >
            <TableCell>{warehouseName}</TableCell>
            <TableCell>{stock}</TableCell>
            <TableCell>{formatter.format(stockNetPrice)}</TableCell>
        </TableRow>
    )
}

const StockManagementListComponent = ({ data }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell>Ilość</TableCell>
                        <TableCell>Cena</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data?.stocks?.length !== 0) ? (
                        data?.stocks?.map((stock) => (
                            <Cell key={stock?.id} data={stock} />
                        ))
                    ) : (
                        <Box>
                            <Typography component="p" sx={{ p: 2 }}>Nie znaleziono wyników 😟</Typography>
                        </Box>
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export {
    StockManagementListComponent
}