import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { isOpen } from '../tradeTermLayout';
import { getData } from '../tradeTermLayout';

const EditSupplierButton = React.memo((params) => {
    const handleClick = (event) => {
        isOpen(true)
        getData(params.row)
    }
    return (
        <IconButton onClick={handleClick} size="small"><EditIcon /></IconButton>
    )
})

const toPercent = (params) => {
    console.log(params.row.percentageValue)
    return (params.row.percentageValue === 0) ? 'Towar na zapytanie' : `${params.row.percentageValue}%`;
}

const translateCalcType = (params) => {
    switch (params.row['calculationType']) {
        case 'DealersPriceRebate':
            return 'Od ceny hurtowej'
        case 'ListPriceRebate':
            return 'Od ceny katalogowej'
    }
}


export const columns = [
    {
        field: 'edit',
        headerName: 'Edytuj',
        width: 80,
        renderCell: (params) => <EditSupplierButton {...params} />,
    },
    {
        field: 'name',
        headerName: 'Nazwa grupy rabatowej',
        width: 160,
    },
    {
        field: 'supplier.name',
        headerName: 'Nazwa dostawcy',
        width: 400,
    },
    {
        field: 'supplier.nip',
        headerName: 'Nip dostawcy',
        width: 160,
    },
    {
        field: 'calculationType.name',
        headerName: 'Sposób obliczania rabatu',
        width: 160,
        valueGetter: translateCalcType
    },
    {
        field: 'percentageValue',
        headerName: 'Wartość rabatu',
        width: 160,
        valueGetter: toPercent
    }


]