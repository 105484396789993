import * as React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import renderCount from '../../../data/productBundles/getBundleCount'
import { SkeletonBoxes } from './skeletonBoxes';


export function CreatedBundles(props) {
  const [bundleCount, setBundleCount] = React.useState([]);
  const [loading, setLoading] = React.useState(true)


  React.useEffect(() => {
    renderCount('', 1, 1, false).then((data) => {
      setBundleCount(data)
    })
  }, [])

  React.useEffect(() => {
    if (bundleCount.length !== 0) {
      setLoading(false)
    }
  }, [bundleCount])

  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
       {loading === false ? (
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item 
          sx={{ width: '70%'}}
          >
            <Grid sx={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"

              >
                Ilość utworzonych zestawów produktowych
              </Typography>
            </Grid>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {bundleCount}
            </Typography>
          </Grid>
          <Grid item 
          >
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: 56,
                width: 56
              }}
            >
              <WorkspacesIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {/* <ArrowDownwardIcon color="error" />
        <Typography
          color="error"
          sx={{
            mr: 1
          }}
          variant="body2"
        >
          12%
        </Typography> */}
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Symulator stanów magazynowych zestawów produktowych

          </Typography>
        </Box>
      </CardContent>
      ) : (
        <Box sx={{ width: '100%' }}>
          <SkeletonBoxes />
        </Box>
      )}
    </Card>
  )
};