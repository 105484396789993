import { GridColDef } from "@mui/x-data-grid";
import { ISupplierListConfig } from "../../config/ISupplierListConfig";
import { SupplierListConfig } from "../../config/SupplierListConfig";
import { Supplier } from "../../models/Supplier";
import { ISupplierListApi } from "../ApiService/ISupplierListApi";
import { SupplierListApiService } from "../ApiService/SupplierListApiService";
import { ISupplierListDataService } from "./ISupplierListDataService";

export class SupplierListDataService implements ISupplierListDataService {
  apiService: ISupplierListApi;
  supplierListConfig: ISupplierListConfig;
  static suppliers: Supplier[] = [];

  constructor() {
    this.apiService = new SupplierListApiService();
    this.supplierListConfig = new SupplierListConfig();
  }

  searchSuppliers(value: string, suppliers: Supplier[]): Supplier[] {
    let filteredList = suppliers.filter((e) => {
      let byName = e.name.toLowerCase();
      let byNip = e.nip.toLowerCase();
      return (
        byName.includes(value.toLowerCase()) ||
        byNip.includes(value.toLowerCase())
      );
    });
    return filteredList;
  }

  getColumnsConfig(): GridColDef<Supplier>[] {
    return this.supplierListConfig.columns!;
  }

  addSupplier(_supplier: Supplier): void {
    let suppliers = SupplierListDataService.suppliers;
    this.apiService.PostSupplier(_supplier);
    _supplier = { ..._supplier, id: suppliers[suppliers.length - 1]?.id! + 1 };
    SupplierListDataService.suppliers = [...suppliers, _supplier];
  }

  refreshSupplierList() {
    return SupplierListDataService.suppliers;
  }

  async getSuppliers(_value: string): Promise<Supplier[]> {
    let supplierList = await this.apiService.GetSupplier();
    SupplierListDataService.suppliers = supplierList;
    return this.searchSuppliers(_value, supplierList);
  }

  async editSupplier(supplier: Supplier): Promise<any> {
    return await this.apiService.PutSupplier(supplier);
  }

  async supplierListData() {}
}
