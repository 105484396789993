import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import React from "react";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

export const fieldComponents = {
  Decimal: {
    component: (props: any) => {
      return <TextField type="number" variant="standard" name="value" helperText="Wprowadź liczbę" value={+props.values?.value}  {...props} />;
    },
    validate: (value: any) => {
      return typeof value === 'string';
    },
  },
  ListOfStrings: {
    component: (props: any) => {
      return <TextField type="text" variant="standard" name="value" helperText="Wprowadź tekst" value={props.values?.value} {...props} />;
    },
    validate: (value: any) => {
      return typeof value === 'string';
    },
  },
  Integer: {
    component: (props: any) => <TextField type="text" variant="standard" name="value" helperText="Wprowadź liczbę" value={+props.values?.value}  {...props} />,
    validate: (value: any) => {
      return typeof value === 'number';
    },
  },
  Text: {
    component: (props: any) =>
      <TextField
        type="text"
        variant="standard"
        name="value"
        helperText="Wprowadź tekst"
        multiline
        maxRows={4}
        value={props.values?.value || ""}
        {...props} />,
    validate: (value: any) =>
      typeof value === 'string',
  },
  Time: {
    component: (props: any) => {
      return <TimePicker
        label="Wybierz datę i godzinę"
        slotProps={{ textField: { variant: "standard" } }}
        value={dayjs(props.values?.value)}
        onChange={(value) => props.onChange(value, null, 'value')}
      />
    },

    validate: (value: any) => {
      return typeof value === 'string';
    },
  },
  Boolean: {
    component: ({ onChange, values }: { onChange: () => void, values: any }) => {

      return (
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={onChange} name="value" checked={values.value?.toLowerCase() === "true"} />} label={values.value?.toLowerCase() === "true" ? "Prawda" : "Fałsz"} />
        </FormGroup>
      )
    },
    validate: (value: any) => {
      return typeof value === 'boolean';
    },
  },
};