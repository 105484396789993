import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';

const ViewListItem = (props) => {
  const { view, viewId, selected, onDelete, onSelect, ...other } = props;

  return (
    <MenuItem sx={{ fontSize: '.85rem', color: "#000", display: "flex", justifyContent: "space-between" }} selected={selected} onClick={() => onSelect(viewId)} {...other}>
      {view.label}
      <IconButton
        edge="end"
        aria-label="delete"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(viewId);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </MenuItem>
  );
};


export {
  ViewListItem
}