import { createObject } from '../../services/index'

const INITIAL_STATE = {
    views: {
        defaultView: {
            label: "Widok domyślny",
            value: {
                pinnedColumns: {
                    // "right": [],
                    // "left": ["__check__", "ProductEdit"]
                },
                columns: {
                    // "orderedFields": [
                    //     "__check__",
                    //     "id",
                    //     "ProductEdit",
                    //     "CreatedSource",
                    //     "Symbol",
                    //     "AdditionalSymbol",
                    //     "Name",
                    //     "Ean",
                    //     "CnSymbol",
                    //     "PkwiuSymbol",
                    //     "Comments",
                    //     "Brand.Name",
                    //     "Categories",
                    //     "ListPrice.Net",
                    //     "ListPrice.Gross",
                    //     "ListPrice.Currency.IsoCode",
                    //     "ListPrice.LastModified",
                    //     "DealersPrice.Net",
                    //     "IsWithdrawn",
                    //     "DealersPrice.Gross",
                    //     "DealersPrice.Currency.IsoCode",
                    //     "DealersPrice.LastModified",
                    //     "Tax",
                    //     "ReplacementsText",
                    //     "DefaultSuppliedProduct.Supplier.Nip",
                    //     "DefaultSuppliedProduct.Supplier.Name",
                    //     "DefaultSuppliedProduct.SupplierSymbol",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.Name",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.PercentageValue",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.CalculationType.Name",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Net",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Gross",
                    //     "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Currency.IsoCode",
                    //     "Created"
                    // ],
                }
            },
        },
    },

    newViewLabel: '',
    isMenuOpened: false,
    menuAnchorEl: null,
    activeViewId: null,
};

const makeActions = (state, action) => {

    switch (action.type) {
        case 'restoreView': {
            return {
                ...state,
                activeViewId: '',
                newViewLabel: '',
                views: {
                    ...state.views,
                    [action.label]: { label: action.label, value: action.value },
                }
            };
        }

        case 'createView': {
            let saveView = {
                ...state,
                activeViewId: state.newViewLabel,
                newViewLabel: '',
                views: {
                    ...state.views,
                    [state.newViewLabel]: { label: state.newViewLabel, value: action.value },
                }
            }
            let activeViewId = saveView?.activeViewId;
            let newView = saveView?.views[activeViewId]
            createObject(newView, action?.page)
            return saveView;
        }

        case 'deleteView': {
            const views = Object.fromEntries(
                Object.entries(state.views).filter(([id]) => id !== action.id),
            );

            let activeViewId;
            if (state.activeViewId !== action.id) {
                activeViewId = state.activeViewId;
            } else {
                const viewIds = Object.keys(state.views);

                if (viewIds.length === 0) {
                    activeViewId = null;
                } else {
                    activeViewId = viewIds[0];
                }
            }

            return {
                ...state,
                views,
                activeViewId,
            };
        }

        case 'setActiveView': {
            return {
                ...state,
                activeViewId: action.id,
                isMenuOpened: false,
            };
        }

        case 'setNewViewLabel': {
            return {
                ...state,
                newViewLabel: action.label,
            };
        }

        case 'togglePopper': {
            return {
                ...state,
                isMenuOpened: !state.isMenuOpened,
                menuAnchorEl: action.element,
            };
        }

        case 'closePopper': {
            return {
                ...state,
                isMenuOpened: false,
            };
        }

        default: {
            return state;
        }
    }

};

export {
    makeActions,
    INITIAL_STATE
}