import { Button } from '@mui/material'
import React from 'react'
// import { IntegrationRules } from '../../interfaces/integrations';

interface NewRuleProps {
    onAddCondition: () => void
    innerText: string
}

export default React.memo(function AddNewRule({ onAddCondition, innerText }: NewRuleProps) {
    return (
        <Button sx={{ my: 2, borderRadius: "5px" }} size="small" variant="outlined" onClick={onAddCondition}>{innerText}</Button>
    )
})
