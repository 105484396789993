import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography } from '@mui/material';

export default function ItemsTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rows, setRows] = React.useState(props.data.items)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 0, border: '1px solid rgba(224, 224, 224, 1)' }}>
            {(rows) &&
                <Table sx={{ width: '100%' }} aria-label="simple table">

                    {(rows.length !== 0) ? (
                        <>
                            <TableHead sx={{ background: '#f4f4f4' }}>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: '600' }}>{(props.isConfirmed === true) ? "Zaktualizowane pozycje" : "Szczegóły"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row) => (
                                    <TableRow
                                        key={row}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">{row}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </>
                    ) : (
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                        sx={{ m: 3 }}
                                    >
                                        Brak zmian
                                    </Typography>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    )}
                </Table>
            }
            {(rows) &&
                (rows.length !== 0) &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={(props.data.items) ? props.data.items.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }
        </TableContainer>

    );
}