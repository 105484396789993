import React from 'react'
import { TextField, Grid } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import hasPermission from '../../Services/auth/CheckPermission';
import addNewSupplier from '../../Services/suppliers/addNewSuplier'
import editSupplier from '../../Services/suppliers/editSupplier'
import { toast } from 'react-toastify';
import { editRefreshed } from './viewLayout'

export default function AddNewSupplier(props) {
    const [values, setValues] = React.useState(
        (props.modalData) ? { 'id': props.modalData.id, 'nip': props.modalData.nip, 'name': props.modalData.name } : []
    );
    const [response, setResponse] = React.useState(false);


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const handleAdd = (event) => {
        setResponse('');
        setResponse(true);
        addNewSupplier(values).then((data) => {
            if (data) {
                if (data.status === 500 || data.status === 400) {
                    toast.warn(data.title)
                } else {
                    toast.success('Dodano dostawcę')
                    props.refresh()
                    setValues([])
                }
                setTimeout(() => {
                    setResponse(false)
                }, 500)
            }
        })
    }

    const handleEdit = (event) => {
        setResponse('');
        setResponse(true);
        editSupplier(values).then((data) => {
            if (data) {
                if (data.status === 500 || data.status === 400) {
                    toast.warn(data.title)
                } else {
                    toast.success('Pomyślnie edytowano dostawcę')
                    editRefreshed(true)
 
                }
                setTimeout(() => {
                    setResponse(false)
                }, 500)
            }
        })
    }


    return (
        <>
            <Grid item className='mobile-100'>
                <TextField
                    sx={{
                        minWidth: '300px'
                    }}
                    fullWidth
                    onChange={handleChange}
                    value={(values) ? values.name : ''}
                    label="Nazwa dostawcy"
                    name='name'
                    required
                />
            </Grid>
            <Grid item className='mobile-100'>
                <TextField
                    sx={{
                        minWidth: '300px'
                    }}
                    fullWidth
                    onChange={handleChange}
                    label="Nip dostawcy"
                    name='nip'
                    value={(values) ? values.nip : ''}
                    required
                />
            </Grid>
            <Grid item className='mobile-100'>
                <LoadingButton
                    sx={{
                        width: {
                            lg: "unset",
                            md: "100%",
                            xs: "100%"
                        },
                        height: '55px',
                        p: "0 40px",
                    }}
                    disabled={!hasPermission('Permissions.AdvancedProducts.Suppliers.Edit')}
                    color="primary"
                    onClick={(props.type === 'add') ? handleAdd : handleEdit}
                    loading={response}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Zapisz
                </LoadingButton>
            </Grid>

        </>
    )
}
