import * as React from 'react';
import { ProductHunterDataService } from '../../Pages/ProductHunter/Services/DataServices/DataService';

export const DataGridContext = React.createContext(null);

const DataGridProvider = ({ children }) => {
    let retrievedObject = localStorage.getItem('exactSearch')
    let isExactSearch = JSON.parse(retrievedObject)
    let [isChecked, setIChecked] = React.useState();
    let [productFilter, setProductFilter] = React.useState([])

    const DataService = React.useMemo(() => {
        return new ProductHunterDataService();
    }, []);

    let defaultCheck = {
        name: true,
        ean: true,
        symbol: true,
        additionalSymbol: true,
        brand: true,
        exactSearch: false
    }

    const handleChecked = (value) => {
        setIChecked(value)
    }
    const [checked, setChecked] = React.useState((isExactSearch != null) ? isExactSearch : defaultCheck);
    const updateChecked = (getChecked) => {
        localStorage.setItem('exactSearch', JSON.stringify(getChecked))
        setChecked(getChecked);
    };

    const handleProductFilter = (_object) => {
        setProductFilter(_object)
    }

    const handleExport = async (_ev) => {
        const { filter, sortModel, pageNumber, pageSize } = productFilter
        DataService.paginatedSortedListParams(filter, sortModel, pageNumber, pageSize, 'export')
    }


    return (
        <DataGridContext.Provider value={{ checked, updateChecked, isExactSearch, handleChecked, isChecked, handleExport, handleProductFilter }}>
            {children}
        </DataGridContext.Provider>
    );
};

export default DataGridProvider;