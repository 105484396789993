import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Typography, useTheme, Skeleton } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import wareHouseReport from '../../../Services/dashboard/getWareHouseReport';



export const RoundChart = (props) => {
    const [report, setReport] = React.useState('')
    const [loading, setLoading] = React.useState(true)
    const theme = useTheme();


    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);

            const newRows = await wareHouseReport();
            if (!active) {
                return;
            }
            setReport(newRows);
            setLoading(false);
        })();
        return () => {
            active = false;
        };

    }, []);

    // console.log(report.allProducts)

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])

    const data = {
        datasets: [
            {
                data: [report?.allProducts, report?.productsInStock, report?.processedProductsAll, report?.processedProductsInStock],
                backgroundColor: ['#3F51B5', '#e53935', '#FB8C00'],
                borderWidth: 8,
                borderColor: '#FFFFFF',
                hoverBorderColor: '#FFFFFF'
            }
        ],
        labels: ['Wszystkie', 'Produkty na stanie', 'Wszystkie zwymiarowane produkty', 'Zwymiarowane produky ze stanu']
    };

    const options = {
        animation: true,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    const productsInfo = [
        {
            title: 'Wszystkie produkty',
            value: report?.allProducts,
            color: '#3F51B5'
        },
        {
            title: 'Produkty na stanie',
            value: report?.productsInStock,
            color: '#E53935'
        },
        {
            title: 'Wszystkie zwymiarowane produkty',
            value: report?.processedProductsAll,
            color: '#FB8C00'
        },
        {
            title: 'Zwymiarowane produky ze stanu',
            value: report?.processedProductsInStock,
            color: '#FB8C00'
        },

    ];

    return (
        <Card {...props} sx={{ position: "relative" }}>

            {loading == false ? (
                <>
                    <Typography
                        color="textSecondary"
                        sx={{
                            p: 2
                        }}
                        variant="h6"
                    >
                        Wymiarowanie produktów
                    </Typography>

                    <Divider />
                    <CardContent>
                        <Box
                            sx={{
                                height: 300,
                                position: 'relative'
                            }}
                        >
                            <Doughnut
                                data={data}
                                options={options}
                            />
                        </Box>
                        <Box
                            className="prodWarehouseBox"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                pt: 2
                            }}
                        >
                            {productsInfo.map(({
                                color,
                                title,
                                value
                            }) => (
                                <Box
                                    key={title}
                                    sx={{
                                        p: 1,
                                        textAlign: 'center',
                                        
                                    }}
                                >
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        {title}
                                    </Typography>
                                    <Typography
                                        style={{ color }}
                                        variant="h5"
                                    >
                                        {value}

                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </CardContent>
                </>
            ) : (
                <Box sx={{ height: 470, m: 1 }}>
                    {[...Array(12)].map((_) => (
                        <Skeleton sx={{ my: 3, mx: 1 }} height={16} />
                    ))}
                </Box>

            )}
        </Card>
    );
};
