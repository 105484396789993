export const apiRoutes = {
    getIntegration: '/api/Integration',
    getIntegrationRules: '/api/Integration/:id/rules',
    getAvailableRules: '/api/Integration/:id/available-rules',
    postNewRule: '/api/Integration/:integrationId/rules',
    getRules: '/api/Integration/:id/rules',
    editRule: '/api/Integration/:integrationId/rules/:ruleId/conditions/:conditionId',
    deleteRule: '/api/Integration/:integrationId/rules/:ruleId',
    newCondition: '/api/Integration/:integrationId/rules/:ruleId/conditions',
    updateOrder: '/api/Integration/:integrationID/rules/update-order',
    deleteCondition: '/api/Integration/:integrationID/rules/:ruleId/conditions/:conditionId',
    addActionsOnConditionsMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-met',
    editActionsOnConditionsMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-met/:actionId',
    deleteActionsOnConditionsMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-met/:actionId',
    addActionsOnConditionsNotMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-not-met',
    editActionsOnConditionsNotMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-not-met/:actionId',
    deleteActionsOnConditionsNotMet: '/api/Integration/:integrationId/rules/:ruleId/actions-on-conditions-not-met/:actionId', 
    getIntegrationRulesStatus: '/api/Integration/:integrationId/rules/:ruleId/status',
    putIntegrationRulesStatus: '/api/Integration/:integrationId/rules/:ruleId/status'
};