import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { plPL } from '@mui/x-data-grid';

export default function PriceHisotryDataGridComponent() {
    const locale = createTheme(
        plPL,
    );

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'createdData',
            headerName: 'Data utworzenia produktu',
            width: 150,
            type: 'date',
            flex: 1
        },
        {
            field: 'whoMadeChanges',
            headerName: 'Kto wprowadził zmiany',
            width: 150,
            flex: 1
        },
        {
            field: 'changes',
            headerName: 'Wykonane zmiany',
            width: 150,
            flex: 1
        }
    ]
    
    const rows = [
        { id: 1, createdData: new Date("11-21-2022"), whoMadeChanges: 'Jon', changes: 'Zmiany' },
        { id: 2, createdData: new Date("03-23-2022"), whoMadeChanges: 'Cersei', changes: 'Zmiany' },
        { id: 3, createdData: new Date("04-14-2022"), whoMadeChanges: 'Eva', changes: 'Zmiany' },
        { id: 4, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 5, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 6, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 7, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 8, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 9, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 10, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 11, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 12, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 13, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 14, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 15, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
        { id: 16, createdData: new Date("05-13-2022"), whoMadeChanges: 'frank', changes: 'Zmiany' },
    ];

    return (
        <ThemeProvider theme={locale}>
            <Box sx={{ height: '500px', width: '100%'}}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    rowHeight={38}
                    checkboxSelection
                    disableSelectionOnClick
                />
            </Box>
        </ThemeProvider>
    )
}
