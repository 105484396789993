export const columnsVisibility = {
    defaultConf: {
        websiteId: false,
        id: false,
        symbol: false,
        websiteName: true,
    },
    1: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    2: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    3: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    4: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    5: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    6: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: true,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: true,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: false,
        discountPriceNet: true,
        discountPriceGross: true,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    7: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: true,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: true,
        discountPriceNet: true,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    8: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: true,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    9: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: false,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    10: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    11: {
        historicalData: true,
        processedState: true,
        isActive: true,
        websiteId: false,
        websiteName: true,
        id: false,
        symbol: true,
        symbolEan: false,
        symbolAdditional: true,
        symbolIS: false,
        name: true,
        dateAdded: true,
        oldPriceCatalogGross: true,
        priceCatalogGross: true,
        priceGrossChange: true,
        oldPriceCatalogNet: false,
        priceCatalogNet: false,
        priceNetChange: false,
        oldPurchasePriceGross: false,
        additionalPriceGross: false,
        purchasePriceGrossChange: false,
        oldPurchasePriceNet: false,
        additionalPriceNet: false,
        purchasePriceNetChange: false,
        currency: true,
        vat: false,
        promotion: false,
        rebate: false,
        isDecommitted: false,
        quantity: true,
        oldQuantity: true,
        dateDelivery: false,
        size: false,
        weight: false,
        link: false,
        linkFriendlyUser: true,
        unit: false
    },
    12: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    13: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: true
    },
    14: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: false,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    15: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    16: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    17: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: false,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    18: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    19: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: true,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: true,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    20: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    21: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    22: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: true,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    23: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: true,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: true,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    24: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: true,
        regularMarketPriceGross: true,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    25: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: true
    },
    26: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: true,
        regularMarketPriceGross: true,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: false,
        regularMarketPriceNetChange: true,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    27: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: false,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    28: {
         id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: false,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    29: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: true,
        productReplacementName: true,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: true,
        minimumOrderQuantity: false,
        expectedAvailabilityName: true,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: true,
        lengthCm: true,
        widthCm: true,
        heightCm: true,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: true,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: true
    },
    30: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    31: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    32: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    33: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    34: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    35: {
       id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: true,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: false,
        listPriceGross: false,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: true,
        wholesalePriceGross: true,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: true,
        wholesalePriceNetChange: false
    },
    36: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    37: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    38: {
        id: false,
        dateAdded: true,
        productModel: true,
        websiteId: false,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: false,
        productLinkPl: true,
        productLinkEn: false,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: false,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: true,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: true,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: true,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    40: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: false,
        extraNamePl1: false,
        extraNamePl2: false,
        extraNameEn1: false,
        extraNameEn2: false,
        shortDescriptionPl: false,
        extraDescriptionPl: false,
        shortDescriptionEn: false,
        extraDescriptionEn: false,
        productImageUrl: true,
        extraProductImagesUrl: true,
        productLinkPl: true,
        productLinkEn: true,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: false,
        dateDelivery: false,
        currency: true,
        taxRate: true,
        rebate: true,
        discountGroup: false,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: false,
        isDiscontinued: false,
        pkwiuCode: false,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: false,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: false,
        purchasePriceGross: false,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: false,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    },
    41: {
        id: false,
        dateAdded: true,
        productModel: true,
        processed: true,
        productReplacementSymbol: false,
        productReplacementName: false,
        countryOrigin: true,
        name: true,
        nameEn: true,
        extraNamePl1: true,
        extraNamePl2: true,
        extraNameEn1: true,
        extraNameEn2: true,
        shortDescriptionPl: true,
        extraDescriptionPl: true,
        shortDescriptionEn: true,
        extraDescriptionEn: true,
        productImageUrl: true,
        extraProductImagesUrl: true,
        productLinkPl: true,
        productLinkEn: true,
        isActive: true,
        stockQuantity: true,
        stockName: false,
        minimumOrderQuantity: false,
        expectedAvailabilityName: false,
        expectedAvailabilityDate: true,
        dateDelivery: false,
        currency: true,
        taxRate: true,
        rebate: true,
        discountGroup: true,
        quantityBulkPackage: false,
        nettoWeightGrams: false,
        grossWeightGrams: false,
        lengthCm: false,
        widthCm: false,
        heightCm: false,
        category: false,
        badge: false,
        brand: true,
        isDiscontinued: false,
        pkwiuCode: true,
        cnCode: false,
        gtinCode: false,
        symbol: true,
        suppierSymbol1: false,
        suppierSymbol2: false,
        ean: true,
        symbolAdditional: false,
        symbolIs: false,
        shippingCost: false,
        listPriceNet: true,
        listPriceGross: true,
        regularMarketPriceNet: false,
        regularMarketPriceGross: false,
        discountPriceNet: false,
        discountPriceGross: false,
        wholesalePriceNet: false,
        wholesalePriceGross: false,
        purchasePriceNet: true,
        purchasePriceGross: true,
        promotion: true,
        historyEntriesCount: true,
        websiteName: true,
        listPriceNetChange: true,
        regularMarketPriceNetChange: false,
        purchasePriceNetChange: true,
        discountPriceNetChange: false,
        wholesalePriceNetChange: false
    }
};
