import React from 'react';
import { token, host, userID } from '../../../../Services/Connnection';
import { RestoreView } from '../customView/customViews.dispatch'

async function getColumnsConfig(userId, type) {
    let res = await fetch(`${host}/api/ColumnSettings?UserId=${userId}&Type=${type}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    })
    return await res.json();
}

function GetColumnsSettings(page) {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true)
            const data = await getColumnsConfig(userID, page)
            if (!active) {
                return
            }

            getConfignValues(data?.items)
            setLoading(false)
        })()
        return () => {
            active = false
        }
    }, [])

    const getConfignValues = (data) => {
        let viewsNames = getViewsNames(data);
        viewsNames?.forEach(n => {
            GetSavedViews(data, n)
        })
    }

    const getViewsNames = (items) => {
        let result = [];
        items?.forEach(function (item) {
            if (result.indexOf(item.group) < 0) {
                result.push(item.group);
            }
        });
        return result;
    }

    const GetSavedViews = (d, group) => {
        let columnVisibilityModel;
        let orderedFields;
        let order;
        let model = [];
        let dimensions;
        let left = [];
        let right = [];
        let sortModel = [];
        let pagination;

        let byGroup = d.filter(function (value) {
            if (value.group == group)
                return value;
        });

        byGroup?.forEach(d => {

            if (d.group === group) {

                if (d.pinned === 'left') {
                    left = [
                        ...left,
                        d.name
                    ]
                }

                if (d.pinned === 'right') {
                    right = [
                        ...right,
                        d.name
                    ]
                }

                sortModel = [
                    ...sortModel,
                    {
                        'field': d.name,
                        'sort': d.sort
                    }
                ]

                if (d.name === '@pagination') {
                    pagination = {
                        ['pageSize']: d.order
                    }
                }

                columnVisibilityModel = {
                    ...columnVisibilityModel,
                    [d.name]: d.visible
                }
                order = {
                    ...order,
                    [d.order]: d.name
                }
                orderedFields = Object.values(order);

                dimensions = {
                    ...dimensions,
                    [d.name]: {
                        width: d.columnWidth
                    }
                }

                model = {
                    label: d.group,
                    value: {
                        columns: {
                            columnVisibilityModel,
                            dimensions,
                            orderedFields
                        },
                        pagination: pagination,
                        sort: {
                            sortmodel: sortModel
                        },
                        pinnedColumns: {
                            "left": left,
                            "right": right
                        }
                    }

                }
            }
        })

        RestoreView(model)
    }
}

export {
    GetColumnsSettings
}
