import { ReactNode } from "react";
import { IntegrationRules } from "./integrations";

export type ActionMap<M extends { [index: string]: unknown }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? { type: Key }
    : { type: Key; payload: M[Key] };
};

export enum Types {
    GetRules = "GET_RULES",
}

export type IntegrationType = {
    rules: IntegrationRules;
};

export type ArticlePayload = {
    [Types.GetRules]: {
        rules: IntegrationRules[];
    };
};
export type IntegrationActions =
    ActionMap<ArticlePayload>[keyof ActionMap<ArticlePayload>];

export type InitialStateType = {
    rules: IntegrationType;
};

export type ReducerValues = {
    rules: { rules: IntegrationRules };
    getRules: (data: IntegrationRules[]) => void;
};

export interface IntegrationProviderProps {
    children: ReactNode;
}