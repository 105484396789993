import { token, host } from '../Connnection';

export default async function updateDimensions(dataObject) {
    try {
        let res = await fetch(`${host}/api/Product/UpdateWarehouseInfo`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObject)
            
        })
        
        return await res;
        
    } catch (error) {
        console.log(error);
    }
}




