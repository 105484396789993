import React from 'react';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import confirmImport from '../../Services/products/import/confirmImport';
import { isConfirm } from './AccordionResult'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function AcceptImport(props) {
    const [resp, setResp] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    function uploadId(id) {
        this.uploadId = id
    }

    const handleClick = (ev) => {
        setOpen(!open);
        let upload = new uploadId(props.uploadId)
        confirmImport(upload).then((data) => {
            setResp(data)
            props.setZero(data);
            isConfirm(data);
        })
    }

    React.useEffect(() => {
        setOpen(false);
    }, [props.verify])

    return (
        <>
            <Button
                startIcon={
                    <CheckIcon />
                }
                disabled={(props.disabled === 0) ? false : true}
                onClick={handleClick}
                sx={{
                    mt: 3,
                    p: '15px 50px',
                    background: '#000',
                    color: "#fff",
                    '&:hover': {
                        background: '#313234',
                        color: "#fff"
                    }
                }}
            >
                Zatwierdź import
            </Button>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
