import * as React from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormGroup, FormControlLabel, Checkbox, Switch } from '@mui/material'
import { DataGridContext } from '../../../actions/DataGrid/dataGridContext'


export function CollapseList(props) {
    const { updateChecked, checked } = React.useContext(DataGridContext);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleCheck = (ev) => {
        updateChecked({
            ...checked,
            [ev.target.name]: ev.target.checked
        });
    }

    if (checked != null)
        Object.values(checked).every(
            value => value === false
        );

    let count = 0;
    Object.values(checked)?.forEach(data => {
        if (data === true) {
            count++
        };
    })

    if (checked?.exactSearch === true) {
        count--
    }

    return (
        <List
            sx={{ width: '100%' }}
            aria-labelledby="Więcej ustawień"
        >
            <p className='expandMore-button' onClick={handleClick} >
                Dokładne wyszukiwanie
                {open ? <ExpandLess /> : <ExpandMore />}
            </p>
            <Collapse in={open} unmountOnExit>
                <FormGroup sx={{ flexDirection: 'row', px: '16px', fontSize: ".8rem" }}>
                    <FormControlLabel className='moreOptionsSearch'
                        control={<Checkbox
                            disabled={(count === 1 && checked?.symbol) ? true : false}
                            checked={(checked?.symbol) ? true : false}
                            onClick={handleCheck} name="symbol" />}
                        label="Szukaj po symbolu" />
                    <FormControlLabel className='moreOptionsSearch'
                        control={<Checkbox
                            disabled={(count === 1 && checked?.additionalSymbol) ? true : false}
                            checked={(checked?.additionalSymbol) ? true : false}
                            onClick={handleCheck} name="additionalSymbol" />}
                        label="Szukaj po symbolu dodatkowym" />
                    <FormControlLabel className='moreOptionsSearch'
                        control={<Checkbox
                            disabled={(count === 1 && checked?.brand) ? true : false}
                            checked={(checked?.brand) ? true : false}
                            onClick={handleCheck} name="brand" />}
                        label="Szukaj po marce" />
                    <FormControlLabel className='moreOptionsSearch'
                        control={<Checkbox
                            disabled={(count === 1 && checked?.ean) ? true : false}
                            checked={(checked?.ean) ? true : false}
                            onClick={handleCheck} name="ean" />}
                        label="Szukaj po kodzie EAN" />
                    <FormControlLabel className='moreOptionsSearch'
                        control={<Checkbox
                            disabled={(count === 1 && checked?.name) ? true : false}
                            checked={(checked?.name) ? true : false}
                            onClick={handleCheck} name="name" />}
                        label="Szukaj po nazwie" />
                    <FormControlLabel className='moreOptionsSearch' control={<Switch checked={(checked?.exactSearch) ? true : false} onClick={handleCheck} name="exactSearch" />} label="Dokładne wyszukiwanie" />

                </FormGroup>
                <FormGroup sx={{ flexDirection: 'row', px: '16px' }}>
                </FormGroup>
            </Collapse>
        </List>
    );
}