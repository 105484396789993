import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import OpenModal from './openModal';

const EditSupplierButton = React.memo((params) => {
  const handleClick = (event) => {
    isOpen(true)
    getData(params.row)
  }
  return (
    <IconButton onClick={handleClick} size="small"><EditIcon /></IconButton>
  )
})

let closeModalState
export function closeModal(state) {
  closeModalState(false)
}

let modalOpen
function isOpen(state) {
  modalOpen(state)
}

let paramData
function getData(state) {
  paramData(state)
}


export default function SuppliersList(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  closeModalState = setOpen
  modalOpen = setOpen;
  paramData = setData

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <OpenModal isOpen={open} modalData={data} />
      <Box
        sx={{
          height: '600px',

        }}>
        <DataGridPro
          loading={props.suppliers.length === 0}
          columns={columns}
          rows={props.suppliers}
        />
      </Box>
    </Box>
  )
}


let columns = [
  {
    field: 'edit',
    headerName: 'Edytuj',
    minWidth: 80,
    renderCell: (params) => <EditSupplierButton {...params} />,
  },
  {
    field: 'name',
    headerName: 'Nazwa dostawcy',
    minWidth: 160,
  },
  {
    field: 'nip',
    headerName: 'Nip dostawcy',
    minWidth: 160,
  }
]