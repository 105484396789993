import { Button } from '@mui/material'
import React from 'react'
import { useParams } from "react-router-dom";
import { useAddNewRule } from '../../api/integrations/integration.list';
import { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query';

interface NewRuleProps {
    onAdd: (mutatinAdd: UseMutationResult<AxiosResponse<any, any>>) => void;
}

const NewIntegrationButton = ({ onAdd }: NewRuleProps) => {
    const { id } = useParams();
    const mutationAdd = useAddNewRule(id, (oldData, newData) => [...oldData, newData]);
    return (
        <Button variant='contained' size="small" onClick={() => onAdd(mutationAdd)}>Dodaj nową regułę</Button>
    )
}
export default NewIntegrationButton