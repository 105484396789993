import * as React from 'react';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditSupplierFields from './EditSupplierFields';
import { Supplier } from "../../../Pages/DiscountingSystem/SupplierList/models/Supplier";
import { SupplierListDataService } from '../../../Pages/DiscountingSystem/SupplierList/services/DataService/SupplierListDataService';

const DataService = new SupplierListDataService();

export default function EditSupplierModal({ handleAdd }: { handleAdd(data: Supplier): void }) {
    const [open, setOpen] = React.useState(false);
    const [inputsValue, setInputsValue] = React.useState<Supplier>({ 'name': '', 'nip': '' })

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
        setInputsValue({ 'name': '', 'nip': '' });
    };

    const handleSave = (): void => {
        if (inputsValue) {
            DataService.addSupplier(inputsValue);
            handleAdd(inputsValue);
            handleClose();
        }
    }

    const handleChange = (e: { target: { name: string; value: string; }; }): void => {
        setInputsValue({
            ...inputsValue,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                Dodaj nowego dostawcę
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Dodaj nowego dostawcę
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <EditSupplierFields handleChange={handleChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Anuluj
                    </Button>
                    <Button autoFocus onClick={handleSave}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}