import { Box, Typography } from '@mui/material';
import {
  Card,
  CardContent,
} from '@mui/material';
import AvatarSubmit from '../../Services/accountSettings/avatarSubmit';
import { PrivateRouteVisible } from '../../routes';
import hasPermission from '../../Services/auth/CheckPermission';

function AccountProfile(props) {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* <IconButton color="inherit" sx={{ p: 0.5 }}>
            <Avatar
              src={props.dataObj.profilePicture}
              sx={{
                height: 250,
                // mb: 5,
                width: 250
              }}
            />
          </IconButton> */}
          <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Account.Edit') }}>
            <label htmlFor="contained-button-file">
              <AvatarSubmit avatarSrc={props.dataObj.profilePicture} sx={{ paddingTop: 5 }} />
            </label>
          </PrivateRouteVisible>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {props.dataObj.firstName + ' ' + props.dataObj.lastName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {props.dataObj.roleName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
          </Typography>
        </Box>
      </CardContent>


    </Card>
  )
};



export default AccountProfile;