import { DataGridPro, GridRowClassNameParams } from "@mui/x-data-grid-pro";
import React from "react";
import GridToolBar from "./toolbar";

export default function DiscountingSystemDataGrid({
  data,
  handleAdd,
}: {
  data: any;
  handleAdd: any;
}) {
  return (
    <DataGridPro
      rows={data.filteredRows}
      columns={data.cols}
      rowHeight={35}
      getRowClassName={(_params: GridRowClassNameParams<any>) => `odd-gray`}
      components={{
        Toolbar: GridToolBar,
      }}
      componentsProps={{
        toolbar: { handleAdd },
      }}
    />
  );
}
