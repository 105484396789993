import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Popover, List, ListItem, ListItemButton, ListItemText, Button } from '@mui/material';
import { ExportDialog } from './Dialog'

const DataGridExport = ({ columns, exportProducts }: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDial, setOpenDial] = React.useState<boolean>(false)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : '';

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openDialog = () => {
        setOpenDial(!openDial)
    }

    const closeDialog = () => {
        setOpenDial(false);
    };

    const getColumns = (columns: any) => {
        exportProducts(columns)
    }


    return (
        <>
            <ExportDialog openDial={openDial} closeDialog={closeDialog} columns={columns} getColumns={getColumns} />
            <Button onClick={handleClick} size="small">
                <FileDownloadIcon sx={{ fontSize: '1.1rem' }} />
                Eksportuj
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItem disablePadding onClick={openDialog}>
                        <ListItemButton >
                            <ListItemText primary="Eksportuj widoczne produkty" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
        </>
    )
}

export {
    DataGridExport
}