import { token, host } from '../Connnection';

export default async function getDataWebsiteDiki(param, webid) {

    try {
      let res = await fetch(`${host}/api/Websites?WithParameters=${param}${(webid) ? '&WebsiteId='+webid+'' : '' }`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      })
      return await res.json();
    } catch (error) {
      console.log(error);
    }
  }
  