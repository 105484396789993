import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ChangeUserPassword from './ChangePassword'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import { token, host } from '../../Services/Connnection';
import { PrivateRouteVisible } from '../../routes';
import hasPermission from '../../Services/auth/CheckPermission';
import putNewData from '../../Services/accountSettings/UpdateMyAccount';
import { useNavigate } from 'react-router-dom'


let AccountProfileDetails = (props) => {
  const history = useNavigate();

  let retrievedObject = localStorage.getItem('accessStorage')
  let storageData = JSON.parse(retrievedObject)
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: ""
  });

  const fetchData = () => {
    fetch(`${host}/api/User/${storageData.id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName
        })
      });
  }

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value
    });
  };

  const newData =
  {
    newEmail: userData.email,
    newFirstName: userData.firstName,
    newLastName: userData.lastName,
  }

  function handleSubmit() {
    putNewData(newData);
    history(`/account?changeData=firstname=${newData.newFirstName}%lastname=${newData.newLastName}%email=${newData.newEmail}`)
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <CardHeader
        title="Profil użytkownika"
      />
      <Divider />

      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >

            <TextField
              name="firstName"
              fullWidth
              label="Imie"
              value={userData.firstName}
              onChange={handleChange}
              defaultValue="Imie"
              InputLabelProps={{ shrink: true }}
            />

          </Grid>

          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              fullWidth
              label="Nazwisko"
              name="lastName"
              onChange={handleChange}
              required
              value={userData.lastName}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              label="Adres e-mail"
              id="email"
              name="email"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
              required
              value={userData.email}
              variant="outlined"
            />
          </Grid>
        </Grid>

      </CardContent>
      <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Account.Edit') }}>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button onClick={handleSubmit} variant="contained" endIcon={<SendIcon />}>
            Aktualizuj
          </Button>
        </Box>
      </PrivateRouteVisible>

      <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Account.Edit') }}>
        <Divider />
        <ChangeUserPassword />
      </PrivateRouteVisible>
    </Card>
  );
};

export default AccountProfileDetails;