import React from 'react'
import { Box, Typography, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import CustomizedDialogs from './editRange'
import deleteStatusRange from '../../../Services/settings/productHunter/deleteStatusRange'
import { deleteItem } from './websiteSettingsEdit'
import CircleIcon from '@mui/icons-material/Circle';

let close
export function popupClose(state) {
    close(state)
}


export default function WebsiteRangeList(props) {
    const [open, setOpen] = React.useState(false);
    const [params, setParams] = React.useState([])
    const [rangeId, setRangeId] = React.useState()
    close = setOpen

    function getRangeInfo(id) {
        setRangeId(id)
        setOpen(true);
    }

    let actualData = props.statusRange.find(elem => elem.statusRangesId == (rangeId) ? rangeId : 0)

    React.useEffect(() => {
        if (actualData) {
            setParams({
                'statusRangeId': actualData.statusRangesId,
                'quantityMin': actualData.quantityMin,
                'quantityMax': actualData.quantityMax,
                'nameAvailability': actualData.nameAvailability
            })
        }
    }, [props.isEdited, rangeId])


    const handleClickDelete = (event, rangeId) => {
        let deleteRange = {
            'statusRangesId': rangeId
        }
        deleteItem(true)
        deleteStatusRange(deleteRange)
    }

    
    return (
        <Box
            sx={{ width: '100%', maxHeight: 550, maxWidth: 900, bgcolor: 'background.paper' }}
        >
            <CustomizedDialogs
                isOpen={open}
                statusRangeId={params.statusRangeId}
                quantityMin={params.quantityMin}
                quantityMax={params.quantityMax}
                nameAvailability={params.nameAvailability}
            />
            <Grid item xs={12}>
                <Typography variant='h6' component="h3" >Wprowadzone terminy</Typography>
                <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >

                </Typography>
            </Grid>
            <List sx={{
                width: '100%',
                // maxWidth: 850,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 500,
                '& ul': { padding: 0 },
            }}>

                {props.statusRange.map((data) => (
                    <div key={data.statusRangesId}>
                        <ListItem
                            className='prodHunter-rangeList-settings'
                            sx={{
                                '&:hover': {
                                    background: '#f4f4f4'
                                }
                               
                            }}
                            secondaryAction={
                                <>
                                    <IconButton edge="end" aria-label="edit" sx={{ mx: 1 }}
                                        onClick={(event) => {
                                            getRangeInfo(data.statusRangesId, data.quantityMin, data.quantityMax, data.nameAvailability)
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" sx={{ mx: 1 }}
                                        onClick={(event) => {
                                            handleClickDelete(event, data.statusRangesId);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#00a152', width: 24, height: 24 }}>
                                    <CircleIcon sx={{ width: 12, height: 12 }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ maxWidth: 220 }}
                                primary="Zakres stanów"
                                secondary={
                                        (data.quantityMax == null) ? `Od ` + data.quantityMin + ` do +∞` : 
                                        (data.quantityMin == null) ? `Od 0` + ` do ` + data.quantityMax :  
                                        `Od ` + data.quantityMin + ` do ` + data.quantityMax 
                                       
                                }
                            />
                            <ListItemText
                                sx={{ minWidth: 300, textAlign: 'left' }}
                                primary="Termin realizacji"
                                secondary={data.nameAvailability}
                            />

                        </ListItem>
                        <Divider />
                    </ div>
                ))}
            </List>
        </Box>
    )
}
