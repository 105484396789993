
import { token, host } from '../Connnection';

export default async function getData(searchVal) {
    
  try {
    let res = await fetch(`${host}/api/Product?Symbol=${searchVal}&Name=${searchVal}&Ean=${searchVal}&PageSize=100000`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}