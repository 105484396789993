import { token, host } from '../../Connnection';
import { INewCeneoPrinciple } from '../../../@types/integration/integration.d.tsx'

export default async function putData(object: INewCeneoPrinciple) {
    try {
        let res = await fetch(`${host}/api/PrincipleCheckbox`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })
        return await res.json();
    } catch (error) {
        console.log(error);
    }
}
