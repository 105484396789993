import React from 'react';
import { Autocomplete, CircularProgress, Box, TextField, Popper, autocompleteClasses } from '@mui/material';
import { DataServiceGridFilterLibrary } from './Services/DataService';
import { GridFilterInputValuePropsCustom } from './Models/GridFilter';
import { ListboxComponent } from './VirtualizedList';
import { styled } from '@mui/material/styles';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        transition: 'unset!important',
        '& ul': {
            padding: 0,
            margin: 0,
            transition: 'unset!important',
            '&li': {
                transition: 'unset!important',
            }
        },
    },
});

const SUBMIT_FILTER_STROKE_TIME: number = 500;
export function InputNumberInterval(props: GridFilterInputValuePropsCustom) {
    const { item, applyValue, focusElementRef = null, propsFilter, propsSearch } = props;
    const filterTimeout = React.useRef<any>();
    const [filterValueState, setFilterValueState] = React.useState<string>(item.value ?? '');
    const [options, setOptions] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const loading = open && options.length === 0;
    const DataService = React.useMemo(() => {
        return new DataServiceGridFilterLibrary();
    }, []);

    React.useEffect(() => {
        let active: boolean = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (active) {
                let options: string[] = await DataService.getColumnsValues(propsFilter, propsSearch, item?.columnField)
                setOptions(options);
            }
        })();
        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    React.useEffect(() => {
        const itemValue: string = item.value ?? '';
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (filterValue: string) => {
        clearTimeout(filterTimeout.current);
        setFilterValueState(filterValue)
        filterTimeout.current = setTimeout(() => {
            applyValue({ ...item, value: filterValue });
        }, SUBMIT_FILTER_STROKE_TIME);
    };

    const handleFilterChange = (newValue: any) => {
        updateFilterValue(newValue?.join(';'));
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'end',
                height: 48,
            }}
        >
            <Autocomplete
                multiple
                freeSolo
                size="small"
                disableCloseOnSelect
                disableListWrap
                PopperComponent={StyledPopper}
                open={open}
                value={(filterValueState) ? filterValueState.split(';') : []}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                sx={{ width: '100%' }}
                onChange={(_event, newValue: string[]) => handleFilterChange(newValue)}
                options={options}
                loading={loading}
                ListboxComponent={ListboxComponent}
                renderOption={(props, option) => [props, option] as React.ReactNode}
                renderTags={(value) => `Wybrano ${value.length}x`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Wartość"
                        inputRef={focusElementRef}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            
        </Box>
    );
}




