import { Checkbox, FormControl, FormGroup, FormControlLabel } from '@mui/material'
import React from 'react'

export default function AdvancedDiscounting(): JSX.Element {
    let [checked, setChecked] = React.useState<any>([])

    let object = [
        { title: 'test1', checked: true },
        { title: 'test2', checked: false },
        { title: 'test3', checked: false }
    ]

    React.useEffect(() => {
        setChecked(object)
    }, [])


    const handleChange = (ev: any) => {
        let index = object.findIndex(x => x.title === ev.target.name)
        checked[index] = { ...checked[index], checked: ev.target.checked }
        setChecked([...checked])
    }


    return (
        <div style={{ display: 'flex', height: '100%', marginTop: '0px', background: "#fff", borderRadius: '10px' }}>
            <div style={{ flexGrow: 1 }}>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormGroup>
                        {checked.map((d: any) => (
                            <FormControlLabel
                                key={d.title}
                                control={
                                    <Checkbox checked={d.checked} name={d.title} onChange={handleChange} />
                                }
                                label={d.title}
                            />
                        ))}



                    </FormGroup>
                </FormControl>

            </div>
        </div>
    )
}
