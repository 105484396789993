import React from 'react';
import PriceHisotryDataGridComponent from '../components/PriceHistory/PriceHisotryDataGridComponent';
import NewCategory from '../components/Categories/newCategory'
import { MarketplaceMainComponent, StockManagementMainComponent, CategoriesTreeMainComponent } from '../components';

const formConfig = {
    "general": [
        {
            label: "Nazwa produktu",
            attribute: 'name',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
            }
        },
        {
            label: "Symbol Gk",
            attribute: 'symbol',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Symbol dodatkowy GK",
            attribute: 'additionalSymbol',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Ean",
            attribute: 'ean',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Symbol Cn",
            attribute: 'symbolCn',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Symbol PKWIU",
            attribute: 'symbolPkwiu',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Opis produktu",
            attribute: 'description',
            component: 'text-area',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
            }
        },
        {
            label: "Marka",
            attribute: 'brandName',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Komentarz",
            attribute: 'comment',
            component: 'text-field',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Czy wycofany?",
            attribute: 'isWithdrawn',
            component: 'checkbox',
            type: 'boolean',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12
            }
        },
        {
            label: "Cena katalogowa",
            attribute: 'listPriceGross',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Cena katalogowa - waluta",
            attribute: 'catalogCurrency',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Cena hurtowa",
            attribute: 'quantityPrice',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Cena hurtowa - waluta",
            attribute: 'quantityCurrency',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Cena hurtowa - nie dotyczy",
            attribute: 'quantityPrice-notApplicable',
            component: 'checkbox',
            type: 'boolean',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12
            }
        },

        {
            label: "PD: Domyślna cena zakupup netto/brutto",
            attribute: 'dealersPriceGross',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
        {
            label: "Vat",
            attribute: 'tax',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },

        // {
        //     label: "Produkt Id",
        //     attribute: 'productId',
        //     component: 'text-field',
        //     type: 'number',
        //     validation: {
        //         required: false,
        //         maxLength: 150,
        //         minLength: 5
        //     },
        //     col: {
        //         xs: 12
        //     }
        // },

        {
            label: "Galeria",
            attribute: 'gallery',
            component: 'gallery',
            type: 'text',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
    ],
    "warehouseDimensions": [
        {
            label: "Czy produkt jest gotowy do wysyłki",
            attribute: 'productReadyToShip',
            component: 'checkbox',
            type: 'boolean',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Czy produkt posiada numer seryjny?",
            attribute: 'hasSerialNumber',
            component: 'checkbox',
            type: 'boolean',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Czy produkt posiada datę ważności?",
            attribute: 'hasValidityPeriod',
            component: 'checkbox',
            type: 'boolean',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Długość",
            attribute: 'length',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Wysokość",
            attribute: 'height',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Szerokość",
            attribute: 'width',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 4
            }
        },
        {
            label: "Waga",
            attribute: 'weight',
            component: 'text-field',
            type: 'number',
            validation: {
                required: false,
                maxLength: 150,
                minLength: 5
            },
            col: {
                xs: 12,
                sm: 6
            }
        },
    ],
    "priceHistory": [
        {
            renderComponent: <><PriceHisotryDataGridComponent /><NewCategory /></>
        }

    ],
    "categories": [
        {
            renderComponent: <CategoriesTreeMainComponent />
        }

    ],
    "marketplace": [
        {
            renderComponent: <MarketplaceMainComponent />
        }
    ],
    "stock": [
        {
            renderComponent: <StockManagementMainComponent />
        }
    ]

}


export {
    formConfig
}