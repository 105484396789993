import { GridFilterItem, GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { ColumnsParams, Filter } from "../Models/Columns"
import { ApiServiceDataGridFilter } from "./ApiService"

export class DataServiceGridFilterLibrary {
    apiService: any
    static filters: any = []
    constructor() {
        this.apiService = new ApiServiceDataGridFilter()
    }
    
    capitalizeFirstLetter(str: string) {
        return str?.charAt(0).toUpperCase() + str?.slice(1)
    }

    orderBy(SortModel: GridSortModel): string {
        return this.capitalizeFirstLetter(SortModel?.[0]?.['field'])
    }

    orderDescending(SortModel: GridSortModel): boolean {
        return (SortModel?.[0]?.['sort'] === 'asc') ? false : true
    }

    translateOperator(operator: string | undefined): string {
        switch (operator) {
            case 'contains':
                return 'Contain'
            case 'equals':
                return 'Exact'
        }
        return 'Contain'
    }

    filterObject(filterValue: GridFilterModel, columnName: string): Filter[] {
        let filtersValue: any = []
        if (filterValue) {
            (filterValue.items?.length >= 1) &&
                filterValue.items.forEach((data: GridFilterItem) => {
                    if (data?.columnField !== columnName) {
                        let object: Filter = {
                            column: this.capitalizeFirstLetter(data.columnField),
                            filter: data.value,
                            filterType: this.translateOperator(data.operatorValue)
                        };
                        if (data.value !== '' && data.value !== undefined) {

                            filtersValue.push(object)
                        }
                    }
                })
        }
        return filtersValue
    }

    createObject(data: any, columnName: string, propsSearch: string) {
        return {
            name: propsSearch,
            symbol: propsSearch,
            additionalSymbol: propsSearch,
            brand: propsSearch,
            ean: propsSearch,
            exactSearch: false,
            filters: this.filterObject(data, columnName),
            columnValue: columnName
        } as ColumnsParams
    }

    async getColumnsValues(data: any, propsSearch: string, columnName: string): Promise<string[]> {
        return await this.apiService.GetColumnValues(this.createObject(data, columnName, propsSearch))
    }

}