import React from 'react'
import { categoryList } from '../../config/category.config'
import TreeItem from "@material-ui/lab/TreeItem";
import {
    useItemStyles
} from '../../../../common/index'


export default function TreeViewItems() {
    const classesItem = useItemStyles();
    const renderTree = React.useCallback((nodes) => (
        (nodes?.isActive) && (
            <TreeItem classes={classesItem} key={nodes.id} nodeId={nodes.id} label={nodes.label}>
                {Array.isArray(nodes.child)
                    ? nodes.child.map((node) => renderTree(node))
                    : null}
            </TreeItem>
        )
    ), []);

    return renderTree(categoryList)

}
