/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { DataGridPro} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet'

import { columns } from '../../../data/settings/productHunter/websiteListCols'
import { promiseCategory } from '../../../data/dashboard/websiteDiki';
import { QuickSearchToolbar } from './elements/QuickSearchToolbar';
import { escapeRegExp } from './commons/escapeRegExp';


export default function WebsiteLists() {
    let [rows, setRows] = React.useState([])
    let [dataRow, setDataRow] = React.useState([])
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        promiseCategory().then((data) => {
            setDataRow(data)
        })
    }, [promiseCategory])

    React.useEffect(() => {
        setRows(dataRow);
    }, [dataRow]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = dataRow.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Helmet>
                <title>Wyszukiwarka produktów | Panel Grupa Krawczyk</title>
            </Helmet>
            <Box
                sx={{
                    height: '100%',
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }}>

                <DataGridPro
                    pagination={false}
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) =>
                                requestSearch(event.target.value),
                            clearSearch: () => requestSearch(''),
                        },
                    }}
                />
            </Box>
        </Box>
    );
}