import { Box, Paper, TextField, Typography, Grid, MenuItem, Divider, Checkbox, Avatar, Button, ListItemText, ListItemButton } from '@mui/material';
import {
    GridRowsProp,
    useGridApiRef,
    DataGridPro,
    GridApi,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,

} from '@mui/x-data-grid-pro';
import AddIcon from '@mui/icons-material/Add';


interface EditToolbarProps {
    apiRef: React.MutableRefObject<GridApi>;
}

export default function EditToolbar(props: EditToolbarProps) {
    const { apiRef } = props;

    const handleClick = () => {
        const id = Math.random();
        apiRef.current.updateRows([{ id, isNew: true }]);
        apiRef.current.setRowMode(id, 'edit');
        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });
            apiRef.current.setCellFocus(id, 'symbol');
        });
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Dodaj nowy produkt
            </Button>
        </GridToolbarContainer>
    );
}