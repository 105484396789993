import { token, host } from '../Services/Connnection';
import axios from 'axios';

export const api = {
    get: <T>(url: string, params?: object) =>
        axios.get<T>(host + url, {
            headers: {
                token: token,
            },
            ...params,
        }),
    post: <T>(url: string, data: any) =>
        axios.post<T>(host + url, data, {
            headers: {
                token: token,
            },
        }),
    patch: <T>(url: string, data: any) =>
        axios.patch<T>(host + url, data, {
            headers: {
                token: token,
            },
        }),
    put: <T>(url: string, data: any) =>
        axios.put<T>(host + url, data, {
            headers: {
                token: token,
            },
        }),
    delete: <T>(url: string) =>
        axios.delete<T>(host + url, {
            headers: {
                token: token,
            },
        }),
};
