import React from 'react'
import { Box, Paper, Typography } from '@mui/material';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import uploadFile from '../../Services/products/import/uploadFile';
import { isRemoved } from './AccordionResult'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

let verify;
export const isVerifiy = (state) => {
  verify(state)
}

export default function DropZone(props) {
  const [buttonDisable, setButtonDisable] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [ver, setVer] = React.useState([]);
  verify = setVer

  const handleSubmit = (files, allFiles) => {
    setOpen(!open);
    uploadFile(files[0]['file'])
    setButtonDisable(true)
  }

  const handleRemove = (event) => {
    setOpen(false);
    if (event) {
      if (event.meta.status === 'removed') setButtonDisable(false);
      isRemoved(event.meta.status)
    }
  }

  React.useEffect(() => {
    setOpen(false);
  }, [ver])


  return (
    <Box>
      <Typography variant='h6' component="h3" >Wgraj plik</Typography>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        Akcpetowane rozszerzenia: .csv
      </Typography>
      <Paper sx={{ boxShadow: 'unset', pt: 1 }}>
        <Dropzone
          onSubmit={handleSubmit}
          onChangeStatus={handleRemove}
          submitButtonContent="Wyślij"
          maxFiles={1}
          accept=".csv"
          inputContent="Dodaj plik"
          submitButtonDisabled={buttonDisable}
        />

      </Paper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box >
  );
}
