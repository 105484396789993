import {
    GridOverlay
} from '@mui/x-data-grid-pro';
import React from 'react';

export const notFound = React.memo(() => {
    return (
        <GridOverlay>
            <div>Nie znaleziono wyników 😟</div>
        </GridOverlay>
    )
});