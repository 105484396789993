
import { token, host } from '../../../Services/Connnection';

async function POST_products(object = {}) {
    let res = await fetch(`${host}/api/AdvancedProduct/GetProducts`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(object)
    })
    return await res.json();

}

export async function productsData(sendObject) {
    let actualProd = [];
    let prodData = await POST_products(sendObject);
    let prodItems = prodData.items;

    prodItems.forEach(prod => {
        let combined = {};
        let row = {
            [`id`]: prod.id,//
            [`ProductEdit`]: prod.id,//
            [`CreatedSource`]: prod.createdSource,
            [`Created`]: prod.created,//
            [`AdditionalSymbol`]: prod.additionalSymbol, //
            [`Brand.Name`]: prod.brand?.name,//
            [`Carrier`]: prod.carrier,
            [`Comments`]: prod.comments,
            [`ReplacementsText`]: prod.replacementsText,
            [`DealersPrice.Gross`]: prod.dealersPrice?.gross,
            [`DealersPrice.GrossPLN`]: prod.dealersPrice?.grossPLN,
            [`DealersPrice.Net`]: prod.dealersPrice?.net,
            [`DealersPrice.NetPLN`]: prod.dealersPrice?.netPLN,
            [`DealersPrice.Currency.IsoCode`]: prod.dealersPrice?.currency?.isoCode,
            [`DealersPrice.Currency.CurrentConversionRate`]: prod.dealersPrice?.currency?.currentConversionRate,
            [`DealersPrice.Created`]: prod.dealersPrice?.created,
            [`DealersPrice.LastModified`]: prod.dealersPrice?.lastModified,
            [`Description`]: prod.description,
            [`Ean`]: prod.ean,
            [`IsWithdrawn`]: prod.isWithdrawn,
            [`Name`]: prod.name,//
            [`Symbol`]: prod.symbol, // 
            [`Tax`]: prod.tax,
            [`CnSymbol`]: prod.cnSymbol,
            [`PkwiuSymbol`]: prod.pkwiuSymbol,
            [`ListPrice.Gross`]: prod.listPrice?.gross,
            [`ListPrice.GrossPLN`]: prod.listPrice?.grossPLN,
            [`ListPrice.Net`]: prod.listPrice?.net,
            [`ListPrice.NetPLN`]: prod.listPrice?.netPLN,
            [`ListPrice.Currency.IsoCode`]: prod.listPrice?.currency?.isoCode,
            [`ListPrice.Currency.ConversionRate`]: prod.listPrice?.currency?.currentConversionRate,
            [`ListPrice.Created`]: prod.listPrice?.created,
            [`ListPrice.LastModified`]: prod.listPrice?.lastModified,
        }
        combined = { ...row }
        if (prod.categories.length != 0) {
            prod.categories.forEach(category => {
                let cat = {
                    ['Categories']: category.path
                }
                combined = { ...row, ...cat }
            })
        }

        if (prod.defaultSuppliedProduct) {
            if (prod.defaultSuppliedProduct.length != 0) {
                let dsp = 'DefaultSuppliedProduct.'
                let s = {
                    [`${dsp}SupplierSymbol`]: prod.defaultSuppliedProduct?.supplierSymbol,
                    [`${dsp}Supplier.Name`]: prod.defaultSuppliedProduct?.supplier?.name,
                    [`${dsp}Supplier.Nip`]: prod.defaultSuppliedProduct?.supplier?.nip,
                    [`${dsp}Supplier.Id`]: prod.defaultSuppliedProduct?.supplier?.id,
                    [`${dsp}DefaultSuppliedPrice.SuppliedProduct`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.suppliedProduct,
                    [`${dsp}DefaultSuppliedPrice.TradeTerm.Name`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTerm?.name,
                    [`${dsp}DefaultSuppliedPrice.TradeTerm.PercentageValue`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTerm?.percentageValue,
                    [`${dsp}DefaultSuppliedPrice.TradeTerm.CalculationType.Name`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTerm?.calculationType?.name,
                    // [`${dsp}defaultSuppliedPrice.tradeTerm.supplier.name`]: (prod.defaultSuppliedProduct.defaultSuppliedPrice) ? prod.defaultSuppliedProduct.defaultSuppliedPrice.tradeTerm.supplier.name : null,
                    // [`${dsp}defaultSuppliedPrice.tradeTerm.supplier.nip`]: (prod.defaultSuppliedProduct.defaultSuppliedPrice) ? prod.defaultSuppliedProduct.defaultSuppliedPrice.tradeTerm.supplier.nip : null,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.Created`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.created,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.LastModified`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.lastModified,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.Currency.IsoCode`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.currency?.isoCode,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.Gross`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.gross,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.GrossPLN`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.grossPLN,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.Net`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.net,
                    [`${dsp}DefaultSuppliedPrice.TradeTermPrice.NetPLN`]: prod.defaultSuppliedProduct?.defaultSuppliedPrice?.tradeTermPrice?.netPLN,
                }
                combined = { ...combined, ...s }
            }
        }
        actualProd.push(combined);
    });

    let final = {
        'items': actualProd,
        'totalCount': prodData.totalCount
    }

    return final;
}


export default productsData