import React, { createContext, useReducer } from "react";
import { integrationReducer } from "./integration.reducer";
import {
    IntegrationActions,
    IntegrationProviderProps,
    InitialStateType,
    ReducerValues,
    Types,
} from "../../interfaces/interface.reducer";
import { IntegrationRules } from "../../interfaces/integrations";

const initialState = {
    rules: {} as any,
    getRules: () => null,
};

export const IntegrationContext = createContext<ReducerValues>(initialState);

const mainReducer = (
    { rules }: InitialStateType,
    action: IntegrationActions
) => ({
    rules: integrationReducer(rules, action),
});

export const IntegrationProvider = ({ children }: IntegrationProviderProps) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    
    const getRules = (rules: IntegrationRules[]) => {
        dispatch({
            type: Types.GetRules,
            payload: {
                rules: rules,
            },
        });
    }

    const value: any = {
        ...state,
        getRules
    };

    return (
        <IntegrationContext.Provider value={value}>{children}</IntegrationContext.Provider>
    );
};