import React from 'react';
import { TextField, Grid, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import CalculationTypeInput from './elements/calculationTypeInput';
import SuppliersList from './elements/suppliersList';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import hasPermission from '../../../Services/auth/CheckPermission';
import { toast } from 'react-toastify';
import { refresh } from './tradeTermLayout'


export default function TradeTermInput(props) {
    let [data, setData] = React.useState((props.data) ? props.data : [])
    let [response, setResponse] = React.useState([])
    let [checked, setChecked] = React.useState(false)
    let [discount, setDiscount] = React.useState(
        (props.data) ? (props.data?.percentageValue !== 0)
            ? { 'percentageValue': 'typeValue' } :
            { 'percentageValue': 'onDemand' }
            : '')

    const handleChange = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        })
    }

    
    const handleDiscount = (ev) => {
        setDiscount({
            ...discount,
            [ev.target.name]: ev.target.value
        })

        if (ev.target.value === 'onDemand') {
            setData({
                ...data,
                [ev.target.name]: 0
            })
        } else {
            setData({
                ...data,
                [ev.target.name]: ''
            })
        }
    }

    const supplierName = (d) => {

        if (d) {
            setData({
                ...data,
                ['supplierId']: d.id
            })
        } 
        else if(props.type == 'add') {
            setData({
                ...data,
                ['supplierId']: null
            })
        }
    }

    const calcTypeName = (d) => {
        if (d) {
            setData({
                ...data,
                ['calculationType']: d.name
            })
        } 
        else if(props.type == 'add') {
            setData({
                ...data,
                ['calculationType']: null
            })
        }

    }

    const handleSubmit = (ev) => {
        props.fetch(data).then((data) => {
            setResponse(data)
        })
        refresh(true)
    }


    React.useEffect(() => {
        if (response.status === 500 || response.status === 400) toast.error(response.title);
        if (response) {
            if (response.calculationType) toast.success("Dodano pomyślnie");
        }
        if (data.percentageValue === 0) {
            setChecked(true)
        }
    }, [response])


    const isDiabled = () => {
        if (!hasPermission('Permissions.AdvancedProducts.Suppliers.TradeTerm.Edit')
        || data.name && data.calculationType && data.supplierId && (discount.percentageValue === 'onDemand') ? discount.percentageValue === 'onDemand' : (discount.percentageValue === 'typeValue' && data.percentageValue > 0 ) ) {
            return false
        }
        return true
    }

    let disabledButton = isDiabled();


    return (
        <>
            <Grid item xs={12} md={(props.type === 'add' ) ? 6 : null}>
                <Grid item className='mobile-100'>
                    <TextField
                        sx={{
                            // minWidth: '300px'
                        }}
                        fullWidth
                        onChange={handleChange}
                        label="Nazwa grupy rabatowej"
                        name='name'
                        value={data?.name}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={(props.type === 'add' ) ? 6 : null}>
                <Grid item className='mobile-100'>
                    <SuppliersList suppliers={supplierName} data={(data) ? data['supplier.name'] : ''} action={props.type} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={(props.type === 'add' ) ? 4 : null}>
                <Grid item className='mobile-100'>
                    <CalculationTypeInput type={calcTypeName} data={(data) ? data['calculationType.label'] : ''} action={props.type} />
                </Grid>
            </Grid>
            <Grid item xs={12} md={(props.type === 'add' ) ? 4 : null}>
                <Grid item className='mobile-100'>
                    <FormControl fullWidth>
                        <InputLabel id="rabate-field-label">Rabat</InputLabel>
                        <Select
                            labelId="rabate-field-label"
                            id="rabate-field"
                            value={discount?.percentageValue}
                            label="Rabat"
                            name="percentageValue"
                            onChange={handleDiscount}
                        >
                            <MenuItem value={'typeValue'}>Wprowadź wartość</MenuItem>
                            <MenuItem value={'onDemand'}>Na zapytanie u dostawcy</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {discount?.percentageValue === 'typeValue' && (
                <Grid item xs={12} md={(props.type === 'add' ) ? 4 : null}>
                    <Grid item className='mobile-100'>

                        <TextField
                            sx={{
                                // minWidth: '300px'
                            }}
                            fullWidth
                            type={'number'}
                            onChange={handleChange}
                            label="Wartość rabatu "
                            required
                            name='percentageValue'

                            error={(data.percentageValue < 0) ? true : false}
                            helperText={
                                (data.percentageValue) ?
                                    (data.percentageValue < 0) ?
                                        "Wartość nie może być ujemna" :
                                        "" :
                                    (checked) ? '' : ""

                            }
                            value={(data) ? data.percentageValue : null}
                            
                        />
                    </Grid>
                </Grid>
            )}




            <Grid item xs={12}>
                <Grid item className='mobile-100'>
                    <LoadingButton
                        sx={{
                            width: {
                                lg: "unset",
                                md: "100%",
                                xs: "100%"
                            },
                            height: '55px',
                            p: "0 40px",
                        }}
                        disabled={disabledButton || (data.percentageValue < 0) ? true : false}
                        color="primary"
                        onClick={handleSubmit}
                        loading={(response) ? false : true}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                    >
                        Zapisz
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    )
}
