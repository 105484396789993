import React from 'react'
import { StockManagementListComponent } from './StockManagementListComponent.js'
import useProduct from '../../hooks/useProduct.hook'

export default function StockManagementMainComponent() {
    const { productData } = useProduct();
    return (
        <StockManagementListComponent data={productData} />
    )
}
