import { token, host } from '../Connnection';

export default function putAllowAccess(userId, switchState) {
    const dataObject =
    {
        id: userId,
        allowAccess: switchState
    };
    fetch(`${host}/api/User/ChangeAllowedAccess`, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })
}