import React from 'react'
import { TabLayout } from './components'
import { Helmet } from 'react-helmet'
import { ProductDataService } from './services/dataServices/ProductDataService.tsx'
import useProduct from './hooks/useProduct.hook'

export default function EditProductLayout() {
    const { setNewData, productData } = useProduct();
    const product = React.useMemo(() => {
        return new ProductDataService();
    }, []);

    React.useEffect(() => {
        let active = true;
        (async () => {
            const rows = await product.GetProduct()
            if (!active) {
                return
            }
            setNewData(rows)
        })()
        return () => {
            active = false
        }
    }, [product])

    return (
        <>
            <Helmet title={`${productData?.name} | Grupa Krawczyk`} />
            <TabLayout productData={productData} />
        </>
    )
}
