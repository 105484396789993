import { token, host } from '../../Connnection';

export default function putProductRange(dataObject) {

    fetch(`${host}/api/Websites/Status/Add`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })

}