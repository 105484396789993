import React from 'react'
import { Paper, Box, Typography, Grid } from '@mui/material';
import AddNewSupplier from './addNewSupplier';
import SuppliersList from './suppliersList';
import { ToastContainer } from 'react-toastify';
import getSuppliersData from '../../data/suppliers/getSuppliersData';

let editSupplierRefresh;
export function editRefreshed(state) {
  editSupplierRefresh(state)
}
export default function ViewLayout() {
  const [suppliers, setSuppliers] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)
  editSupplierRefresh = setRefresh
  const handleRefresh = () => {
    setRefresh(true)
  }

  React.useEffect(() => {
    getSuppliersData().then((data) => {
      setSuppliers(data)
    })
    setRefresh(false)
  }, [refresh])
  
  return (
    <Paper sx={{ height: '100%', my: 2, p: 2 }}>
      <Grid
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <Typography variant='h6' component="h3" >Dodaj nowego dostawcę</Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Wypełnij podstawowe dane
          </Typography>
        </Grid>

        <AddNewSupplier refresh={handleRefresh} type={'add'} />
        <Grid item xs={12} >
          <SuppliersList suppliers={suppliers} />
        </Grid>
      </Grid>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme='dark'
        pauseOnHover
      />
    </Paper>

  )
}
