import { EditButton } from '../../../components/settings/productHunter/elements/editButton'

const columns = [
    {
        field: 'edytuj',
        headerName: 'Edytuj',
        width: 100,
        renderCell: EditButton
    },
    { field: 'label', headerName: 'Dostawca', width: 150 },
];

export {
    columns
}