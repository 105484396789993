import * as React from 'react';

export const ProductEditContext = React.createContext(null);

const ProductEditProvider = ({ children }) => {
    const [productData, setProductData] = React.useState({})
    const setNewData = (data) => {
        setProductData(data)
    }
    const value = {
        productData,
        setNewData
    }

    return (
        <ProductEditContext.Provider value={value}>
            {children}
        </ProductEditContext.Provider>
    );
};

export default ProductEditProvider;