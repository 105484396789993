import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import TransitionsModal from './addNewBundleModal'

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    color: 'common.white',
    bgcolor: blue[500],
    '&:hover': {
        bgcolor: blue[600],
    },
};


let closeModalState
export function closeModal(){
    closeModalState(false)
}


export default function AddNewBundleBtn() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    closeModalState = setOpen
    return (
        <>
            <TransitionsModal isOpen={open} />
            <Fab sx={fabStyle} aria-label={'Dodaj nowy zestaw'} onClick={handleOpen}>
                <AddIcon />
            </Fab>
        </>

    );
}



