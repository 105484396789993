import React from 'react';
import {
    dealerPrice,
    listPrice,
    formatToPercent,
    tradeTerm,
    translateCalcType,
    dealerDateTime,
    listDateTime,
    createdDateTime,
} from '../utils/helpers/valueFormatter';
import { EditProductButton } from '../components'
import { getGridStringOperators } from '@mui/x-data-grid-pro';
import { InputNumberInterval } from '../../../shared/components/DataGridFilterLibrary'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === 'contains' || operator.value === 'equals',
);

function InputValue(props) {
    const {
        item,
        applyValue,
    } = props;
    const filterTimeout = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(filterTimeout.current);
        };
    }, []);

    const updateFilterValue = (filterValue) => {
        clearTimeout(filterTimeout.current);
        filterTimeout.current = setTimeout(() => {
            applyValue({ ...item, value: filterValue });
        }, 500);
    };

    const handleFilterChange = (ev) => {
        updateFilterValue(ev.target.value);
    };
    return (
        <FormControl fullWidth>
            <InputLabel id="true-label">Wartość</InputLabel>
            <Select
                labelId="true-label"
                id="simple-select"
                variant='standard'
                value={item.value}
                label="select"
                onChange={(ev) => handleFilterChange(ev)}
            >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
            </Select>
        </FormControl>
    );
}

const columnsConfig = (filter, search) => {
    const filterDictionary = [
        {
            label: 'contains',
            value: 'contains',
            InputComponent: InputNumberInterval,
            InputComponentProps: { propsFilter: filter, propsSearch: search }
        },
        {
            label: 'equals',
            value: 'equals',
            InputComponent: InputNumberInterval,
            InputComponentProps: { propsFilter: filter, propsSearch: search },
        },
    ];

    const booleanOperator = [
        {
            label: 'equals',
            value: 'equals',
            InputComponent: InputValue,
            // InputComponentProps: { propsFilter: filter, propsSearch: search },
        },
    ]
    return (
        [
            // ['__check__']: {
            //     headerName: "Zaznacz",
            //     minWidth: 140,
            //     // type: 'checkbox',
            //     // hide: true,
            //     disableReorder: true
            // },
            {
                headerName: "Id produktu",
                field: "id",
                minWidth: 140,
                type: 'string',
                hidden: true,
                sortable: false,
                filterable: false,

            },
            {
                headerName: "Edytuj",
                field: "ProductEdit",
                minWidth: 70,
                sortable: false,
                filterOperators: filterDictionary,
                renderCell: (params) => <EditProductButton {...params} />
            },
            {
                headerName: "Źródło utworzenia",
                field: "CreatedSource",
                minWidth: 180,
                type: 'string',
                filterOperators: filterOperators
            },
            {
                headerName: `Symbol GK`,
                field: "Symbol",
                minWidth: 120,
                type: "string",
                filterOperators: filterOperators
            },
            {
                headerName: "Dodatkowy Symbol GK",
                field: 'AdditionalSymbol',
                minWidth: 180,
                type: 'string',
                filterOperators: filterOperators,
            },
            {
                headerName: `Nazwa produktu`,
                field: "Name",
                minWidth: 250,
                type: 'string',
                filterOperators: filterOperators,
            },
            {
                headerName: `EAN`,
                field: "Ean",
                minWidth: 150,
                type: 'string',
                filterOperators: filterOperators,
            },
            {
                headerName: `Symbol CN`,
                field: "CnSymbol",
                minWidth: 100,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `Symbol PKWIU`,
                field: "PkwiuSymbol",
                minWidth: 100,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `Komentarz`,
                field: "Comments",
                minWidth: 300,
                type: 'string',
                sortable: false,
                filterOperators: filterDictionary,
            },
            {
                headerName: "Marka",
                field: "Brand.Name",
                minWidth: 140,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: "Kategoria Asortymentowa",
                field: "Categories",
                minWidth: 200,
                type: 'string',
                oldIndex: 3,
                filterOperators: filterDictionary,
            },
            {
                headerName: `Cena katalogowa netto`,
                field: "ListPrice.Net",
                minWidth: 180,
                type: 'number',
                valueGetter: listPrice,
                filterOperators: filterOperators,
            },
            // {
            //     headerName: `Cena katalogowa netto PLN`,
            //     field: "ListPrice.NetPLN",
            //     minWidth: 180,
            //     type: 'number',
            //     valueGetter: listPrice,
            //     filterOperators: filterDictionary,
            // },
            {
                headerName: `Cena katalogowa brutto`,
                field: "ListPrice.Gross",
                minWidth: 180,
                type: 'number',
                valueGetter: listPrice,
                filterOperators: filterOperators,
            },
            // {
            //     headerName: `Cena katalogowa brutto PLN`,
            //     field: "ListPrice.GrossPLN",
            //     minWidth: 180,
            //     type: 'number',
            //     valueGetter: listPrice,
            //     filterOperators: filterDictionary,
            // },
            {
                headerName: `Cena katalogowa - Kod waluty`,
                field: "ListPrice.Currency.IsoCode",
                minWidth: 140,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `Cena katalogowa - Ostatnia zmiana`,
                field: "ListPrice.LastModified",
                minWidth: 260,
                type: 'date',
                valueGetter: listDateTime,
                filterOperators: filterOperators,
            },
            {
                headerName: `Cena hurtowa netto`,
                field: 'DealersPrice.Net',
                minWidth: 200,
                type: 'number',
                valueGetter: dealerPrice,
                filterOperators: filterOperators,
            },
            // {
            //     headerName: `Cena hurtowa netto PLN`,
            //     field: "DealersPrice.NetPLN",
            //     minWidth: 200,
            //     type: 'number',
            //     valueGetter: dealerPrice,
            //     filterOperators: filterDictionary,
            // },
            {
                headerName: `Cena hurtowa brutto`,
                field: "DealersPrice.Gross",
                minWidth: 200,
                type: 'number',
                valueGetter: dealerPrice,
                filterOperators: filterOperators,
            },
            // {
            //     headerName: `Cena hurtowa brutto PLN`,
            //     field: "DealersPrice.GrossPLN",
            //     minWidth: 200,
            //     type: 'number',
            //     valueGetter: dealerPrice,
            //     filterOperators: filterDictionary,
            // },
            {
                headerName: `Cena hurtowa - kod waluty`,
                field: "DealersPrice.Currency.IsoCode",
                minWidth: 200,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `Cena hurtowa - Ostatnia zmiana`,
                field: "DealersPrice.LastModified",
                minWidth: 260,
                type: 'date',
                valueGetter: dealerDateTime,
                filterOperators: filterOperators,
            },
            {
                headerName: `Stawka vat`,
                field: "Tax",
                minWidth: 100,
                type: 'number',
                valueGetter: formatToPercent,
                filterOperators: filterDictionary,
            },
            {
                headerName: `Produkt wycofany`,
                field: "IsWithdrawn",
                minWidth: 180,
                type: 'boolean',
                sortable: false,
                filterOperators: booleanOperator,
            },
            {
                headerName: "Zamiennik",
                field: "ReplacementsText",
                minWidth: 130,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `PD: NIP dostawcy`,
                field: "DefaultSuppliedProduct.Supplier.Nip",
                minWidth: 260,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `PD: Nazwa dostawcy`,
                field: "DefaultSuppliedProduct.Supplier.Name",
                minWidth: 260,
                type: 'string',
                filterOperators: filterDictionary,
            },
            {
                headerName: `PD: Symbol produktu u dostawcy`,
                field: "DefaultSuppliedProduct.SupplierSymbol",
                minWidth: 260,
                type: 'string',
                filterOperators: filterOperators,
            },
            {
                headerName: `PD: Grupa Rabatowa - Nazwa`,
                field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.Name",
                minWidth: 400,
                type: 'string',
                filterOperators: filterOperators,
            },
            {
                headerName: `PD: Grupa Rabatowa - Wartość rabatu podstawowego`,
                field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.PercentageValue",
                minWidth: 400,
                type: 'string',
                valueGetter: formatToPercent,
                filterOperators: filterDictionary,
            },
            {
                headerName: `PD: Sposób obliczania ceny zakupu`,
                field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTerm.CalculationType.Name",
                minWidth: 400,
                type: 'string',
                valueGetter: translateCalcType,
                filterOperators: filterDictionary,
            },
            {
                headerName: `PD: Cena Zakupu Netto`,
                field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Net",
                minWidth: 260,
                type: 'string',
                valueGetter: tradeTerm,
                filterOperators: filterOperators,
            },
            {
                headerName: `PD: Cena Zakupu Brutto`,
                field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Gross",
                minWidth: 260,
                type: 'string',
                valueGetter: tradeTerm,
                filterOperators: filterOperators,
            },
            {
                headerName: "Data utworzenia",
                field: "Created",
                minWidth: 180,
                type: 'string',
                valueGetter: createdDateTime,
                filterOperators: filterDictionary,
            }



            // {
            //     headerName: "Przewoźnik",
            //     field: "Carrier",
            //     minWidth: 120,
            //     type: 'string',
            //     hide: true,
            //     sortable: false,
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `Opis`,
            //     field: "Description",
            //     minWidth: 250,
            //     type: 'string',
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `Cena hurtowa - kurs waluty`,
            //     field: "DealersPrice.Currency.CurrentConversionRate",
            //     minWidth: 200,
            //     type: 'number',
            //     valueGetter: dealerPrice,
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `Cena katalogowa - Kurs waluty`,
            //     field: "ListPrice.Currency.ConversionRate",
            //     minWidth: 120,
            //     type: 'number',
            //     valueGetter: listPrice,
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `PD: Cena Zakupu Brutto PLN`,
            //     field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.GrossPLN",
            //     minWidth: 260,
            //     type: 'string',
            //     valueGetter: tradeTerm,
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `PD: Cena Zakupu Netto PLN`,
            //     field: "DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.NetPLN",
            //     minWidth: 260,
            //     type: 'string',
            //     valueGetter: tradeTerm,
            //     filterOperators: filterDictionary,
            // },

            // {
            //     headerName: `PD: ID dostawcy`,
            //     field: "DefaultSuppliedProduct.Supplier.Id",
            //     minWidth: 260,
            //     hide: true,
            //     type: 'string',
            //     filterOperators: filterDictionary,
            // },
        ]
    )
}

export { columnsConfig }
