import React from 'react'
import { ProductHunterDataService } from './Services/DataServices/DataService'
import ProductHunterDataGrid from './Components/ProductGrid/ProductHunterDataGrid'
import { columnsVisibility } from './Config/ProductHunterList/columnsVisibility'
import { useParams } from 'react-router';
import { DataGridContext } from '../../actions/DataGrid/dataGridContext';

export default function ProductHunterMainLayout() {
  const { isChecked, handleProductFilter } = React.useContext(DataGridContext)
  const [products, setProducts] = React.useState({ items: [], totalCount: 0 })
  let [pageNumber, setPageNumber] = React.useState(1)
  let [pageSize, setPageSize] = React.useState(50)
  let [loading, setLoading] = React.useState(false)
  let [initialState, setInitialstate] = React.useState({})
  let [filter, setFilter] = React.useState()
  const [sortModel, setSortModel] = React.useState();
  let params = useParams();
  let websiteId = params?.id || '0';

  const DataService = React.useMemo(() => {
    return new ProductHunterDataService();
  }, []);

  React.useEffect(() => {
    let active = true;
    (async () => {
      let apiProduct = await DataService.paginatedSortedListParams(filter, sortModel, pageNumber, pageSize, 'search')
      setLoading(true);
      if (!active) {
        return
      }
      handleProductFilter({ filter, sortModel, pageNumber, pageSize })
      setInitialstate({ columns: { columnVisibilityModel: columnsVisibility[websiteId] } });
      setProducts(apiProduct);
      setLoading(false);
    })()
    return () => {
      active = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DataService, pageNumber, pageSize, websiteId, isChecked, filter, sortModel])

  const changePage = React.useCallback((page) => {
    setPageNumber(page)
  }, []);

  const changePageSize = React.useCallback((page) => {
    setPageSize(page)
  }, []);

  const handleSelect = (ids) => {
    DataService.getAllSelectedIds(ids)
  }

  const handleFilterChange = React.useCallback((filterModel) => {
    setFilter(filterModel)
  }, []);

  const handleSortModelChange = React.useCallback((newModel) => {
    setSortModel(newModel)
  }, []);

  return (
    <ProductHunterDataGrid
      loading={loading}
      products={products}
      changePage={changePage}
      changePageSize={changePageSize}
      pageSize={pageSize}
      initialState={initialState}
      websiteId={websiteId}
      handleSelect={handleSelect}
      handleFilterChange={handleFilterChange}
      handleSortModelChange={handleSortModelChange}
    />
  )
}
