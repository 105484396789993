import { MarketPlaceProduct } from "../../models/MarketPlaceProduct";
import { token, host } from '../../../../Services/Connnection'// @ts-ignore
import { IApiService } from "./IApiService.tsx";// @ts-ignore
import { Product } from "../../models/Product.tsx";

export class apiService implements IApiService {
    async GetMarketplaces(symbol: string): Promise<MarketPlaceProduct> {
        const response = await fetch(`${host}/api/ProductMarketplace/GetProductMarketplaces/${symbol}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json();
        return result as MarketPlaceProduct
    }

    async GetProduct(id: number): Promise<Product> {
        const response = await fetch(`${host}/api/Product/${id}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json();
        return result as Product
    }

    async RemoveMarketplaceEntry(id: number): Promise<Product> {
        const response = await fetch(`${host}/api/ProductMarketplace/RemoveMarketplaceEntry/??${id}`, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json();
        return result as Product
    }

}