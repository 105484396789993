import * as React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IFormBuilder } from "./IFormBuilder";
import FormBuilder from "./formBuilder";

export default React.memo(function EditModal({
  formBuilder = [],
  handleAdd,
  buttonTitle = "Dodaj nowe",
  modalHeader = "Dodaj nowe",
  getValues,
  row,
}: {
  formBuilder?: IFormBuilder[];
  handleAdd: any;
  buttonTitle?: string;
  modalHeader?: string;
  getValues: any;
  row?: any;
}) {
  const [open, setOpen] = React.useState<any>(false);
  const [data, setData] = React.useState<any>([]);
  const handleClickOpen = (): void => {
    setOpen(!open);
  };

  const handleClose = (): void => {
    setOpen(!open);
  };

  const handleChange = (ev: any, value?: string, fieldName?: string): any => {
    setData({
      ...data,
      [ev.target.name ? ev.target.name : fieldName]: ev.target.value
        ? ev.target.value
        : value,
    });
  };

  React.useEffect(() => {
    getValues(data);
  }, [data]);

  React.useEffect(() => {
    if (row !== undefined) setData(row);
  }, [open]);

  return (
    <div>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
        {buttonTitle}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{modalHeader}</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <FormBuilder
            formBuilder={formBuilder}
            row={data}
            handleChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button autoFocus onClick={() => handleAdd(data)}>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
