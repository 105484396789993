import React from "react";
import { Button, Popover, ListItemButton, ListItemText } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
    GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { ProductHunterDataService } from "../../../../Pages/ProductHunter/Services/DataServices/DataService";
import { DataGridContext } from "../../../../actions/DataGrid/dataGridContext";
export default function DataGridToolbarSelectAll() {
    const { handleChecked, isChecked } = React.useContext(DataGridContext)

    const DataService = React.useMemo(() => {
        return new ProductHunterDataService();
    }, []);

    return (
        <GridToolbarContainer sx={{ p: 0, m: 0 }} >
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>
                        <Button {...bindTrigger(popupState)} size="small">
                            <SettingsSuggestIcon sx={{ fontSize: '1rem' }} /> Akcje
                        </Button>
                        <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <ListItemButton component="a" >
                                <ListItemText primary="Zaznacz wszystkie" onClick={() => { DataService.setProcessedState([], 'select'); handleChecked(!isChecked) }} />
                            </ListItemButton>
                            <ListItemButton component="a">
                                <ListItemText primary="Odznacz wszystkie" onClick={() => { DataService.setProcessedState([], 'unselect'); handleChecked(!isChecked) }} />
                            </ListItemButton>
                        </Popover>
                    </div>
                )}
            </PopupState>
        </GridToolbarContainer >
    );
}
