import React from 'react';
import DropZone from './dropZone';
import { Box, Paper } from '@mui/material';
import AccordionResult from './AccordionResult';
import { Helmet } from 'react-helmet'
let verify;
export function isVerifiy(state) {
    verify(state)
}


export default function MainImportPage() {
    // eslint-disable-next-line no-unused-vars
    let [ver, setVer] = React.useState();
    verify = setVer
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <Helmet>
                <title>Zaimportuj produkty | Panel Grupa Krawczyk</title>
            </Helmet>
            <Paper sx={{ height: '100%', width: '100%', p: 3 }}>
                <DropZone />
                <AccordionResult />
            </Paper>
        </Box>
    )
}
