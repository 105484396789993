import {
    useFetch, usePost, usePut, useDelete
} from '../../utils/reactQuery';
import { apiRoutes } from '../../config/apiRoutes';
import { AllowedActions, AllowedConditions, IntegrationList, IntegrationRules, NewRule } from '../../interfaces/integrations';
import { pathToUrl } from '../../utils/urlParser';


export const useGetIntegrationList = () => {
    const context = useFetch<{ data: IntegrationList }>(
        apiRoutes.getIntegration,
        // { retry: false }
    );
    return { ...context, data: context.data || [] as any };
};

export const useGetRules = (id: string | undefined) =>
    useFetch<{ data: IntegrationRules }>(pathToUrl(apiRoutes.getIntegrationRules, { id }));

export const useGetAvailableRules = (id: string | undefined) =>
    useFetch<{ allowedConditions: AllowedConditions[], allowedActions: AllowedActions[] }>(pathToUrl(apiRoutes.getAvailableRules, { id }));

export const useAddNewRule = (integrationId: string | undefined, updater: (oldData: NewRule[], newData: NewRule) => NewRule[]) =>
    usePost<NewRule[], null>(pathToUrl(apiRoutes.postNewRule, { integrationId }), updater);

export const useGetAllRules = (id: string | undefined) =>
    useFetch<IntegrationRules[]>(pathToUrl(apiRoutes.getRules, { id }));

export const useEditRule = (integrationId: string | undefined, ruleId: number, conditionId: number | undefined, updater: (oldData: any, newData: any) => any) =>
    usePut<any, null>(pathToUrl(apiRoutes.editRule, { integrationId, ruleId, conditionId }), updater)

export const useAddCondition = (integrationId: string | undefined, ruleId: number, updater: (oldData: any, newData: any) => any) =>
    usePost<any, null>(pathToUrl(apiRoutes.newCondition, { integrationId, ruleId }), updater)

export const useChangeOrder = (integrationID: string | undefined, updater: (oldData: any, newData: any) => any) =>
    usePut<any, null>(pathToUrl(apiRoutes.updateOrder, { integrationID }), updater)

export const useDeleteCondition = (integrationID: string | undefined, ruleId: number, conditionId: number | undefined, updater: (oldData: any, newData: any) => any) =>
    useDelete<any>(pathToUrl(apiRoutes.deleteCondition, { integrationID, ruleId, conditionId }), updater)

export const useDeleteRule = (integrationId: string | undefined, ruleId: number, updater: (oldData: any, newData: any) => any) =>
    useDelete<any>(pathToUrl(apiRoutes.deleteRule, { integrationId, ruleId }), updater)

export const useAddActionsOnConditionsMet = (integrationId: string | undefined, ruleId: number, updater: (oldData: any, newData: any) => any) =>
    usePost<any, null>(pathToUrl(apiRoutes.addActionsOnConditionsMet, { integrationId, ruleId }), updater)

export const useEditActionsOnConditionsMet = (integrationId: string | undefined, ruleId: number, actionId: any, updater: (oldData: any, newData: any) => any) =>
    usePut<any, null>(pathToUrl(apiRoutes.editActionsOnConditionsMet, { integrationId, ruleId, actionId }), updater)

export const useDeleteActionsOnConditionsMet = (integrationId: string | undefined, ruleId: number, actionId: any, updater: (oldData: any, newData: any) => any) =>
    useDelete<any>(pathToUrl(apiRoutes.editActionsOnConditionsMet, { integrationId, ruleId, actionId }), updater)


export const useAddActionsOnConditionsNotMet = (integrationId: string | undefined, ruleId: number, updater: (oldData: any, newData: any) => any) =>
    usePost<any, null>(pathToUrl(apiRoutes.addActionsOnConditionsNotMet, { integrationId, ruleId }), updater)

export const useEditActionsOnConditionsNotMet = (integrationId: string | undefined, ruleId: number, actionId: any, updater: (oldData: any, newData: any) => any) =>
    usePut<any, null>(pathToUrl(apiRoutes.editActionsOnConditionsNotMet, { integrationId, ruleId, actionId }), updater)

export const useDeleteActionsOnConditionsNotMet = (integrationId: string | undefined, ruleId: number, actionId: any, updater: (oldData: any, newData: any) => any) =>
    useDelete<any>(pathToUrl(apiRoutes.editActionsOnConditionsNotMet, { integrationId, ruleId, actionId }), updater)

export const useGetIntegrationRulesStatus = (integrationId: string | undefined, ruleId: number) =>
    useFetch<{ disabled: boolean }>(pathToUrl(apiRoutes.getIntegrationRulesStatus, { integrationId, ruleId }));

export const useEditIntegrationRulesStatus = (integrationId: string | undefined, ruleId: number, updater: (oldData: any, newData: any) => any) =>
    usePut<any, any>(pathToUrl(apiRoutes.putIntegrationRulesStatus, { integrationId, ruleId }), updater);
