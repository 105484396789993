import styled from "styled-components";

const RuleHeader = styled.div`
    /* background: #f3f3f3; */
    border-bottom: 1px dashed #c7c7c7;
    padding: 5px 10px;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
`
const Id = styled.div`
    font-weight: 700;
    font-size: .8rem;
    display: flex;
    align-items: center;
`

const OrderBox = styled.div`
    font-weight: 700;
    font-size: .8rem;
    display: flex;
    align-items: center;
`

const RuleFooter = styled.div`
    /* background: #f3f3f3; */
    padding: 0px;
    border-right: 1px dashed #c7c7c7;
    border-radius: 0 0 5px 5px;
    display: flex;
    justify-content: space-between;
`
const RuleBody = styled.div`
    padding: 0 5px;

`
const Rule = styled.div<{ index: number }>`
    padding: 10px 0;
    /* background: ${props => props.index % 2 ? "white" : "#f7f7f7"}; */
    /* border-bottom: ${props => !(props.index % 2) ? "2px dashed #000" : "unset"}; */
`

const ConditionHeader = styled.div`
    background-color: #f7f7f7;
    padding: 0px 5px;
    border-radius: 5px;
    display: flex;
    outline: 1px solid #c3c3c3;
    align-items: center;
    justify-content: space-between;
`


const ShowLessMore = styled.button`
    background-color: #f7f7f7;
    border: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    outline: 1px dashed #c3c3c3;
    font-weight: 600;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    cursor: pointer;
`

export {
    RuleHeader,
    Id,
    OrderBox,
    RuleFooter,
    Rule,
    RuleBody,
    ConditionHeader,
    ShowLessMore
}