import { token, host } from '../Connnection';

export default function putRole(userId, selectState) {

    const dataObject =
    {
        id: userId,
        newRole: selectState
    };
    fetch(`${host}/api/User/ChangeRole`, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })

}