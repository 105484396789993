import { host, token } from "../../../../../Services/Connnection";
import { Supplier } from "../../models/Supplier";
import { ISupplierListApi } from "./ISupplierListApi";

export class SupplierListApiService implements ISupplierListApi {

    async GetSupplier(): Promise<Supplier[]> {
        let url = new URL(`${host}/api/Supplier`);
        const response = await fetch(url.href, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return await response.json()
    }
    async PostSupplier(_supplier: Supplier): Promise<Supplier[]> {
        let url = new URL(`${host}/api/Supplier`);
        let response = await fetch(url.href, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_supplier)
        });
        return await response.json()
    }

    async PutSupplier(_supplier: Supplier): Promise<Supplier[]> {
        let url = new URL(`${host}/api/Supplier`);
        let response = await fetch(url.href, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_supplier)
        });
        return await response.json()
    }

}