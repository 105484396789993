import React from 'react';
import {
    notFound
} from '../../../shared/components/DataGridNotFound/notFound'
import {
    ToolBarCustom
} from './dataGridToolbar'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridFilterPanel } from './customfilter'
let modelView;
export var views = (model) => {
    modelView(model)
}



function DataGridList(props) {
    const apiRef = useGridApiRef();

    let [model, setModel] = React.useState({})
    modelView = setModel
    let {
        data,
        loading,
        changePage,
        changePageSize,
        pageSize,
        handleSortModelChange,
        onFilterChange,
        filter,
        searchValue,
        checked
    } = props
    return (
        <div style={{ display: 'flex', height: 'calc(100% - 60px)', marginTop: '0px' }}>
            <div style={{ flexGrow: 1 }}>
                <DataGridPro
                    columns={data.columns}
                    rows={data.rows}
                    loading={loading}
                    rowHeight={35}
                    pagination
                    apiRef={apiRef}
                    hasMultipleFilters
                    paginationMode="server"
                    sortingMode="server"
                    filterMode="server"
                    onSortModelChange={handleSortModelChange}
                    getRowClassName={() => `super-app-theme--bg`}
                    onPageSizeChange={(newPage) => changePageSize(newPage)}
                    onFilterModelChange={onFilterChange}
                    rowsPerPageOptions={[20, 50, 100, 200, 500]}
                    pageSize={pageSize}
                    onPageChange={(newPageNumber) => changePage(newPageNumber + 1)}
                    rowCount={data.total}
                    disableSelectionOnClick
                    components={{
                        Toolbar: ToolBarCustom,
                        NoRowsOverlay: notFound,
                        FilterPanel: GridFilterPanel,

                    }}
                    componentsProps={{ toolbar: { filterValue: filter, searchValue: searchValue, checked: checked } }}
                    initialState={{ columns: { columnVisibilityModel: { 'id': false } } }}
                    sx={{ background: '#fff' }}

                />
            </div>
        </div>
    )
}

export default DataGridList