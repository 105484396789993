import React from 'react'
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context';
import { Modal, Fade, Box, Typography, Backdrop, Step, Stepper, StepButton, Button, Grid } from '@mui/material'
import { style } from '../../../common/modalStyle'
import PrincipleAdd from './principle.add.js'
import AddNewSymbols from './newSymbols.add.js'
import SymbolsList from './symbolsList.datagrid'

export default function PrincipleModal() {
    const { modal, handleModal } = React.useContext(CeneoPrincipleContext);
    const [activeStep, setActiveStep] = React.useState(0);
    const [addNewBtn, setAddNewBtn] = React.useState(false);
    // eslint-disable-next-line 
    const [completed, setCompleted] = React.useState({});
    const steps = ['Edycja reguły', 'Podgląd symboli',];

    const handleClose = () => {
        handleModal(!modal)
    }

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modal}>
                <Box sx={style}>
                    <Box sx={{ backgroundColor: '#1e1f21', color: '#fff', p: 2 }}>
                        <Typography variant="h6" component="h2">
                            Edytuj regułę
                        </Typography>
                    </Box>
                    <Box sx={{ p: 4 }}>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>

                        {(activeStep === 0) && (
                            <>
                                <PrincipleAdd edit />
                                <Grid container>
                                    <Grid sx={{ pl: 2 }}>
                                        <Button variant="contained" onClick={() => { setAddNewBtn(!addNewBtn) }}> {(addNewBtn) ? 'Zamknij okno' : 'Dodaj nowe symbole'} </Button>
                                    </Grid>
                                    <Grid sx={{ pl: 2 }}>
                                        <Button onClick={handleStep(1)}> Zobacz listę wszystkich symboli </Button>
                                    </Grid>
                                </Grid>
                                {(addNewBtn) && (
                                    <AddNewSymbols />
                                )}
                            </>
                        )}
                        {(activeStep === 1) && (
                            <>
                                <SymbolsList />
                            </>
                        )}
                    </Box>
                </Box>

            </Fade>
        </Modal>
    )
}
