import { token, host, userID } from '../../../../Services/Connnection';

async function deleteCustomView(type, page) {
    let res = await fetch(`${host}/api/ColumnSettings`, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "userId": userID,
            "type": page,
            "group": type
        })

    })
    return await res.json();
}

export {
    deleteCustomView
}