import { host, token } from "../../../../../Services/Connnection";

export class StandardDiscountingApiService {
  async GetAllSupplier(): Promise<any> {
    let url = new URL(`${host}/api/Supplier`);
    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  }
  async GetTradeTerm(): Promise<any> {
    let url = new URL(`${host}/api/TradeTerm`);
    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  }

  async PostTradeTerm(value: any): Promise<any> {
    let url = new URL(`${host}/api/TradeTerm`);
    const response = await fetch(url.href, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    return await response.json();
  }
}
