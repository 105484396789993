import React from "react";
import { Checkbox } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ProductHunterDataService } from '../../Services/DataServices/DataService.tsx'


export const IsChecked = React.memo((params) => {
    const [isChecked, setIsChecked] = React.useState({ 'isChecked': false });

    const DataService = React.useMemo(() => {
        return new ProductHunterDataService();
    }, []);

    const handleChange = (e) => {
        setIsChecked({
            ...isChecked,
            [e.target.name]: e.target.checked
        });
        DataService.setProcessedState({
            id: params.row.id,
            processed: e.target.checked
        }, '')
    }

    React.useEffect(() => {
        setIsChecked({ 'isChecked': params.row.processed })
    }, [params.row.processed])

    return (
        <Checkbox
            icon={<CheckCircleOutlineIcon />}
            checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />}
            onChange={(e) => handleChange(e)}
            name='isChecked'
            checked={isChecked.isChecked}
        />
    );
});