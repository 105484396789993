//mui components
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import {
  CardContent,
  CardHeader,
  Divider
} from '@mui/material';
import Button from '@mui/material/Button';
//connection
import { token, host } from '../../Services/Connnection';

//passChanger component
const ChangeUserPassword = (props) => {
    //state 
    const [showPassword, setShowPassword] = useState(false);
    const [showConfmPassword, setShowConfmPassword] = useState(false);
    //storage +user id
    let retrievedObject = localStorage.getItem('accessStorage')
    let storageData = JSON.parse(retrievedObject)
    let userID = storageData.id

    //state do wpisanych wartosci inputow
    const [newCredentials, setNewCredentials] = useState({
      id: userID,
      password: "",
      newPassword: ""
    });
    
    //aktualizacja state z inputow po 'name'
    const onChange = (e) => {
      setNewCredentials({
        ...newCredentials,
        [e.target.name]:e.target.value,
      });
    };
  
    //wyslanie formularza do api
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      await fetch(`${host}/api/User/ChangePassword`, {
        method:"PUT",
        headers: { 
          'Accept': '*/*',
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: newCredentials.id,
          password: newCredentials.password,
          newPassword: newCredentials.newPassword,
        })
      })
      .then((response) => {
        if (response.ok) {
          return response;
          // console.log(response)
        } else {
          throw new Error('Something went wrong');
          toast("Błąd 400/500");
        }
      })
      .then((responseJson) => {
        // console.log(responseJson)
        toast("Dane zostały zaktualizowane 🎉");
        setNewCredentials({
          id: userID,
          password: "",
          newPassword: ""
        });
      })
      .catch((error) => {
        console.log(error)
        toast("Obecne hasło nie jest poprawne. Spróbuj ponownie");
      });
    }

    //passw visibility
    function togglePasswordVisibilty() {
      setShowPassword(!showPassword ? true : false);
    }

    //newPassw visibility
    function toggleConfmPasswordVisibilty() {
      setShowConfmPassword(!showConfmPassword ? true : false);
    }
    return (
    <>
    <form onSubmit={handleSubmit}>
      <CardHeader
        subheader="Zmiana hasła"
        // title="Hasło użytkownika"
      />
      
      <CardContent>
      
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
           
          <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          >
            {/* aktualne haslo */}
            <FormControl sx={{ width: '100%' }} fullwidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Hasło</InputLabel>
            <OutlinedInput
              fullWidth
              label="Hasło"
              name="password"
              value={newCredentials.password}
              minLength={5}
              type={showPassword ? "text" : "password"} //funkcja zmienia typ inputa na passw i text + pobiera wartosc ze state
              onChange={(e) => onChange(e, "password")} //zmienia wartosc value w state
              placeholder='Wpisz aktualne hasło'
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibilty}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            </FormControl>
               
          </Box>
        
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
           
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {/* nowe haslo */}
            <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Nowe hasło</InputLabel>
            <OutlinedInput
              fullWidth
              label="Nowe hasło"
              name="newPassword"
              value={newCredentials.newPassword}
              minLength={5}
              type={showConfmPassword ? "text" : "password"} //funkcja zmienia typ inputa na passw i text + pobiera wartosc ze state
              onChange={(e) => onChange(e, "newPassword")} //zmienia wartosc value w state
              placeholder='Wpisz nowe hasło'
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleConfmPasswordVisibilty}
                    edge="end"
                  >
                    {showConfmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            </FormControl>

          </Box>
        
          </Grid>
      </Grid>
      
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button color='primary' id="testBtn" type="submit" variant="contained" endIcon={<SendIcon />}>
        Aktualizuj
        </Button>
      </Box>
      <Divider />
      </form>
    </>
    )
};

export default ChangeUserPassword