export const packageTypeForPrepared = [
    'Karton',
    'Półpaleta',
    'Paleta niestandardowa',
    'Paleta przemysłowa'
]

export const packageTypeForNoPrepared = [
    'Karton',
    'Półpaleta',
    'Paleta niestandardowa',
    'Paleta przemysłowa',
    'Koperta',
    'Folia bąbelkowa',
    'Stretch',
    'Tektura'
]

export const paletteType = [
    'Półpaleta',
    'Paleta niestandardowa',
    'Paleta przemysłowa'
]

export const readyToDelivery = [
    'Tak',
    'Nie',
]

export const expirationDate = [
    'Tak',
    'Nie',
]

export const hasSerialNumberArr = [
    'Tak',
    'Nie',
]

export const stickersArr = [
    {
        'stickerName': {
            'fragile': {
                'name': 'Produkt podatny na uszkodzenia',
                'image': 'fragileImg',
                'defaultChecked': 'fragile'
            },
            'updown': {
                'name': 'Góra / Dół',
                'image': 'upImg',
                'defaultChecked': 'updown'
            },
        }

    }
]

export const updownConst = 'Góra / Dół';
export const fragileConst = 'Produkt podatny na uszkodzenia'