import * as React from 'react';
import { Grid, Paper, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
    DataGridPro,
    useGridApiContext,
    useGridApiRef,
    GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import AsyncListSymbols from './asyncListSymbols';
import AsyncSuggestNames from './asyncNamesList';
import QtyField from './productQtyField';
import EditToolbar from './editToolbar'
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { ProductSimulation } from './bundleStepperContainer';
import updateBundle from '../../Services/productBundles/updateBundle';
import { isLoadingState } from './productBundlesList';


export default function DetailPanelContent({ row }) {
    const [bundleItems, setBundleItems] = React.useState([])
    const [bundleInfo, setBundleInfo] = React.useState([])
    const [objects, setObjects] = React.useState([])
    let [bundleName, setBundleName] = React.useState(bundleInfo.name);
    let [bundleSymbol, setBundleSymbol] = React.useState(bundleInfo.symbol);
    let [bundleBrand, setBundleBrand] = React.useState(bundleInfo.brand);
    const [loading, setLoading] = React.useState();
    isLoadingState(loading)

    React.useEffect(() => {
        setBundleItems(row.bundleItems)
        setBundleInfo(row);
    }, [row])

    React.useEffect(() => {
        setLoading(false)
    }, [loading])

    React.useEffect(() => {
        setBundleBrand(bundleInfo.brand)
        setBundleSymbol(bundleInfo.symbol)
        setBundleName(bundleInfo.name)
        const allRows = apiRef.current.getRowModels(), array = Array.from(allRows, ([key, value]) => ({ key, value }));
        setObjects(array);
    }, [bundleInfo])


    const apiRefContext = useGridApiContext();
    const apiRef = useGridApiRef();
    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        defaultValues: row,
        mode: 'onChange',
    });

    const onSubmit = (data) => {
        apiRefContext.current.updateRows([data]);
        apiRefContext.current.toggleDetailPanel(row.id);
    };

    const handleCellFocusOut = (
        params,
        event,
    ) => {
        const allRows = apiRef.current.getRowModels(), array = Array.from(allRows, ([key, value]) => ({ key, value }));
        setObjects(array);

        event.defaultMuiPrevented = true;
    };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
        const allRows = apiRef.current.getRowModels(), array = Array.from(allRows, ([key, value]) => ({ key, value }));
        setObjects(array);
    };

    let prodArrIds = []
    objects.forEach((data) => {
        let prod = {}
        prod.id = data.value.id
        prod.symbol = data.value.symbol
        prod.name = data.value.name
        prod.quantity = data.value.quantity
        prod.stock = data.value.stock
        prodArrIds.push(prod)
    })


    let fetchObject = {
        'name': bundleName,
        'symbol': bundleSymbol,
        'brand': bundleBrand,
        'bundleItems': prodArrIds.map((item) => { return { 'symbol': item.symbol, 'name': item.name, 'quantity': parseInt(item.quantity) } })
    }

    const handleSaveBundle = (e) => {
        setLoading(true)
    }

    if (loading === true) {
        updateBundle(fetchObject, bundleInfo.id);
    }
    const handleBudleNameChange = (e) => {
        setBundleName(e.target.value)
    }

    const handleBudleSymbolChange = (e) => {
        setBundleSymbol(e.target.value)
    }

    const handleBudleBrandChange = (e) => {
        setBundleBrand(e.target.value);
    }



    const columnsBundle = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            width: 200,
            flex: 1,
            renderCell: AsyncListSymbols
        },
        {
            field: 'name',
            headerName: 'Nazwa produktu',
            width: 200,
            flex: 1,
            renderCell: AsyncSuggestNames
        },

        {
            field: 'quantity',
            headerName: 'ilość w zestawie',
            width: 200,
            flex: 1,
            renderCell: QtyField
        },
        {
            field: 'stock',
            headerName: 'Dostępne na magazynie',
            minWidth: 70,
            flex: 1,
        },
        {
            field: 'symulacja',
            headerName: 'Symulacja ilości zestawów dla wybranego produktu',
            minWidth: 70,
            flex: 1,
            valueGetter: ProductSimulation
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Akcje',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Usuń"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Stack
            sx={{ py: 2, height: 1, boxSizing: 'border-box' }}
            direction="column"
        >

            <Grid
                container
                sx={{ mx: 'auto', width: '90%', mb: 3, mt: 5 }}
            >
                <Grid item xs={4} sx={{ pr: 2 }} className='mobile-100'>
                    <Paper sx={{ boxShadow: "unset" }}>
                        <TextField
                            fullWidth
                            label="Nazwa zestawu"
                            required
                            name='Name'
                            value={(bundleName) ? bundleName : 0}
                            onChange={handleBudleNameChange}
                        >
                        </TextField>
                    </Paper>
                </Grid>
                <Grid item xs={4} sx={{ pr: 2 }} className='mobile-100'>
                    <Paper sx={{ boxShadow: "unset" }}>
                        <TextField
                            fullWidth
                            label="Indywidualny symbol zestawu"
                            required
                            name='Symbol'
                            value={(bundleSymbol) ? bundleSymbol : 0}
                            onChange={handleBudleSymbolChange}
                        >
                        </TextField>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{ boxShadow: "unset" }}>
                        <TextField
                            fullWidth
                            label="Marka"
                            required
                            name='Brand'
                            value={(bundleBrand) ? bundleBrand : 0}
                            onChange={handleBudleBrandChange}
                        >
                        </TextField>
                    </Paper>
                </Grid>

            </Grid>

            <Paper sx={{ flex: 1, mx: 'auto', width: '90%' }}>
                <DataGridPro
                    sx={{
                        backgroundColor: "#fff",
                    }}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnResize
                    disableMultipleColumnsSorting
                    disableColumnSelector
                    rowHeight={80}
                    apiRef={apiRef}
                    onCellFocusOut={handleCellFocusOut}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                        toolbar: { apiRef },
                    }}
                    columns={columnsBundle}
                    rows={bundleItems}
                />

            </Paper >

            <Grid
                container
                sx={{ mx: 'auto', width: '90%', mb: 1, mt: 1 }}
            >
                {/* <Paper sx={{ p: 3, mt: 3, width: '100%' }}>
            Ilość kompletnych zestawów, którą możesz aktualnie uzyskać dla wprowadzonej listy składników:&nbsp;
            <strong> {(bundleItems.map(item => item.stock) != undefined) ? Math.min.apply(null, bundleItems.map(item => (item.stock / item.quantity >= 1) ? Math.min(Math.floor(item.stock / item.quantity)) : '')) : ''}</strong>
  
          </Paper> */}
            </Grid>

            <LoadingButton
                sx={{
                    mt: 3,
                    mb: 3,
                    py: 2,
                    width: '90%',
                    mx: 'auto',
                    background: '#000',
                    color: "#fff",
                    '&:hover': {
                        background:
                            '#313234', color:
                            "#fff"
                    }
                }}
                loading={loading}
                onClick={handleSaveBundle}
            >Zapisz</LoadingButton>

        </Stack>

    );
}