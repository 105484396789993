import * as React from 'react';
import verifyImport from '../../Services/products/import/verifyImport'
import { Box, Typography, Alert, AlertTitle, IconButton, Grid } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from './elements/accordionStyled';
import ItemsTable from './resultTable';
import { getAlert } from './elements/getAlertColor';
import { ToastContainer, toast } from 'react-toastify';
import AcceptImport from './acceptImport';
import { makeStyles } from '@mui/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { isVerifiy } from './dropZone';

let objectId;
let confirm;

let fileRemoved;
export function isRemoved(state) {
    fileRemoved(state)
}

export function isConfirm(state) {
    confirm(state)
}
let isClicked;

let refresh;
const handleRefreshed = () => {
    refresh(true)
}

let obj;
export function ImportId(object) {
    obj(object)
    objectId(object.id)
    isClicked(false)
}


const useStyles = makeStyles((theme) => ({
    myAlert: {
        color: "#000",
        backgroundColor: "#f6f6f6"
    }
}));


export default function AccordionResult(props) {
    const [verify, setVerify] = React.useState([]);
    const [importId, setImportId] = React.useState([]);
    const [click, setClick] = React.useState(false)
    let [removeFile, setRemoveFile] = React.useState('')
    let [uploadData, setUploadData] = React.useState([]);
    const [again, setAgain] = React.useState(false);
    const classes = useStyles();
    refresh = setAgain;
    obj = setUploadData
    fileRemoved = setRemoveFile;
    objectId = setImportId;
    confirm = setVerify;
    isClicked = setClick;

    
    const handleClick = (data) => {
        setClick(true)
        setAgain(true)
        setVerify(data)
    }

    React.useEffect(() => {
        // if (verify.length != 0 && verify.status != 500) toast.success("Plik został wgrany na serwer")
        if (verify.status === 500) toast.error("Błędy plik")
    }, [verify])



    React.useEffect(() => {
        function uploadId(id) {
            this.uploadId = id
        }
        if (removeFile !== 'removed') {
            if (uploadData.length !== 0) {
                if (click === false) {
                    verifyImport(new uploadId(uploadData.id)).then((data) => {
                        data.sort((a, b) => b.count - a.count);
                        setVerify(data);
                        isVerifiy(data);
                    })
                }
            }
            setAgain(false);
        }
         

    }, [importId, again])
    


    React.useEffect(() => {
        if (removeFile === 'removed') setVerify([]);
        setAgain(true)
    }, [removeFile])

    let error = (click === false) ? verify.find(element => element.type.action === 'Error') : false

    return (
        <>
            {verify.status !== 500 &&
                verify.length !== 0 &&
                <Box sx={{ mt: 3, d: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Typography variant='h6' component="h3" >{(click === true) ? `Import wykonany pomyślnie!` : `Weryfikacja importu`}</Typography>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                                sx={{ mb: 3 }}
                            >
                                {(click === true) ? `Zweryfikuj podsumowanie importu` : `Przed zatwierdzeniem importu, zweryfikuj jakie zmiany zostaną wprowadzone!`}

                            </Typography>
                        </Grid>
                        <Grid item>
                            {(click === false) &&
                                <IconButton onClick={handleRefreshed}>
                                    <RefreshIcon />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>



                    {verify.map((data) => (
                        <Accordion key={data.type.name}>
                            <AccordionSummary
                                aria-controls={`${data.type.name}-content`}
                                id={`${data.name}-header`}
                            >
                                <Alert sx={{ width: '100%', alignItems: 'center' }}


                                    icon={(data.count === 0) ? false : ''}
                                    className={(data.count === 0) ? classes.myAlert : null}
                                    severity={getAlert(data.type.action)}>
                                    <AlertTitle sx={{ mb: 0 }}>
                                        <strong>{(click !== true) ? `(${data.count}) ` : ''}</strong>
                                        {data.type.friendlyName}
                                    </AlertTitle>
                                    <strong>
                                        {(click === true) ?
                                            `Wszystkich zmian: (✅${data.successCount}/${data.expectedCount})`
                                            : ''}
                                        {(click === true) ?
                                            (data.expectedCount - data.successCount > 0) ?
                                                `, ❌ Zakończone niepowodzeniem: (${data.expectedCount - data.successCount}) ` : '' : ''
                                        }
                                    </strong>
                                </Alert>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ItemsTable data={data} isConfirmed={click} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Box sx={{ padding: '0 20px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {(click === false) &&
                            <AcceptImport uploadId={importId} setZero={handleClick} disabled={error.count} verify={verify} />
                        }
                    </Box>
                </Box>
            }

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme='dark'
                pauseOnHover
            />
        </>
    )
}
