import { Typography, IconButton, Button } from '@mui/material'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { useDeleteActionsOnConditionsMet, useEditActionsOnConditionsMet, useAddActionsOnConditionsMet, useAddActionsOnConditionsNotMet, useDeleteActionsOnConditionsNotMet, useEditActionsOnConditionsNotMet } from '../../api/integrations/integration.list';

interface ActionsHeaderProps {
    actions: any,
    integrationId: string | undefined,
    ruleId: number,
    onDelete: (func: any, id: number, actions: any) => void,
    onEdit: () => void,
    onSave: (func: any, func1: any, data: any, onEdit: any) => void,
    isEdit: boolean,
    type: string,
    schema: any
}

export default function ActionsHeader({ actions, onSave, isEdit, onEdit, onDelete, integrationId, ruleId, type, schema }: ActionsHeaderProps) {
    const translation = React.useMemo(() => schema?.find((d: any) => d.name == actions.property), [actions])
    const operatorTranslation = React.useMemo(() => translation?.allowedOperations.find((d: any) => d.operator.name == actions.operator), [actions])

    const deleteActionsOnConditionsMet = useDeleteActionsOnConditionsMet(integrationId, ruleId, actions.id, (oldData, newData) => [...oldData, newData])
    const editActionsOnConditionsMet = useEditActionsOnConditionsMet(integrationId, ruleId, actions.id, (oldData, newData) => [...oldData, newData])
    const addActionsOnConditionsMet = useAddActionsOnConditionsMet(integrationId, ruleId, (oldData, newData) => [...oldData, newData])

    const deleteActionsOnConditionsNotMet = useDeleteActionsOnConditionsNotMet(integrationId, ruleId, actions.id, (oldData, newData) => [...oldData, newData])
    const editActionsOnConditionsNotMet = useEditActionsOnConditionsNotMet(integrationId, ruleId, actions.id, (oldData, newData) => [...oldData, newData])
    const addActionsOnConditionsNotMet = useAddActionsOnConditionsNotMet(integrationId, ruleId, (oldData, newData) => [...oldData, newData])

    return (
        <>
            <Typography variant='subtitle2' component="h6">
                {`${translation ? translation.uiNamePl : `Wybierz opcję`}  ->
                ${operatorTranslation ? operatorTranslation.operator.uiNamePl : "Wybierz operator"} -> 
                ${actions.value ? actions.value === "true" ? "Prawda" : actions.value === "false" ? "Fałsz" : actions.value : "Wprowadź wartość"}`}
            </Typography>
            <div>
                {!isEdit ?
                    (<Button variant="text" size="small" onClick={onEdit} startIcon={<EditIcon sx={{ fontSize: 10 }} />}>
                        Edytuj
                    </Button>) :
                    (<Button variant="text" size="small" onClick={() => onSave(
                        type === "conditionMet" ?
                            editActionsOnConditionsMet :
                            editActionsOnConditionsNotMet,
                        type === "conditionMet" ?
                            addActionsOnConditionsMet :
                            addActionsOnConditionsNotMet, actions, onEdit)} startIcon={<EditIcon sx={{ fontSize: 10 }} />}>
                        Zapisz
                    </Button>)}
                <IconButton color="primary" aria-label="delete condition" onClick={() => onDelete(
                    type === "conditionMet" ?
                        deleteActionsOnConditionsMet :
                        deleteActionsOnConditionsNotMet, actions.id, actions)} >
                    <ClearIcon />
                </IconButton>
            </div>
        </>
    )
}
