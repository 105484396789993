import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { fieldComponents } from './typeOfField';

interface ActionsFormInterface {
  actionsSchema: any;
  actions: any;
  onChange: (e: any, action: any, operator: any) => void;
}

export default React.memo(function ActionsForm({ actionsSchema, actions, onChange }: ActionsFormInterface) {
  const allowedOperations = React.useMemo(() => actionsSchema?.find((d: any) => d.name === actions.property)?.allowedOperations, [actionsSchema, actions])
  const allowedConditions = React.useMemo(() => actionsSchema?.find((d: any) => d.name === actions.property), [actions])
  const operator = React.useMemo(() => allowedOperations?.find((d: any) => d.operator.name === actions.operator) as any, [allowedOperations, actions])

  return (
    <React.Fragment>
      <FormControl id="selectName" sx={{ width: '100%', mt: 1, mb: 1 }}>
        <InputLabel variant="standard" id="selectNameLabel">
          Wybierz pole
        </InputLabel>
        <Select
          labelId="selectNameLabel"
          id="selectName"
          variant="standard"
          size="small"
          name="property"
          onChange={(e: any) => onChange(e, actions, '')}
          value={actions.property}
        >
          {actionsSchema?.map((o: any, index: any) => (
            <MenuItem key={index} value={o.name}>
              {o.uiNamePl}
            </MenuItem>
          ))}
        </Select>
      </FormControl>


      {allowedOperations && (
        <FormControl sx={{ width: '100%', mt: 1 }}>
          <InputLabel variant='standard'>Wybierz operator</InputLabel>
          <Select
            variant='standard'
            size='small'
            name='operator'
            onChange={(e: any) => onChange(e, actions, operator)}
            value={actions.operator}
          >
            {allowedOperations.map((o: any, index: any) => (
              <MenuItem key={index} value={o.operator.name}>{o.operator.uiNamePl}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {
        allowedOperations && operator && (
          <FormControl sx={{ width: '100%', mt: 1 }}>
            {(() => {
              const FieldComponent = fieldComponents?.[actions?.type]?.component;
              return FieldComponent ? (
                <FieldComponent label={allowedConditions?.uiNamePl} values={actions} onChange={(e: any) => onChange(e, actions, operator)} />
              ) : (
                <p>Nie znaleziono komponentu dla akcji typu: {actions?.type}</p>
              );
            })()}
          </FormControl>
        )
      }
      {/* {allowedOperations?.map((d: any, index: any) => {
        const { component: FieldComponent } = fieldComponents?.[d?.type];
        return (
          <FieldComponent
            key={index}
            name="value"
            Checkbox={actions.value === "True"}
            onChange={(e: any) => onChange(e, actions.id, d.operator.name, d.type)}
          />
        );
      })} */}
    </React.Fragment>
  );
});