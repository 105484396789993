/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { types } from '../config/types';

export default function CalculationTypeInput(props) {
    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState(props.data);

    React.useEffect(() => {
        props.type(value)
    }, [value])

    return (
        <Autocomplete
            disablePortal
            id="calculationType"
            options={types}
            
            value={(props.action === 'edit') ? inputValue : undefined}
            isOptionEqualToValue={(props.action === 'edit') ? (option, value) => option.label === value : undefined}
            inputValue={(props.action === 'edit') ? inputValue : undefined}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            renderInput={(params) => <TextField required {...params} label="Sposób obliczania ceny zakupu" />}
        />
    );
}

