import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import useId from "@mui/material/utils/useId";
import * as React from "react";
import {
    AllowedConditions, AllowedOperations, RulesConditions
} from "../../interfaces/integrations";

import { useParams } from "react-router";
import { Rule, ConditionHeader } from "../../style/rulesBuilder.styled";
import ConditionHeaderInner from "./conditionHeaderInner";
import { fieldComponents } from "./typeOfField";

interface ConditionsProps {
    schema: AllowedConditions[] | undefined,
    conditions: RulesConditions,
    ruleId: number,
    index: number,
    onDelete: any,
    onChange: (e: any, conditionId: number, type: string) => void,
    onSave: (data: any, editRule: any, addCondition: any, onEdit: () => void) => void
}

const Conditions = React.memo(({ schema, conditions, ruleId, index, onDelete, onChange, onSave }: ConditionsProps) => {
    const uniqueId = useId()
    const { id: integrationId } = useParams()
    const [isEdit, setIsEdit] = React.useState(false)
    const allowedOperations = React.useMemo(() => schema?.find((d: AllowedConditions) => d.name === conditions.property)?.allowedOperations, [schema, conditions])
    const allowedConditions = React.useMemo(() => schema?.find((d: AllowedConditions) => d.name === conditions.property), [conditions])
    const operator = React.useMemo(() => allowedOperations?.find((d: any) => d.operator.name === conditions.operator) as AllowedOperations, [allowedOperations, conditions])
    const { component: FieldComponent } = fieldComponents?.[conditions?.type];

    const handleEditCondition = React.useCallback(() => {
        setIsEdit(!isEdit)
    }, [isEdit])

    if (schema !== undefined)
        return (
            <Rule index={index}>
                <ConditionHeader>
                    <ConditionHeaderInner ruleId={ruleId} fieldId={conditions.id} integrationId={integrationId} data={conditions} schema={schema} isEdit={isEdit} onSave={onSave} onDelete={onDelete} onEdit={handleEditCondition} />
                </ConditionHeader>
                {isEdit &&
                    <React.Fragment>
                        <FormControl sx={{ width: '100%', mt: 1 }}>
                            <InputLabel variant='standard' id={uniqueId}>Wybierz pole</InputLabel>
                            <Select
                                labelId={uniqueId}
                                id={uniqueId}
                                variant='standard'
                                size='small'
                                name='property'
                                onChange={(e: any) => onChange(e, conditions.id, '')}
                                value={conditions.property}
                            >
                                {schema.map((o: any, index: any) => (
                                    <MenuItem key={index} value={o.name}>{o.uiNamePl}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {allowedOperations && (
                            <FormControl sx={{ width: '100%', mt: 1 }}>
                                <InputLabel variant='standard' id={uniqueId}>Wybierz operator</InputLabel>
                                <Select
                                    labelId={uniqueId}
                                    id={uniqueId}
                                    variant='standard'
                                    size='small'
                                    name='operator'
                                    onChange={(e: any) => onChange(e, conditions.id, '')}
                                    value={conditions.operator}
                                >
                                    {allowedOperations.map((o: any, index: any) => (
                                        <MenuItem key={index} value={o.operator.name}>{o.operator.uiNamePl}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {allowedOperations && operator && (
                            <FormControl sx={{ width: '100%', mt: 1 }}>
                                <FieldComponent label={allowedConditions?.uiNamePl} values={conditions} onChange={(e: any) => onChange(e, conditions.id, conditions.type)}
                                />
                            </FormControl>
                        )}
                    </React.Fragment>
                }
            </Rule>
        );
    return <>Wczytywanie</>
})

export default Conditions
