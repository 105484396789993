import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'


export const EditButton = (params) => {
    const navigate = useNavigate();
    return (
        <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
                navigate(`edit/${params.row.id}`)
            }}
        >
           Edytuj
        </Button>
    );
};