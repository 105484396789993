import React from 'react'
import {
    TextField
} from '@mui/material';

export default function SupplierListSerachBar({ handleSearch }: { handleSearch(event: string): void }) {

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') handleSearch((event.target as HTMLInputElement).value)
    }
    return (
        <TextField
            label="Wyszukaj dostawcę"
            variant="outlined"
            size="small"
            onKeyDown={handleKeyDown}
            fullWidth
            sx={{ background: "#fff", borderRadius: '10px', mb: 2 }}
        />
    )
}


