import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function EditSupplierFields({ handleChange }: {
    handleChange(e:
        {
            target:
            {
                name: string;
                value: string;
            }
        }
    ): void
}) {

    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off">
            <TextField
                required
                onChange={handleChange}
                name='name'
                size="small"
                label="Nazwa"
                placeholder="Nazwa"
            />
            <TextField
                required
                onChange={handleChange}
                name='nip'
                size="small"
                label="Nip"
                placeholder="Nip"
            />
        </Box>
    )
}
