import React from 'react'
import ProductEditProvider from './context/productEdit.context'
import EditProductLayout from './main.layout'


export default function EditProductContext() {
    return (
        <ProductEditProvider>
            <EditProductLayout />
        </ProductEditProvider>
    )
}
