import React from 'react'
import { Chip } from '@mui/material';

export const StockInPill = (params) => {
  if (params.row.calculatedStock >= 1) {
    return (
      <Chip size="small" sx={{ background: '#ecf7ed', lineHeight: 'normal' }} label={params.row.calculatedStock} />
    )
  } else {
    return (
      <Chip size="small" sx={{ background: '#ffeff1', lineHeight: 'normal' }} label={params.row.calculatedStock} />
    )
  }
};