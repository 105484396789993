import { Paper } from '@mui/material';
import React from 'react'
import {
  DragDropContext, Droppable,
  Draggable
} from "react-beautiful-dnd";
import { useParams } from 'react-router';
import {
  useChangeOrder,
  useGetAllRules
} from '../../../api/integrations/integration.list';
import { IntegrationRules } from '../../../interfaces/integrations';
import IntegrationRulesModule from '../integrationRules';
import RuleHeaderInner from '../ruleHeader.inner';
import { RuleHeader, RuleBody } from '../../../style/rulesBuilder.styled';
import { confirmAlert } from 'react-confirm-alert';
import useAlerts from '../../../hooks/useAlerts.hook';
import { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query';
import NewIntegrationButton from '../newIntegrationButton';

export default React.memo(function RulesBuilder() {
  const { id: integrationId } = useParams();
  const { data = [] } = useGetAllRules(integrationId) as { data: IntegrationRules[] }
  const [itemList, setItemList] = React.useState<any>(data);
  const changeOrder = useChangeOrder(integrationId, (oldData, newData) => [...oldData, newData])
  const alerts = useAlerts();
  const sortedData: any = React.useMemo(() => data?.sort((a, b) => a.order - b.order), [data])

  React.useEffect(() => {
    setItemList(sortedData)
  }, [sortedData])

  const handleDelete = React.useCallback((id: number, deleteRule: UseMutationResult<AxiosResponse<any, any>>) => {
    confirmAlert({
      title: '',
      message: 'Czy na pewno chcesz usunąć tą regułę?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => {
            deleteRule.mutateAsync("")
              .then(() =>
                alerts?.onSuccess("Pomyślnie usunięto"))
              .catch((err: any) =>
                alerts?.onError(`Wystąpił błąd podczas usuwania: ${err}`));
            setItemList(itemList?.filter((w: any) => w.id !== id));
          },
        },
        {
          label: 'Nie',
          onClick: () => null
        }
      ]
    },
    );
  }, [itemList])

  const handleDrop = React.useCallback((droppedItem: any) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    const orderList = updatedList.map((d, index) => { return { id: Number(d.id), order: index + 1 } })
    updatedList.map((d, index) => d.order = index + 1)
    changeOrder.mutateAsync(orderList).catch((err) => console.log(err));
    setItemList(updatedList);
  }, [itemList]);

  const handleAdd = React.useCallback(async (mutationAdd: UseMutationResult<AxiosResponse<any, any>>) => {
    try {
      const { data } = await mutationAdd.mutateAsync(null);
      setItemList([
        ...itemList,
        data
      ]);
    } catch (e) {
      console.log('cannot add')
    }
  }, [itemList]);


  return (
    <React.Fragment>
      <NewIntegrationButton onAdd={handleAdd} />
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemList?.map((item: any, index: any) => (
                <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                  {(provided) => (
                    <Paper
                      sx={{ mt: 1 }}
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <RuleHeader>
                        <RuleHeaderInner integrationId={integrationId} item={item} onDelete={handleDelete} />
                      </RuleHeader>
                      <RuleBody>
                        <IntegrationRulesModule key={item.id} rules={item} />
                      </RuleBody>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
})
