import { AlertColor } from "@mui/material";
import * as React from "react";
import { useSnackbar } from "notistack";

interface AlertActions {
  onError(text: string): void;
  onWarning(text: string): void;
  onInfo(text: string): void;
  onSuccess(text: string): void;
}

export const AlertsContext = React.createContext<AlertActions | null>(null);
const ProductEditProvider = ({ children }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const RenderAlert = (severity: AlertColor, text: string) => {
    enqueueSnackbar(text, { variant: severity });
  };
  const value: AlertActions = {
    onError: (text: string) => RenderAlert("error", text),
    onWarning: (text: string) => RenderAlert("warning", text),
    onInfo: (text: string) => RenderAlert("info", text),
    onSuccess: (text: string) => RenderAlert("success", text),
  };
  return (
    <AlertsContext.Provider value={value}>{children}</AlertsContext.Provider>
  );
};

export default ProductEditProvider;
