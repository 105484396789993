
const principleForm = [
    {
        label: "Nazwa grupy",
        attribute: 'name',
        component: 'text-field',
        type: 'text',
        validation: {
            required: true,
            maxLength: 150,
            minLength: 5
        },
        col: {
            xs: 12
        }
    },
    {
        label: "Cena brutto",
        attribute: 'priceGross',
        component: 'text-field',
        type: 'number',
        validation: {
            required: true,
            min: 0,
        },
        col: {
            xs: 6
        }
    },
    {
        label: "Ilość",
        attribute: 'stock',
        component: 'text-field',
        type: 'number',
        validation: {
            required: true,
            min: 0,
        },
        col: {
            xs: 6
        }
    },
    {
        label: "Cena poniżej",
        attribute: 'priceBelow',
        component: 'text-field',
        type: 'number',
        default: null,
        validation: {
            required: false,
            min: 1,
            max: 99999999999
        },
        col: {
            xs: 6
        }
    },
    {
        label: "Maksymalna dostępność",
        attribute: 'maxDayAvailability',
        component: 'text-field',
        type: 'number',
        default: null,
        col: {
            xs: 6
        }
    },
    {
        label: "Ceneo: Tylko dostępne",
        attribute: 'ceneoOnlyInStock',
        component: 'checkbox-group',
        validation: {
            required: false,
        },
    },
    {
        label: "Ceneo: Kup teraz",
        attribute: 'ceneoBuyNow',
        component: 'checkbox-group',
        validation: {
            required: true,
        },
    },
    {
        label: "Google Merchant",
        attribute: 'googleMarchent',
        component: 'checkbox-group',
        validation: {
            required: true,
        },
    },
    {
        label: "Reguła aktywna",
        attribute: 'isActive',
        component: 'checkbox-group',
        validation: {
            required: false,
        },
    },
]

export {
    principleForm
}