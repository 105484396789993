import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { columns } from './config/columns'
import CircularProgress from '@mui/material/CircularProgress';
import { columnsVisibility } from '../../../Pages/ProductHunter/Config/ProductHunterList/columnsVisibility'

export default function PopupTable({ products, websiteId }) {
  return (
    <div style={{ display: 'flex', height: '500px', justifyContent: 'center' }}>
      {(products.length !== 0) ? (
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            initialState={{ columns: { columnVisibilityModel: columnsVisibility[websiteId] } }}
            columns={columns}
            rows={products}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </div >
  )
}
