import * as React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Checkbox } from '@mui/material';
import updateBundle from '../../../Services/productBundles/updateBundle';

export const IsChecked = (params) => {
  const [isChecked, setIsChecked] = React.useState({ 'isChecked': params.row.isActive });

  const handleChange = (e) => {

    const updateObject = {
      'name': params.row.name,
      'symbol': params.row.symbol,
      'brand': params.row.brand,
      'bundleItems': null,
      'isActive': !isChecked.isChecked,
    }
    updateBundle(updateObject, params.row.id)

    setIsChecked({
      ...isChecked,
      [e.target.name]: e.target.checked
    });

  }

  React.useEffect(() => {
    setIsChecked({ 'isChecked': params.row.isActive })
  }, [params.row.isActive])

  return (
    <Checkbox
      icon={<CheckCircleOutlineOutlinedIcon />}
      checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />}
      onChange={(e) => handleChange(e)}
      name='isChecked'
      checked={isChecked.isChecked}
    />
  );
};