import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import deleteUser from '../../Services/settings/DeleteUser'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import RenderUserList from './usersList';
import { useNavigate, useLocation, useLinkClickHandler } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function DeleteUser(props) {
    const history = useNavigate();
    const handleClick = (event) => {

        confirmAlert({
            title: '',
            message: 'Czy na pewno chcesz usunąć tego użytkownika?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => {
                        deleteUser(props.userid);
                        history(`/settings/users?delete=${props.userid}`);
                        toast.success("Pomyślnie usunięto użytkownika")
                    },
                },
                {
                    label: 'Nie'
                }
            ]
        },
        );
    };



    return (
     
            <Button
                variant="text"
                color="error"
                onClick={handleClick}
            >
                <RemoveCircleOutlineIcon />
            </Button>
        
    )
}