import React from 'react';
import { TabPanelProps } from '../../../@types/products/edit/models.d'
import { Box, Tab} from '@mui/material';
import { styled } from '@mui/material/styles';

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledTab = styled((props: any) => <Tab {...props} />)(
    () => ({
        fontSize: '.9rem',
    })
)

export {
    StyledTab,
    a11yProps,
    TabPanel
}