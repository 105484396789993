import { Paper } from "@mui/material";
import React from "react";
import SupplierListSerachBar from "../../../shared/components/DiscountingSystem/SupplierListSerachBar";
import DiscountingSystemDataGrid from "../../../shared/components/DiscountingSystem/dataGrid/dataGrid";
import { StandardDiscountingDataService } from "./Services/DataService/dataService";
import useAlerts from "../../../hooks/useAlerts.hook";
import { GridRowParams } from "@mui/x-data-grid";
import EditModal from "../../../shared/components/DiscountingSystem/dataGrid/editModal";
import { IFormBuilder } from "~/src/shared/components/DiscountingSystem/dataGrid/IFormBuilder";

export default function StandardDiscounting() {
  const [rows, setRows] = React.useState<any>([]);
  const [filteredRows, setFilteredRows] = React.useState<any>(rows);
  const [isUpdate, setIsUpdate] = React.useState(false);

  const apiService = React.useMemo(() => {
    return new StandardDiscountingDataService();
  }, []);
  const alerts = useAlerts();

  const handleSearch = (ev: any) => {
    function searchSuppliers(ev: string, rows: any): any {
      let filteredList = rows.filter((e: any) => {
        let byName = e?.name.toLowerCase();
        let supplierNip = e?.supplierNip.toLowerCase();
        let supplierName = e?.supplierName.toLowerCase();
        return (
          byName.includes(ev.toLowerCase()) ||
          supplierNip.includes(ev.toLowerCase()) ||
          supplierName.includes(ev.toLowerCase())
        );
      });
      return filteredList;
    }
    setFilteredRows(searchSuppliers(ev, rows));
  };

  const handleAdd = async (term: any): Promise<any> => {
    if (term.percentageValueType === "Na zapytanie u dostawcy")
      term.percentageValue = 0;
    delete term.percentageValueType;
    setIsUpdate(true);
    let response = await apiService.postTradeTerm(term);
    if (response?.status > 210) {
      alerts?.onError("Wystąpił błąd podczas dodawania");
    } else {
      alerts?.onSuccess("Pomyślnie dodano nowy warunek handlowy");
    }
  };

  async function fetchData() {
    const data = await apiService.GetTradeTerm();
    let array: any = [];
    data.forEach((d: any) => {
      const object = {
        id: d?.id,
        name: d.name,
        percentageValue: d?.percentageValue,
        supplierName: d?.supplier?.name,
        supplierNip: d?.supplier?.nip,
        calculationTypeName: d?.calculationType?.name,
      };
      array.push(object);
    });
    setRows(array);
    setFilteredRows(array);
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  const [suppliers, setSuppliers] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      setSuppliers(await apiService.GetAllSupplier());
    }
    fetchData();
  }, []);

  const formBuilder: IFormBuilder[] = [
    {
      inptLabel: "Nazwa",
      inputName: "name",
      fieldType: "text",
      type: "string",
      defaultValue: "",
      visible: true,
    },
    {
      inptLabel: "Wybierz dostawcę dla tej grupy rabatowej",
      inputName: "supplierId",
      fieldType: "autocomplete",
      type: "number",
      values: suppliers,
      defaultValue: "",
      visible: true,
    },
    {
      inptLabel: "Sposób obliczania ceny zakupu",
      inputName: "calculationType",
      fieldType: "autocomplete",
      type: "number",
      defaultValue: "",
      values: [
        { name: "Od ceny hurtowej", id: "DealersPriceRebate" },
        { name: "Od ceny katalogowej", id: "ListPriceRebate" },
      ],
      visible: true,
    },
    {
      inptLabel: "Rabat",
      inputName: "percentageValueType",
      fieldType: "autocomplete",
      type: "number",
      defaultValue: "",
      values: [
        { name: "Wprowadź wartość", id: "Wprowadź wartość" },
        { name: "Na zapytanie u dostawcy", id: "Na zapytanie u dostawcy" },
      ],
      visible: true,
    },
    {
      inptLabel: "Wartość rabatu",
      inputName: "percentageValue",
      fieldType: "text",
      type: "string",
      defaultValue: "",
      visible: false,
      // data?.percentageValueType === "Na zapytanie u dostawcy"
      //   ? false
      //   : data.length !== 0
      //   ? true
      //   : false,
    },
  ];

  const handleEdit = () => {};

  const getValues = (_data: any) => {
    // console.log(data);
  };

  const data: any = {
    filteredRows,
    cols: [
      {
        field: "actions",
        type: "actions",
        renderCell: (params: GridRowParams) => (
          <EditModal
            {...params}
            formBuilder={formBuilder}
            handleAdd={handleEdit}
            buttonTitle={"Edytuj"}
            modalHeader={"Edytuj produkt"}
            getValues={getValues}
          />
        ),
      },
      { field: "name", headerName: "Nazwa grupy rabatowej", width: 400 },
      { field: "supplierName", headerName: "Nazwa dostawcy", width: 350 },
      { field: "supplierNip", headerName: "Nip dostawcy", width: 150 },
      {
        field: "calculationTypeName",
        headerName: "Sposób obliczania rabatu",
        width: 150,
        valueGetter: (params: GridRowParams) => {
          switch (params.row.calculationTypeName) {
            case "ListPriceRebate":
              return "Od ceny katalogowej";
            case "DealersPriceRebate":
              return "Od ceny hurtowej";
            default:
              return "";
          }
        },
      },
      {
        field: "percentageValue",
        headerName: "Wartość rabatu",
        width: 150,
        valueGetter: (params: GridRowParams) =>
          `${params.row.percentageValue}%`,
      },
    ],
  };

  return (
    <Paper sx={{ height: "100%", my: 2, p: 2 }}>
      <SupplierListSerachBar handleSearch={handleSearch} />
      <div
        style={{
          display: "flex",
          height: "calc(100% - 56px)",
          background: "#fff",
          borderRadius: "10px",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DiscountingSystemDataGrid data={data} handleAdd={handleAdd} />
        </div>
      </div>
    </Paper>
  );
}
