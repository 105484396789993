import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UserList from './usersList';
import CreateNewUser from './newUser/CreateNewUser';
import { PrivateRouteVisible } from '../../routes';
import hasPermission from '../../Services/auth/CheckPermission';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { name: "Lista użytkowników", permissionName: hasPermission('Permissions.Settings.Users.List.View'), id: 1 },
        { name: "Nowy użytkownik", permissionName: hasPermission('Permissions.Settings.Users.Add.Edit'), id: 0 }
    ]

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#fff'
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Setting Tab"
                >

                    {tabs.map((item) => (
                        <Tab
                            key={item.name}
                            label={item.name}
                            disabled={!item.permissionName}
                            
                        />
                    ))}

                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Settings.Users.List.View') }}>
                    <UserList />
                </PrivateRouteVisible>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Settings.Users.Add.Edit') }}>
                    <CreateNewUser />
                </PrivateRouteVisible>
            </TabPanel>
        </Box>
    );
}