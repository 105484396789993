import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { token } from './Services/Connnection';
/** Layouts **/
import DashboardRoute from "./layouts/DashboardLayout";
// import LoginRoute from "./layouts/LoginLayout";

/** Components Login **/
import LoginPage from './components/login/LoginForm';

/** Components Dasboard **/
import DashboardLayout from './components/dashboard/DashboardLayout';
import Account from './components/dashboard/Account';
import CustomerListResults from './components/settings/settingsPage';
import ProductsList from './components/wareHouse/productList';
import hasPermission from './Services/auth/CheckPermission';
import ProductView from './components/wareHouse/productView';
import ProductBundles from './components/productBundles/productBundlesList';
import ProdHunterSettings from './components/settings/productHunter/prodHuterSettings';
import WebsiteSettingsEdit from './components/settings/productHunter/websiteSettingsEdit';
import MainImportPage from './Pages/ProductManago-Import/mainImportPage';
import EditProductContext from './Pages/ProductManago-Edit/main.context';
import SupplierEdit from './components/suppliers/viewLayout';
import TradeTermLayout from './components/suppliers/tradeTerm/tradeTermLayout'
import IntegrationLayout from './components/integrations/main.layout'
import IntegrationsList from './Pages/Integrations/integrationsList'
import PrincipleCeneo from './components/integrations/Ceneo/main.layout'
import ProductGrid from './Pages/ProductManago-List/productGrid'
import DataGridProvider from './actions/DataGrid/dataGridContext'
import ProductHunterMainLayout from './Pages/ProductHunter'
import AdvancedDiscounting from './Pages/DiscountingSystem/AdvancedDiscounting';
import AdvancedDiscountingProvider from './actions/AdvancedDiscounting/AdvancedDiscountingContext';
import SupplierList from './Pages/DiscountingSystem/SupplierList'
import StandardDiscounting from './Pages/DiscountingSystem/StandardDiscounting'
import IntegrationBuilder from './Pages/Integrations/integrationBuilder'

const PrivateRoute = ({ auth: { isAuth }, children }) => {
  return (isAuth === true) ? children : <Navigate to="/" />;
};

export const PrivateRouteVisible = ({ auth: { isAuth }, children }) => {
  return (isAuth === true) ? children : '';
};

function App() {

  if (!token) {
    return <LoginPage />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<DashboardRoute />} >
          <Route path="/dashboard" element={<DashboardLayout />} />
          <Route path="/account" element={<Account />} />
          <Route path="/warehouse" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Warehouse.View') }}><ProductsList /></PrivateRoute>} />
          <Route path="/settings/users" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Settings.Users.View') }}><CustomerListResults /></PrivateRoute>} />
          <Route path="/integration/productHunter" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Settings.ProductHunter.View') }}><ProdHunterSettings /></PrivateRoute>} />
          <Route path="/integration/productHunter/edit/:id" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Settings.ProductHunter.Website.Edit') }}><WebsiteSettingsEdit /></PrivateRoute>} />
          <Route path="/warehouse/edit/:id" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Warehouse.View') }}><ProductView content="" title="Edycja produktu" /></PrivateRoute>} />
          <Route path="/products/import" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.Import.Edit') }}><MainImportPage /></PrivateRoute>} />
          <Route path="/product-sets" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.ProdSets.View') }}><ProductBundles /></PrivateRoute>} />
          <Route path="/products/edit/:id" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><EditProductContext /></PrivateRoute>} />
          <Route path="/products/settings/suppliers" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.AdvancedProducts.Suppliers.View') }}><SupplierEdit /></PrivateRoute>} />
          <Route path="/products/settings/tradeTerm" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.AdvancedProducts.Suppliers.TradeTerm.Edit') }}><StandardDiscounting /></PrivateRoute>} />
          <Route path="/products/settings/tradeTerm-old" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.AdvancedProducts.Suppliers.TradeTerm.Edit') }}><TradeTermLayout /></PrivateRoute>} />
          {/* <Route path="/integration/list" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Integration.View') }}><IntegrationLayout /></PrivateRoute>} /> */}
          <Route path="/integration/list" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Integration.View') }}><IntegrationsList /></PrivateRoute>} />
          <Route path="/integration/:id" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Integration.Edit') }}><IntegrationBuilder /></PrivateRoute>} />
          <Route path="/integration/principleCheckbox/" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Integration.PrincipleCeneo.View') }}><PrincipleCeneo /></PrivateRoute>} />
          <Route path="/products/list" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><DataGridProvider><ProductGrid /></DataGridProvider></PrivateRoute>} />
          <Route path="/hunter/list" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><DataGridProvider><ProductHunterMainLayout /></DataGridProvider></PrivateRoute>} />
          <Route path="/hunter/list/:id" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><DataGridProvider><ProductHunterMainLayout /></DataGridProvider></PrivateRoute>} />
          <Route path="/advanced-discounting" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><AdvancedDiscountingProvider><AdvancedDiscounting /></AdvancedDiscountingProvider></PrivateRoute>} />
          <Route path="/suppliers-list" element={<PrivateRoute auth={{ isAuth: hasPermission('Permissions.Products.View') }}><AdvancedDiscountingProvider><SupplierList /></AdvancedDiscountingProvider></PrivateRoute>} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="*" element={<h2>Nic nie znaleziono: 404!</h2>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;   