import React from 'react'
import { Box, Modal, Fade, Backdrop, Typography, Grid } from '@mui/material';
import { style } from '../../../common/modalStyle';
import { closeModal } from './tradeTermLayout';
import TradeTermInput from './tradeTermInput'
import editTradeTerm from '../../../Services/suppliers/tradeTerm/editTradeTerm';

export default function EditModal(state) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(state.isOpen);
    }, [state.isOpen])

   

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={closeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ backgroundColor: '#1e1f21', color: '#fff', p: 2 }}>
                            <Typography variant="h6" component="h2">
                                Edytuj grupę rabatową
                            </Typography>
                        </Box>
                        <Box sx={{ p: 4 }}>
                            <Grid
                                spacing={2}
                                container
                            >
                                <TradeTermInput type={'edit'} data={state.modalData} fetch={editTradeTerm} />
                            </Grid>
                        </Box>

                    </Box>

                </Fade>
            </Modal>
        </div>
    )
}
