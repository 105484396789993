function dealerPrice(params) {
    var formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: (params.row['DealersPrice.Currency.IsoCode']) ? params.row['DealersPrice.Currency.IsoCode'] : 'PLN',
    });
    return (params.row[`DealersPrice.Currency.IsoCode`]) ? formatter.format(params.row[params.field]) : params.row[params.field]
}

function listPrice(params) {
    var formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: (params.row['ListPrice.Currency.IsoCode']) ? params.row['ListPrice.Currency.IsoCode'] : 'PLN',
    });
    return (params.row[`ListPrice.Currency.IsoCode`]) ? formatter.format(params.row[params.field]) : params.row[params.field]
}

function tradeTerm(params) {
    var formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: (params.row[`DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Currency.IsoCode`]) ?
            params.row[`DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Currency.IsoCode`] : 'PLN',
    });
    return (params.row[`DefaultSuppliedProduct.DefaultSuppliedPrice.TradeTermPrice.Currency.IsoCode`]) ? formatter.format(params.row[params.field]) : params.row[params.field]
}

function formatToPercent(params) {
    return (params.row[params.field]) ? `${params.row[params.field]}%` : '';
}

const translateCalcType = (params) => {
    switch (params['row'][params.field]) {
        case 'DealersPriceRebate':
            return 'Od ceny hurtowej'
        case 'ListPriceRebate':
            return 'Od ceny katalogowej'
    }
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}


const dateTimeFormat = (params) => {
    return (params['row'][params.field]) ? formatDate(new Date(params['row'][params.field])) : null
}

const dealerDateTime = (params) => {
    return (params['row']['DealersPrice.LastModified'] != null) ?
        formatDate(new Date(params['row']['DealersPrice.Created'])) :
        (params['row']['DealersPrice.LastModified']) ?
            formatDate(new Date(params['row']['DealersPrice.LastModified'])) : null
}

const listDateTime = (params) => {
    return (params['row']['ListPrice.LastModified'] != null) ?
        formatDate(new Date(params['row']['ListPrice.Created'])) :
        (params['row']['ListPrice.LastModified']) ?
            formatDate(new Date(params['row']['ListPrice.LastModified'])) : null
}

const createdDateTime = (params) => {
    return formatDate(new Date(params['row']['Created'])) 
}

export {
    dealerPrice,
    listPrice,
    formatToPercent,
    tradeTerm,
    translateCalcType,
    dateTimeFormat,
    listDateTime,
    dealerDateTime,
    createdDateTime
}