import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ExportDialog = ({ openDial, closeDialog, columns, getColumns }: any) => {
  let [checked, setChecked] = React.useState<any>([]);

  React.useEffect(() => {
    setChecked(columns);
  }, []);

  const handleChange = (ev: { target: { name: string; checked: boolean } }) => {
    let index: any = checked.findIndex((d: any) => d.field === ev.target.name);
    checked[index] = { ...checked[index], checked: ev.target.checked };
    setChecked([...checked]);
  };

  const handleExport = () => {
    getColumns(checked);
  };

  const saveFields = () => {
    localStorage.setItem("exportColumns", JSON.stringify(checked));
  };

  const handleSelectAll = () => {
    checked?.forEach((d: any) => {
      d.checked = true;
    });
    setChecked([...checked]);
  };

  const handleUnSelectAll = () => {
    checked?.forEach((d: any) => {
      d.checked = false;
    });
    setChecked([...checked]);
  };

  return (
    <BootstrapDialog
      onClose={closeDialog}
      fullWidth={true}
      maxWidth={"md"}
      open={openDial}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={closeDialog}>
        Wskaż kolumny do eksportu
      </BootstrapDialogTitle>

      <DialogActions>
        <Button
          sx={{ mr: 1 }}
          variant="text"
          size="small"
          onClick={handleSelectAll}
        >
          Zaznacz wszystkie
        </Button>
        <Button variant="outlined" size="small" onClick={handleUnSelectAll}>
          Odznacz wszystkie
        </Button>
      </DialogActions>

      <DialogContent dividers>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Wybierz pola</FormLabel>
          <FormGroup>
            {checked.map((d: any) => (
              <FormControlLabel
                key={d.field}
                control={
                  <Checkbox
                    checked={d.checked}
                    name={d.field}
                    onChange={handleChange}
                  />
                }
                label={d.headerName}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveFields}>Zapisz konfigurację pól</Button>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button variant="contained" onClick={handleExport}>
          Eksportuj plik
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export { ExportDialog };
