import * as React from 'react';
import {
    useGridApiContext,
    GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { SaveViewButton, ViewListItem } from './index'
import { makeActions, INITIAL_STATE } from './reducer/index.tsx';
import { GetColumnsSettings, deleteCustomView } from '../services/index'

let modelView;
var RestoreView = (model) => {
    modelView(model)
}

let load;
var NewLoading = (x) => {
    load(x)
}

function CustomViews({ page }) {

    const apiRef = useGridApiContext();
    const [state, dispatch] = React.useReducer(makeActions, INITIAL_STATE);
    let [model, setModel] = React.useState([])
    let [loading, setLoading] = React.useState({ 'status': false })
    load = setLoading
    GetColumnsSettings(page);
    modelView = setModel

    React.useEffect(() => {
        if (model.length !== 0)
            dispatch({
                type: 'restoreView',
                label: model.label,
                value: model.value
            });
    }, [model])

    React.useEffect(() => {
        let retrievedObject = 'defaultView';
        retrievedObject = localStorage?.getItem(`${page}-ActiveView-id`)
        let id = JSON.parse(retrievedObject)
        if (id && model.length !== 0) {
            let activeView = state?.['activeViewId']
            let view = state?.views?.[activeView]?.value

            if (apiRef.current.state.pagination.pageSize === view.pagination?.pageSize) {
                apiRef.current.restoreState(view);
            }
        }
    }, [loading])

    React.useEffect(() => {
        let retrievedObject = 'defaultView';
        retrievedObject = localStorage?.getItem(`${page}-ActiveView-id`)
        let id = JSON.parse(retrievedObject)
        if (id && model.length !== 0) {
            if (model?.label === id) {
                apiRef.current.restoreState(model?.value);
            }
        }
        dispatch({ type: 'setActiveView', id: id });
    }, [apiRef, model])


    const createNewView = () => {
        dispatch({
            type: 'createView',
            page: page,
            value: apiRef.current.exportState(),
        });
    };

    const handleNewViewLabelChange = (event) => {
        dispatch({ type: 'setNewViewLabel', label: event.target.value });
    };

    const handleDeleteView = React.useCallback((viewId) => {
        deleteCustomView(viewId, page)
        dispatch({ type: 'deleteView', id: viewId });
    }, []);

    const handleSetActiveView = (viewId) => {
        apiRef.current.restoreState(state.views[viewId]?.value);
        localStorage.setItem(`${page}-ActiveView-id`, JSON.stringify(viewId))
        dispatch({ type: 'setActiveView', id: viewId });
    };

    const handlePopperAnchorClick = (event) => {
        dispatch({ type: 'togglePopper', element: event.currentTarget });
        event.stopPropagation();
    };

    const handleClosePopper = () => {
        dispatch({ type: 'closePopper' });
    };

    const isNewViewLabelValid = React.useMemo(() => {
        if (state.newViewLabel.length === 0) {
            return false;
        }

        return Object.values(state.views).every(
            (view) => view.label !== state.newViewLabel,
        );
    }, [state.views, state.newViewLabel]);

    const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
    const popperId = canBeMenuOpened ? 'transition-popper' : undefined;

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            dispatch({ type: 'closePopper' });
        } else if (event.key === 'Escape') {
            dispatch({ type: 'closePopper' });
        }
    };

    return (
        <GridToolbarContainer>
            <Button
                aria-describedby={popperId}
                type="button"
                size="small"
                id="custom-view-button"
                aria-controls={state.isMenuOpened ? 'custom-view-menu' : undefined}
                aria-expanded={state.isMenuOpened ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handlePopperAnchorClick}
            >
                Widoki ({Object.keys(state.views).length})
            </Button>
            <ClickAwayListener onClickAway={handleClosePopper}>
                <Popper
                    id={popperId}
                    open={state.isMenuOpened}
                    anchorEl={state.menuAnchorEl}
                    role={undefined}
                    transition
                    placement="bottom-start"
                    sx={{ zIndex: 999 }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <MenuList
                                    autoFocusItem={state.isMenuOpened}
                                    id="custom-view-menu"
                                    aria-labelledby="custom-view-button"
                                    onKeyDown={handleListKeyDown}
                                    sx={{
                                        maxHeight: "300px",
                                        overflow: "auto"
                                    }}
                                >
                                    {Object.entries(state.views).map(([viewId, view]) => (
                                        <ViewListItem
                                            key={viewId}
                                            view={view}
                                            viewId={viewId}
                                            selected={viewId === state.activeViewId}
                                            onDelete={handleDeleteView}
                                            onSelect={handleSetActiveView}
                                        />
                                    ))}
                                </MenuList>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </ClickAwayListener>
            <SaveViewButton
                label={state.newViewLabel}
                onLabelChange={handleNewViewLabelChange}
                onSubmit={createNewView}
                isValid={isNewViewLabelValid}
            />
        </GridToolbarContainer>
    );
}

export {
    CustomViews,
    RestoreView,
    NewLoading
}