import React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";
import EditModal from "./editModal";
import { IFormBuilder } from "./IFormBuilder";
import { StandardDiscountingApiService } from "../../../../Pages/DiscountingSystem/StandardDiscounting/Services/ApiService/apiService";

export default function GridToolBar({ handleAdd }: any) {
  const apiService = new StandardDiscountingApiService();
  const [suppliers, setSuppliers] = React.useState([]);
  const [data, setData] = React.useState<any>([]);
  React.useEffect(() => {
    async function fetchData() {
      setSuppliers(await apiService.GetAllSupplier());
    }
    fetchData();
  }, []);

  const getValues = (values: string) => {
    setData(values);
  };

  const formBuilder: IFormBuilder[] = [
    {
      inptLabel: "Nazwa",
      inputName: "name",
      fieldType: "text",
      type: "string",
      defaultValue: "",
      visible: true,
    },
    {
      inptLabel: "Wybierz dostawcę dla tej grupy rabatowej",
      inputName: "supplierId",
      fieldType: "autocomplete",
      type: "number",
      values: suppliers,
      defaultValue: "",
      visible: true,
    },
    {
      inptLabel: "Sposób obliczania ceny zakupu",
      inputName: "calculationType",
      fieldType: "autocomplete",
      type: "number",
      defaultValue: "",
      values: [
        { name: "Od ceny hurtowej", id: "DealersPriceRebate" },
        { name: "Od ceny katalogowej", id: "ListPriceRebate" },
      ],
      visible: true,
    },
    {
      inptLabel: "Rabat",
      inputName: "percentageValueType",
      fieldType: "autocomplete",
      type: "number",
      defaultValue: "",
      values: [
        { name: "Wprowadź wartość", id: "Wprowadź wartość" },
        { name: "Na zapytanie u dostawcy", id: "Na zapytanie u dostawcy" },
      ],
      condition: {
        setVisible: "percentageValue",
        valueEqual: "Wprowadź wartość",
      },
      visible: true,
    },
    {
      inptLabel: "Wartość rabatu",
      inputName: "percentageValue",
      fieldType: "text",
      type: "string",
      defaultValue: "",
      visible:
        data?.percentageValueType === "Na zapytanie u dostawcy"
          ? false
          : data.length !== 0
          ? true
          : false,
    },
  ];

  return (
    <GridToolbarContainer>
      <EditModal
        formBuilder={formBuilder}
        handleAdd={handleAdd}
        getValues={getValues}
        buttonTitle="Dodaj nowego dostawcę"
        modalHeader="Dodaj nowego dostawcę"
      />
    </GridToolbarContainer>
  );
}
