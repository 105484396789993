import React from 'react'
import { token, host } from '../Connnection';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material';

const Input = styled('input')({
  display: 'none',
});

export default function UploadFile(props) {
  const history = useNavigate();

  const successUpload = () => toast.success("Załadowano pomyślnie 🎉");
  function onImageLoad(e) {
    uploadForm(e.target.files[0]);
    history(`/account?imageName=${e.target.files[0].name}`)
  }
  function handleSubmit(e) {
    onImageLoad(e);
  }
  function uploadForm(file) {
    let form = new FormData();
    form.append('image', file);
    fetch(`${host}/api/User/AddImage`, {
      method: 'put',
      headers: {
        'Authorization': 'Bearer ' + token,
      },
      body: form
    }).then(res => successUpload());

  }

  return (
    <div>
      <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" onImageLoad={(e) => onImageLoad(e)} onChange={(e) => handleSubmit(e)} />
        <IconButton color="primary" aria-label="upload picture" component="span" >

          <Avatar
            className="avatarImage"
            src={props.avatarSrc}
            sx={{
              height: 250,
              width: 250,
              transition: "all .3s",
              '&:hover': {
                transition: "all .3s",
                filter: 'brightness(90%)'
              }
            }}
          >
          </Avatar>
          <PhotoCamera
            className="PhotoCameraIco"
            sx={{
              zIndex: "999",
              display: "block",

              position: "absolute",
              color: "#fff",
              fontSize: "2rem",
            }} />

        </IconButton>
      </label>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme='dark'
        pauseOnHover
      />
    </div>
  )

}