import * as React from "react";
import Button from "@material-ui/core/Button";
import {
  GridAddIcon,
  GridApiContext,
  // GridFilterForm,
  // GridFilterItem,
  // GridLinkOperator,
  GridPanelContent,
  GridPanelFooter,
  GridPanelWrapper,
//   useGridSelector,
  // useGridState,
} from "@mui/x-data-grid";

export function GridFilterPanel() {
  const apiRef = React.useContext<any>(GridApiContext);
  // const [gridState] = useGridState(apiRef!);
//   const { disableMultipleColumnsFiltering } = useGridSelector(
//     apiRef,
//     optionsSelector
//   );

  // const hasMultipleFilters = React.useMemo(
  //   () => gridState.filter.items.length > 1,
  //   [gridState.filter.items.length]
  // );

  // const applyFilter = React.useCallback(
  //   (item: GridFilterItem) => {
  //     apiRef!.current.upsertFilter(item);
  //   },
  //   [apiRef]
  // );

  // const applyFilterLinkOperator = React.useCallback(
  //   (operator: GridLinkOperator) => {
  //     apiRef!.current.applyFilterLinkOperator(operator);
  //   },
  //   [apiRef]
  // );

  // const addNewFilter = React.useCallback(() => {
  //   apiRef!.current.upsertFilter({});
  // }, [apiRef]);

  // const deleteFilter = React.useCallback(
  //   (item: GridFilterItem) => {
  //     apiRef!.current.deleteFilter(item);
  //   },
  //   [apiRef]
  // );

  // React.useEffect(() => {
  //   if (gridState.filter.items.length === 0) {
  //     addNewFilter();
  //   }
  // }, [addNewFilter, gridState.filter.items.length]);

  return (
    <GridPanelWrapper>
      <GridPanelContent>
        {/* {gridState.filter.items.map((item: GridFilterItem, index: number) => (
          <GridFilterForm
            key={item.id}
            item={item}
            applyFilterChanges={applyFilter}
            deleteFilter={deleteFilter}
            hasMultipleFilters={hasMultipleFilters}
            showMultiFilterOperators={index > 0}
            multiFilterOperator={gridState.filter.linkOperator}
            disableMultiFilterOperator={index !== 1}
            applyMultiFilterOperatorChanges={applyFilterLinkOperator}
          />
        ))} */}
      </GridPanelContent>
 
        <GridPanelFooter>
          <Button
            // onClick={addNewFilter}
            startIcon={<GridAddIcon />}
            color="primary"
          >
            {apiRef!.current.getLocaleText("filterPanelAddFilter")}
          </Button>
          test
        </GridPanelFooter>

    </GridPanelWrapper>
  );
}
