import * as React from 'react';
import { ListItemText, ListItem, IconButton, ListItemIcon, Typography, Box } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PrincipleList() {
  const { principleList, handleDeletePrinciple, handleModal, modal } = React.useContext(CeneoPrincipleContext);

  const confirmDelete = (d) => {
    confirmAlert({
      title: '',
      message: 'Czy na pewno chcesz usunąć tą regułę?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => { handleDeletePrinciple(d.id) },
        },
        {
          label: 'Nie'
        }
      ]
    })
  }

  return (
    <>
      <Typography variant='h6' component="h3" sx={{ p: 2 }}>Lista reguł</Typography>
      <Box sx={{ overflow: 'auto' }}>
        {principleList?.map((d) => (
          <ListItem button key={d.id}
            className='prodHunter-rangeList-settings'
            onClick={() => { handleModal(!modal, d.id) }}
            sx={{
              '&:hover': {
                background: '#f4f4f4'
              }
            }}>
            <ListItemIcon>
              <FiberManualRecordIcon color={(d.isActive) ? 'success' : 'error'} />
            </ListItemIcon>
            <ListItemText primary={d.name} />
            <IconButton variant="contained" size="small" onClick={(e) => { e.stopPropagation(); confirmDelete(d) }}> <DeleteIcon /></IconButton>
            <IconButton onClick={(e) => { e.stopPropagation(); handleModal(!modal, d.id) }}> <EditIcon /></IconButton>
          </ListItem>
        ))}
      </Box>
    </>
  )
}
