import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { EditSupplierModal } from "../../../../shared/components/DiscountingSystem/EditSupplierModal/index";
import { Supplier } from "../models/Supplier";
import { ISupplierListConfig } from "./ISupplierListConfig";
import EditIcon from "@mui/icons-material/Edit";

export class SupplierListConfig implements ISupplierListConfig {
  public columns: GridColDef<Supplier>[] = [
    {
      field: "id",
      headerName: "Id",
      type: "text",
    },
    {
      field: "name",
      headerName: "Nazwa dostawcy",
      minWidth: 450,
      type: "text",
    },
    {
      field: "nip",
      headerName: "Nip dostawcy",
      type: "text",
    },
    {
      field: "edit",
      headerName: "Edytuj",
      renderCell: (params: GridRenderCellParams) => (
        <EditSupplierModal
          title="Edytuj produkt"
          buttonTitle=""
          buttonIcon={<EditIcon />}
          {...params}
        />
      ),
    },
  ];
}
