import React from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Popover, List, ListItem, ListItemButton, ListItemText, Button } from '@mui/material';
import { DataGridContext } from '../../../actions/DataGrid/dataGridContext';


export default function DataGridToolbarExport() {
    const { handleExport } = React.useContext(DataGridContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : '';

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button aria-describedby={id} onClick={handleClick} size="small">
                <FileDownloadIcon sx={{ fontSize: '1.1rem' }} />
                Eksportuj
            </Button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExport}>
                            <ListItemText primary="Eksportuj widoczne produkty" />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Popover>
        </>
    )
}
