import React from 'react'
import { formConfig } from '../../config/form.config'
import { renderFields } from '../../utils/customfields.js'
import { Grid } from '@mui/material'


export default function RenderForm({ attr, header, productData }) {
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight - 290
    })
    const [prodData, setProdData] = React.useState(productData)

    React.useEffect(() => {
        setProdData(productData)
    }, [productData])

    const handleChange = (e, attr) => {
        setProdData({
            ...prodData,
            [e.target.name]: (attr === 'boolean') ? e.target.checked : e.target.value
        })
    }

    const checkType = (d, prodData, handleChange) => {
        if (d?.renderComponent) {
            return d?.renderComponent
        } else {
            return (
                <Grid key={d?.attribute} item {...d?.col} sx={{ pr: 2 }} className='mobile-100'>
                    {renderFields(d, prodData, handleChange)}
                </Grid>
            )
        }
    }

    React.useEffect(() => {
        let active = true;
        (() => {
            if (!active) {
                return
            }
            function handleResize() {
                setDimensions({
                    height: window.innerHeight - 290
                })
            }
            window.addEventListener('resize', handleResize)
        })()
        return () => {
            active = false
        }
    }, [])

    return (
        <>
            <Grid
                sx={{ py: 1, }}
                style={{ overflow: 'auto', maxHeight: `${dimensions.height}px` }}
                container
            >
                {(formConfig?.[attr]) && (
                    formConfig?.[attr].map((d) => {
                        return (
                            checkType(d, prodData, handleChange)
                        )
                    })
                )}
            </Grid>
        </>
    )
}
