import { token, host } from '../../Connnection';


export default async function postData(object) {
    try {
        let res = await fetch(`${host}/api/PrincipleCheckbox`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })
        return await res.json();
    } catch (error) {
        console.log(error);
    }
}
