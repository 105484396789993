import { StandardDiscountingApiService } from "../ApiService/apiService";

export class StandardDiscountingDataService {
  apiService: any;

  constructor() {
    this.apiService = new StandardDiscountingApiService();
  }

  //   searchSuppliers(value: string, suppliers: any): any {
  //     let filteredList = suppliers.filter((e) => {
  //       let byName = e.name.toLowerCase();
  //       let byNip = e.nip.toLowerCase();
  //       return (
  //         byName.includes(value.toLowerCase()) ||
  //         byNip.includes(value.toLowerCase())
  //       );
  //     });
  //     return filteredList;
  //   }
  async GetAllSupplier() {
    return await this.apiService.GetAllSupplier();
  }

  async postTradeTerm(value: any) {
    return await this.apiService.PostTradeTerm(value);
  }

  async GetTradeTerm() {
    return await this.apiService.GetTradeTerm();
  }
}
