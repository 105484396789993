import React from 'react'
import { TextField, Grid, Button, Autocomplete } from '@mui/material';
import getStatusName from '../../../Services/settings/productHunter/getStatusName'
import { useParams } from 'react-router-dom'
import { saveItemState } from './websiteSettingsEdit.js';
import { editItemState } from './websiteSettingsEdit.js'
import Tooltip from '@mui/material/Tooltip';


export default function NewRange(props) {
    const urlParam = useParams();
    const [values, setValues] = React.useState();
    const [clickedButton, setClickedButton] = React.useState();
    const [disableButton, setDisableButton] = React.useState(false);
    const [disableButtonAuto, setDisableButtonAuto] = React.useState(true);
    const [statusList, setStatusList] = React.useState([]);
    const [rangeStatus, setRangeStatus] = React.useState();
    const [min, setMin] = React.useState((props.qtyMin) ? props.qtyMin : 0);
    const [max, setMax] = React.useState((props.qtyMax) ? props.qtyMax : 0);


    React.useEffect(() => {
        getStatusName().then((data) => {
            let statusList = [];
            data.rangeNames.forEach((data) => {
                let stat = {}
                stat.label = data.status
                statusList.push(stat)
            })
            setStatusList(statusList)
            setClickedButton(false)
        });
    }, [urlParam])


    let createRange = {
        'websiteId': parseInt(urlParam.id),
        'quantityMin': parseInt((min) ? min : null),
        'quantityMax': parseInt((max) ? max : null),
        'nameAvailability': (rangeStatus) ? rangeStatus.label : 0
    }

    let editRange = {
        'statusRangesId': parseInt(props.id),
        'quantityMin': parseInt((min) ? min : null),
        'quantityMax': parseInt((max) ? max : null),
        'nameAvailability': (rangeStatus) ? rangeStatus.label : 0
    }

    const handleMinValueChange = (event) => {
        setMin(event.target.value)

    }
    const handleMaxValueChange = (event) => {
        setMax(event.target.value)

    }

    const handleRangeAdd = () => {
        if (props.type == 'Add') {
            props.request(createRange)
            setMax('')
            setMin('')
            setRangeStatus(null)
        }
        if (props.type == 'Edit') {
            editItemState(true)
            props.request(editRange)
        }
        saveItemState(true)
    }

    React.useEffect(() => {
        if (parseInt(max) !== 0) {
            if (parseInt(max) < parseInt(min)) {
                setDisableButton(true)
            } else {
                setDisableButton(false)
            }
        }
        if (rangeStatus === undefined) {
            setDisableButtonAuto(true)
        } else {
            setDisableButtonAuto(false)
        }
        
    }, [min, max, rangeStatus])

   
    return (
        <Grid item container spacing={2}>
            <Grid item >
            <Tooltip title="Puste pole oznacza nieskończoność" arrow placement='top'>
                <TextField
                    type="Number"
                    sx={{
                        minWidth: '250px'
                    }}
                    label="Stan od"
                    error={(disableButton === true) ? true : false}
                    helperText={(disableButton === true) ? 'Wartość "Od" musi być mniejsza niż "Do"' : ''}
                    name='minValue'
                    value={(min === 0) ? '' : min}
                    required
                    onChange={handleMinValueChange}
                />
                </Tooltip>
            </Grid>
            <Grid item >
            <Tooltip title="Puste pole oznacza nieskończoność" arrow placement='top'>
                <TextField
                    type="Number"
                    sx={{
                        minWidth: '250px'
                    }}

                    label="Stan do"
                    value={(max === 0) ? '' : max}
                    name='maxValue'
                    required
                    onChange={handleMaxValueChange}
                />
                </Tooltip>
            </Grid>
            <Grid item >
                <Autocomplete
                    disablePortal
                    onChange={(event, value) => { setRangeStatus(value); setValues(value)}}
                    options={statusList}
                    value={(props.nameAvai) ? props.nameAvai : (rangeStatus) ? rangeStatus.label : null}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    clearOnEscape={false}
                    sx={{
                        minWidth: '250px'
                    }}
                    renderInput={
                        (params) => <TextField
                            {...params}
                            required
                            value={(props.nameAvai) ? props.nameAvai : ''}
                            label="Termin realizacji"
                        />
                    }
                />
            </Grid>
            <Grid item className='mobile-100'>
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleRangeAdd}
                    disabled={(disableButton) ? disableButton : disableButtonAuto}
                    sx={{
                        height: '55px'
                    }}
                >
                    {props.buttonVal}
                </Button>
            </Grid>
        </Grid>
    )
}
