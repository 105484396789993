import React from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { columns } from '../../Config/ProductHunterList/columns'
import { columnsVisibility } from '../../Config/ProductHunterList/columnsVisibility'
import {
  notFound
} from '../../../../shared/components/DataGridNotFound/notFound'
import {
  ToolBarCustom
} from './dataGridToolbar'

export default function ProductHunterDataGrid({
  products,
  changePage,
  changePageSize,
  pageSize,
  loading,
  handleSelect,
  websiteId,
  handleFilterChange,
  handleSortModelChange
}) {
  const apiRef = useGridApiRef();

  React.useEffect(() => {
    apiRef.current.restoreState({ columns: { columnVisibilityModel: columnsVisibility[websiteId] } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  return (
    <DataGridPro
      rowHeight={35}
      loading={loading}
      rows={products?.items}
      rowCount={products?.totalCount}
      pagination
      apiRef={apiRef}
      columnBuffer={2}
      columnThreshold={2}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      onPageChange={(page) => changePage(page + 1)}
      onPageSizeChange={(pageSizeNumber) => changePageSize(pageSizeNumber)}
      pageSize={pageSize}
      columns={columns}
      rowsPerPageOptions={[20, 50, 100, 200, 500]}
      initialState={{
        columns: {
          columnVisibilityModel: { 'id': false }
        },
        sorting: {
          sortModel: [{ field: 'dateAdded', sort: 'desc' }],
        },
      }}
      getRowClassName={() => `super-app-theme--bg`}
      components={{
        Toolbar: ToolBarCustom,
        NoRowsOverlay: notFound
      }}
      checkboxSelection
      disableSelectionOnClick
      onSelectionModelChange={(e) => { handleSelect(new Set(e)) }}
      onFilterModelChange={handleFilterChange}
      onSortModelChange={handleSortModelChange}
      sx={{ background: '#fff' }}
    />
  )
}
