import React from 'react'
import { Box, Grid } from '@mui/material';
import { IntegrationCard } from '../../components/integrations/integration.card'
import { IntegrationList } from '~/src/interfaces/integrations';
import { useGetIntegrationList } from '../../api/integrations/integration.list';

export default function IntegrationsList() {
    const {
        data,
        isLoading,
        error
    } = useGetIntegrationList();

    if (isLoading) return 'Loading...'
    if (error) return 'An error has occurred: ' + error

    const newData = [
        ...data,
        {
            name: "Zmiana dostępności",
            description: "Konfiguracja dostępności",
            id: 999,
            url: "/integration/productHunter"
        }
    ]

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 1
            }}
        >
            <Box>
                <Grid
                    container
                    spacing={3}
                >
                    {newData.map((d: IntegrationList) => (
                        <Grid
                            item
                            key={d.id}
                            lg={4}
                            md={6}
                            xs={12}
                        >
                            <IntegrationCard data={d} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}
