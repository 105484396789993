import * as React from 'react';
import useDocumentTitle from "../useDocumentTitle"
import { Box, Tab, Tabs, TextField, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProductDimensions from './productWarehouseInfo'
import { useParams } from "react-router-dom";
import renderData from '../../data/wareHouse/ProductData'
import hasPermission from '../../Services/auth/CheckPermission';
import { PrivateRouteVisible } from '../../routes';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const StyledTab = styled((props) => <Tab {...props} />)(
    () => ({
        fontSize: '1rem',
    })
)


export default function ProductView(props) {
    useDocumentTitle(`${props.title}`)
    const params = useParams();
    const [value, setValue] = React.useState(0);
    let [productData, setProductData] = React.useState([]);
    // eslint-disable-next-line
    const [refresh, setRefresh] = React.useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        renderData(params.id).then((data) => {
            setProductData(data)
            setRefresh(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, /*refresh*/])

    const handleRefresh = () => {
        setRefresh(true)
    }

    return (
        <Box>
            <Paper
                sx={{
                    mb: 2,
                    boxShadow: "unset"
                }}>
                <TextField
                    value={productData.name}
                    color="primary"
                    fullWidth
                    size="small"
                    disabled={!hasPermission('Permissions.Products.Info.Edit')}
                    sx={{
                        fontSize: '1.3rem',
                    }}
                >

                </TextField>
            </Paper>
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: "#fff",
                }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable auto tabs example"
                        sx={{
                            display: "grid",
                            marginRight: "14px"
                        }}
                    >
                        <StyledTab label="Wymiary" {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Warehouse.View') }}>
                    <TabPanel value={value} index={0}>
                        <ProductDimensions productData={productData} refresh={handleRefresh} />
                    </TabPanel>
                </PrivateRouteVisible>

            </Box>

        </Box>
    );
}

