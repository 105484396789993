import { api } from './api'
import {
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from 'react-query';
// import { QueryFunctionContext } from 'react-query/types/core/types';
import { AxiosError, AxiosResponse } from 'axios';

type QueryKeyT = [string, object | undefined];

export const fetchApi = <T>({
    queryKey,
    pageParam,
}: any): Promise<T> => {
    const [url, params] = queryKey;
    return api
        .get<T>(url, { params: { ...params, pageParam } })
        .then((res: { data: any }) => res.data);
};

const useGenericMutation = <T, S>(
    func: (data: T | S) => Promise<AxiosResponse<S>>,
    url: string,
    params?: object,
    updater?: ((oldData: T, newData: S) => T) | undefined
) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, T | S>(func, {
        onMutate: async (data) => {
            await queryClient.cancelQueries([url!, params]);

            const previousData = queryClient.getQueryData([url!, params]);
            queryClient.setQueryData<T>([url!, params], (oldData) => {
                return updater ? updater(oldData!, data as S) : (data as T);
            });
            return previousData;
        },
        onError: (_err, _, context) => {
            queryClient.setQueryData([url!, params], context);
        },
        onSettled: () => {
            queryClient.invalidateQueries([url!, params]);
        },
    });
};


export const useFetch = <T>(
    url: string | null,
    params?: object,
    config?: UseQueryOptions<T, Error, T, QueryKeyT>
) => {
    const context = useQuery<T, Error, T, QueryKeyT>(
        [url!, params],
        ({ queryKey }) => fetchApi({ queryKey }),
        {
            enabled: !!url,
            ...config,
        }
    );

    return context;
};

export const useDelete = <T>(
    url: string,
    params?: object,
    updater?: (oldData: T, id: string | number) => T
) => {
    return useGenericMutation<T, string | number>(
        (id) => api.delete(`${url}/${id}`),
        url,
        params,
        updater
    );
};

export const usePost = <T, S>(
    url: string,
    params?: object,
    updater?: (oldData: T, newData: S) => T
) => {
    return useGenericMutation<T, S>(
        (data) => api.post<S>(url, data),
        url,
        params,
        updater
    );
};

export const useUpdate = <T, S>(
    url: string,
    params?: object,
    updater?: (oldData: T, newData: S) => T
) => {
    return useGenericMutation<T, S>(
        (data) => api.patch<S>(url, data),
        url,
        params,
        updater
    );
};

export const usePut = <T, S>(
    url: string,
    params?: object,
    updater?: (oldData: T, newData: S) => T
) => {
    return useGenericMutation<T, S>(
        (data) => api.put<S>(url, data),
        url,
        params,
        updater
    );
};
