import styled from "styled-components";

export const DataNotFound = styled.p`
  font-size: .9rem;
  text-align: center;
  color: #a7a7a7;
`;

export const DataError = styled.p`
  font-size: .9rem;
  text-align: center;
  color: red;
`;

export const FontBold = styled.p`
  font-size: .9rem;
  text-align: center;
  font-weight: 700;
  color: #2c2c2c;
`;