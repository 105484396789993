import { token, host } from '../Connnection';
import { toast } from 'react-toastify';


export default function putNewData(userData) {

    fetch(`${host}/api/User/UpdateUser`, {
      method: "PUT",
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData),
    })
      .then(resp => toast.success("Załadowano pomyślnie 🎉"))
      .catch((err) => toast.warn("Coś poszło nie tak"))
  }