
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { PrivateRouteVisible } from '../../../routes';
import hasPermission from '../../../Services/auth/CheckPermission';
import WebsiteLists from './websiteLists'
import { makeStyles } from "@material-ui/core";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    appContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100vh"
    },

    container: {
        // display: "flex",
        height: "100%",
        width: "100%"
    },
    panel: {
        height: '100%',
        width: "100%"
    }
});


export default function ProdHunterSettings() {
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { name: "Ustawienia ogólne", permissionName: hasPermission('Permissions.Settings.ProductHunter.View'), id: 1 },
        // { name: "Nowy użytkownik", permissionName: hasPermission('Permissions.Settings.ProductHunter.View'), id: 0 }
    ]



    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#fff'
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Setting Tab"
                >

                    {tabs.map((item) => (
                        <Tab
                            key={item.name}
                            label={item.name}
                            disabled={!item.permissionName}

                        />
                    ))}

                </Tabs>
            </Box>
            <Box className={classes.container}>
                <TabPanel value={value} index={0} className={'settings--tabpanel'}>
                    <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Settings.Users.List.View') }}>
                        <Box className={classes.container}>
                            <WebsiteLists />
                        </Box>
                    </PrivateRouteVisible>
                </TabPanel>
            </Box>

        </Box>
    );
}