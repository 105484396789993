import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import deleteBundle from '../../../Services/productBundles/deleteBundle'
import { confirmAlert } from 'react-confirm-alert';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { checkIfDelete } from '../productBundlesList'


export function DeleteButton(params) {
    const handleClick = (e) => {
        confirmAlert({
            title: '',
            message: 'Czy na pewno chcesz usunąć ten zestaw?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => {
                        deleteBundle(params.row.id);
                        checkIfDelete(true);
                        toast.success("Pomyślnie usunięto zestaw");
                    },
                },
                {
                    label: 'Nie'
                }
            ]
        },
        );
    }

    return (
        <IconButton onClick={handleClick} aria-label="delete" size="small">
            <DeleteOutlineIcon color='danger' fontSize="inherit" />
        </IconButton>
    )
}
