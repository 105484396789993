import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const EditProductButton = React.memo((params) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/products/edit/${params.row.id}?tabid=1`)
    }
    return (
        <IconButton onClick={handleClick} size="small"><EditIcon /></IconButton>
    )
})
