import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { UncheckedProducts } from './elements/uncheckedProductsBox.js';
import { CreatedBundles } from './elements/createdBundlesBox.js';
import { CheckedProducts } from './elements/checkedProductsBox';
import { FourthBox } from './elements/fourthBox.js';
import { LineChart } from './elements/lineChart.js';
import { RoundChart } from './elements/roundChart.js';
import { FifthChart } from './elements/fifthChart.js';
import { Helmet } from 'react-helmet'
import { PrivateRouteVisible } from '../../routes';
import hasPermission from '../../Services/auth/CheckPermission';
import onlineProductsReport from '../../Services/dashboard/getOnlineProductReport';

function Dashboard() {
  const [processedState, setProcessedState] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);

      const newRows = await onlineProductsReport();
      if (!active) {
        return;
      }
      setProcessedState(newRows);
      setLoading(false);
    })();
    return () => {
      active = false;
    };

  }, []);

  return (
    <>
      <Helmet>
        <title>Strona główna | Panel Grupa Krawczyk</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          // flexGrow: 1,
          py: 1
        }}
      >
        <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.View') }}>
          <Grid
            container
            spacing={3}
          >
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.ProductHunter.View') }}>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <CheckedProducts data={processedState} loading={loading} />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.ProductHunter.View') }}>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <UncheckedProducts data={processedState} loading={loading} />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Bundle.View') }}>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <CreatedBundles />
              </Grid>
            </PrivateRouteVisible>

            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Bundle.View') }}>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <FourthBox sx={{ height: '100%' }} />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Warehouse.View') }}>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <RoundChart sx={{ height: '100%' }} />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Warehouse.View') }}>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
               
                <FifthChart />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Warehouse.View') }}>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <LineChart sx={{ height: '100%' }} />
              </Grid>
            </PrivateRouteVisible>
            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Dashboard.Warehouse.View') }}>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <FifthChart />
              </Grid>
            </PrivateRouteVisible>
          </Grid>
        </PrivateRouteVisible>
      </Box>
    </>
    )
};

export default Dashboard;


