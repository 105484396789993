

import React from 'react'
import { MarketplaceListComponent } from './MarketplaceListComponent';
import useProduct from '../../hooks/useProduct.hook'

export default function MarketplaceComponent() {
    const { productData } = useProduct();
    return (
        <MarketplaceListComponent data={productData} />
    )
}
