
import { bundleHost } from '../Connnection';

export default async function deleteBundle(bundleId) {

  try {
    let res = await fetch(`${bundleHost}/api/app/bundle/${bundleId}`, {
      method: 'DELETE',
    })
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}