import { token, host } from '../Connnection';

export default async function wareHouseReport() {
    
  try {
    let res = await fetch(`${host}/api/Product/GetWarehouseProductReport`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}