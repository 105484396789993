// @ts-ignore
import { Product } from "../../models/Product";
// @ts-ignore
import { apiService } from "../apiServices/apiService";
import { IProductData } from "./IProductData";
import { confirmAlert } from 'react-confirm-alert';

export class ProductDataService implements IProductData {
    apiService: apiService;

    constructor() {
        this.apiService = new apiService();
    }

    deleteMarketplace(id: number): void {
        confirmAlert({
            title: '',
            message: 'Czy na pewno chcesz usunąć ten marketplace?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => { this.apiService.RemoveMarketplaceEntry(id); this.GetProduct() },

                },
                {
                    label: 'Nie',
                    onClick: () => { },
                }
            ]
        })

    }

    getProductId(): number {
        let url = window.location.pathname;
        var id = parseFloat(url.substring(url.lastIndexOf('/') + 1));
        return id as number
    }

    async GetProduct(): Promise<Product> {
        let id = this.getProductId();
        return this.convertedObject(await this.apiService.GetProduct(id));
    }

    convertedObject(data: any): Product {
        data.brandName = data.brand?.name;
        data.listPriceGross = data.listPrice?.gross
        data.dealersPriceGross = data.dealersPrice?.gross
        return data;
    }
}