import { Grid, Typography } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { ActionsWhenCondition, AllowedActions } from '../../interfaces/integrations';
import AddNewRule from './addNewRule';
import Actions from './actions';
import { confirmAlert } from 'react-confirm-alert';
import useAlerts from '../../hooks/useAlerts.hook';

interface ActionsProps {
  actionsMet: ActionsWhenCondition[];
  actionsSchema: AllowedActions[] | undefined;
  integrationId: string | undefined;
  ruleId: number;
}

export default React.memo(function ActionsMet({
  actionsMet,
  actionsSchema,
  integrationId,
  ruleId
}: ActionsProps) {
  const alerts = useAlerts();
  const [actions, setActions] = useState<ActionsWhenCondition[]>(actionsMet);
  const [schema] = useState<AllowedActions[] | undefined>(actionsSchema);


  const handleAddCondition = useCallback(() => {
    setActions((prev: any) => [
      ...prev,
      {
        id: Math.floor(Math.random() * 199999900) + 1,
        property: '',
        operator: '',
        type: '',
        value: null,
        isNew: true
      }
    ]);
  }, []);

  const handleSaveAction =
    async (editAction: any, addAction: any, data: any, onEdit: any) => {
      onEdit()
      const index: any = actions.find((w: any) => w.id === data.id)

      if (index?.isNew === undefined)
        await editAction.mutateAsync({ ...index }).then(() => alerts?.onSuccess("Pomyślnie edytowano")).catch((err: any) => alerts?.onError(`Wystąpił błąd podczas zapisu: ${err}`));
      if (index?.isNew) {
        const { data: apiData, status } = await addAction.mutateAsync({ ...index })

        if (status === 201) {
          alerts?.onSuccess("Pomyślnie zapisano")
          delete index.isNew
          index.isEdit = true
          setActions(prevConditions => {
            const indexToEdit = prevConditions.findIndex(item => item.id === index.id);
            if (indexToEdit === -1) {
              return prevConditions;
            }
            const updatedObject = {
              ...prevConditions[indexToEdit],
              ...apiData
            };
            const updatedConditions = [
              ...prevConditions.slice(0, indexToEdit),
              updatedObject,
              ...prevConditions.slice(indexToEdit + 1)
            ];
            return updatedConditions;
          });
        }
        else {
          alerts?.onError(`Wystąpił błąd podczas zapisu: ${index}`)
        }
      }
    }




  const handleDelete = useCallback(
    (deleteActionsOnConditionsMet: any, id: number, actions: any) => {
      confirmAlert({
        title: '',
        message: 'Czy na pewno chcesz usunąć tą regułę?',
        buttons: [
          {
            label: 'Tak',
            onClick: () => {
              if (actions.isNew) {
                alerts?.onSuccess('Pomyślnie usunięto');
                setActions((prev) => prev.filter((w) => w.id !== id));
              } else {
                deleteActionsOnConditionsMet
                  .mutateAsync('')
                  .then(() => {
                    alerts?.onSuccess('Pomyślnie usunięto');
                    setActions((prev) => prev.filter((w) => w.id !== id));
                  })
                  .catch((err: any) => {
                    alerts?.onError(`Wystąpił błąd podczas usuwania: ${err}`);
                  });
              }

            }
          },
          {
            label: 'Nie',
            onClick: () => null
          }
        ]
      });
    },
    [alerts]
  );

  const handleActions = useCallback(
    (e: any, action: any, _operator: any) => {

      setActions(prevConditions => {
        const indexToEdit = prevConditions.findIndex(item => item.id === action.id);
        if (indexToEdit === -1) {
          return prevConditions;
        }
        const updatedObject = {
          ...prevConditions[indexToEdit],
          ...(e.target.name !== "value" && {
            [e.target.name]: e.target.value
          }),
          ...(e.target.name === "value" && {
            value: action?.type === "Boolean" ? String(e.target.checked) : e.target.value
          })
        };

        const allowedActions = schema?.find(d => d.name === updatedObject.property)?.allowedOperations
        const allowedOperations = allowedActions?.find(d => d.operator.name === updatedObject.operator)
        if (allowedOperations)
          updatedObject.type = allowedOperations.type
        const updatedConditions = [
          ...prevConditions.slice(0, indexToEdit),
          updatedObject,
          ...prevConditions.slice(indexToEdit + 1)
        ];
        return updatedConditions;
      })

    },
    []
  );



  return (
    <Grid item sx={{ ml: 2 }}>
      <Typography variant="subtitle2" component="h6" sx={{ fontWeight: 700 }}>
        Jeśli warunek spełniony
      </Typography>
      {actions.map((action) => (
        <Actions
          key={action.id}
          actions={action}
          actionsSchema={schema}
          integrationId={integrationId}
          ruleId={ruleId}
          onDelete={handleDelete}
          onSave={handleSaveAction}
          onChange={handleActions}
          type="conditionMet"
        />
      ))}
      <AddNewRule onAddCondition={handleAddCondition} innerText="Dodaj akcję" />
    </Grid>
  );
});