import React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import DraggableDialog from "./EditSupplierModal";
import { Supplier } from "../../../Pages/DiscountingSystem/SupplierList/models/Supplier";


export default function AddNewRecord({
  handleAdd,
}: {
  handleAdd(data: Supplier): void;
}) {
  return (
    <GridToolbarContainer>
      <DraggableDialog handleAdd={handleAdd} />
    </GridToolbarContainer>
  );
}
