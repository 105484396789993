import React from 'react';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { CustomViews } from '../../../shared/components/DataGridToolbar/customView/customViews.dispatch'
import { DataGridExport } from '../../../shared/components/DataGridExport/ToolbarConfigExportButton';
import { ProductManagoDataService } from '../services/DataService/DataService';
import { columns } from './exportColumnsConfig'

export const ToolBarCustom = React.forwardRef(({ filterValue, searchValue, checked }, ref) => {
    const DataService = React.useMemo(() => {
        return new ProductManagoDataService();
    }, []);

    const ExportProducts = (columns) => {
        DataService.ExportProducts(filterValue, searchValue, checked, columns)
    }

    let savedFields = localStorage?.getItem("exportColumns");

    return (
        <GridToolbarContainer
            ref={ref}
            sx={{
                color: '#000',
            }}>
            <CustomViews page="ProductManago" />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <DataGridExport exportProducts={ExportProducts} columns={(savedFields === null) ? columns : JSON.parse(savedFields)} />
        </GridToolbarContainer>
    );
});
