import React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';
import { SkeletonBoxes } from './skeletonBoxes.js';

export function FourthBox() {
  const [loading, setLoading] = React.useState(true)


  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])

  return (
    <Card
      sx={{ height: '100%', position: 'relative', }}
    // {...props}
    >
      <Card sx={{
        backgroundColor: '#f7f7f7',
        zIndex: "999",
        position: 'absolute',
        height: '100%',
        width: "100%",
        opacity: '0.7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography variant="subtitle1" component="p">Tymczasowo nieaktywne</Typography>
      </Card>
      {loading === false ? (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="overline"
              >
                Ilość
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                24
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: 'error.main',
                  height: 56,
                  width: 56
                }}
              >
                <MoneyIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <ArrowDownwardIcon color="error" />
            <Typography
              color="error"
              sx={{
                mr: 1
              }}
              variant="body2"
            >
              12%
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              Od ostatniego miesiąca
            </Typography>
          </Box>
        </CardContent>
      ) : (
        <Box sx={{ width: '100%' }}>
          <SkeletonBoxes />
        </Box>
      )}
    </Card>
  )
};