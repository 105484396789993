import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
} from "@mui/material";
import EditSupplierFields from "./EditSupplierFields";
import { SupplierListDataService } from "../../../../Pages/DiscountingSystem/SupplierList/services/DataService/SupplierListDataService";
import { Supplier } from "../../../../Pages/DiscountingSystem/SupplierList/models/Supplier";
import useAlerts from "../../../../hooks/useAlerts.hook";

const EditSupplierModal: any = React.memo(
  ({ title, row, api, buttonIcon = null, buttonTitle = "Edytuj" }: any) => {
    const [data, setData] = React.useState<Supplier>({
      id: null,
      name: "",
      nip: "",
    });
    const alerts = useAlerts();
    const [open, setOpen] = React.useState<boolean>(false);
    const { id, name = "", nip = "" } = row;
    const DataService = React.useMemo(() => {
      return new SupplierListDataService();
    }, []);
    const handleChange = (ev: { target: { name: any; value: any } }) => {
      setData({
        ...data,
        id,
        [ev.target.name]: ev.target.value,
      });
    };
    const handleSave = async () => {
      let response = await DataService.editSupplier(data);
      if (response?.id >= 0) {
        api.updateRows([data]);
        alerts?.onSuccess("Pomyślnie zaktualizowano");
        setOpen(!open);
      }
      if (response?.status > 210)
        alerts?.onError("Wystąpił błąd podczas aktualizacji.");
    };
    const handleOpen = () => {
      setData({ id: id, name: name, nip: nip });
      setOpen(!open);
    };
    const handleClose = () => {
      setData({ id: null, name: "", nip: "" });
      setOpen(!open);
    };
    return (
      <>
        <Button color="primary" startIcon={buttonIcon} onClick={handleOpen}>
          {buttonTitle}
        </Button>
        <div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
              <EditSupplierFields handleChange={handleChange} data={data} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button autoFocus onClick={handleSave}>
                Zapisz
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
);

export { EditSupplierModal };
