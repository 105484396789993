import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeViewItems from './treeViewItems'
import { categoryList } from '../../config/category.config'


export default function CategoriesTreeMainComponent() {

    const [expanded, setExpanded] = React.useState(['root']);
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        if (event.target.nodeName !== "svg") {
            return;
        }
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        if (event.target.nodeName === "svg") {
            return;
        }
        const first = nodeIds[0];
        if (selected.includes(first)) {
            setSelected(selected.filter(id => id !== first));
        } else {
            setSelected([first, ...selected]);
        }

    };

    let selectedId = []
    const selectedCategories = (data) => {
        if (data?.isChecked) {
            selectedId.push(data?.id)
        };

        if (Array.isArray(data.child)) {
            data.child.map((node) => {
                selectedCategories(node);
            })
        }
    };
    selectedCategories(categoryList)

    React.useEffect(() => {
        setSelected(selectedId)
    }, [])

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            multiSelect
        >
            <TreeViewItems />
        </TreeView>
    );
}
