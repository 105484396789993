import * as React from 'react';
import { Box, Paper, TextField, Typography, Grid, Divider, Checkbox, Avatar, Radio } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import updateDimensions from '../../Services/wareHouse/UpdateWarehouseInfo'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hasPermission from '../../Services/auth/CheckPermission';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import upImg from '../../img/up.png';
import fragileImg from '../../img/fragile.jpg';
import {
    packageTypeForPrepared,
    packageTypeForNoPrepared,
    readyToDelivery,
    expirationDate,
    hasSerialNumberArr,
    updownConst,
    fragileConst
} from './const/warehouseConst';
import { Helmet } from 'react-helmet'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom'

let updateLoading;
export function setLoadingFalse(loading) {
    updateLoading(loading)
}

export default function ProductDimensions(props) {
    const [prodInfo, setProdInfo] = React.useState(props.productData);
    const [readyToDeliveryState, setReadyToDeliveryState] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [expirationDateState, setExpirationDateState] = React.useState('');
    const [hasSerialNumber, setHasSerialNumber] = React.useState('');
    const [updown, setUpdown] = React.useState([{ 'name': updownConst, 'state': false }])
    const [fragile, setFragile] = React.useState([{ 'name': fragileConst, 'state': false }])
    const [packType, setPackType] = React.useState({
        selected: [],
    });
    updateLoading = setLoading;
    const navigate = useNavigate();
    const formRef = React.useRef();

    let readyToDeliveryConst;
    if (readyToDeliveryState !== '' && readyToDeliveryState !== undefined) {
        readyToDeliveryConst = readyToDeliveryState
    }


    function checkFragile() {
        if (updown != null) {
            if (updown[0]['name']) {
                const objectSticker = {
                    'name': updown[0]['name'],
                    'state': updown[0]['state']
                }
                return objectSticker
            }
            return ''
        }
    }

    function checkUpDown() {
        if (fragile != null) {
            if (fragile[0]['name']) {
                const objectSticker = {
                    'name': fragile[0]['name'],
                    'state': fragile[0]['state']
                }
                return objectSticker
            }
            return ''
        }
    }

    function checkStickers() {
        let array = [];
        const updownFunc = checkUpDown()
        const fragileFunc = checkFragile()
        if (updownFunc !== undefined) {
            const objectSticker = {
                'name': updownFunc.name,
                'state': updownFunc.state
            }
            array.push(objectSticker)
        }
        if (fragileFunc !== '') {
            const objectSticker = {
                'name': fragileFunc.name,
                'state': fragileFunc.state
            }
            array.push(objectSticker)
        }
        return array
    }

    function getStcickerObject() {
        const array = [];
        const stickers = checkStickers();

        if (stickers[0]['state'] === true) {

            const stickersObj0 = {
                'name': stickers[0]['name']
            }
            array.push(stickersObj0)
        }
        if (stickers[1]['state'] === true) {
            const stickersObj1 = {
                'name': stickers[1]['name']
            }
            array.push(stickersObj1)
        }
        return array
    }


    function checkIsReadyToShip() {
        if (prodInfo.productReadyToShip !== undefined) {
            if (prodInfo.productReadyToShip == null) {
                return ''
            }
            if (prodInfo.productReadyToShip === true) {
                return 'Tak'
            } else {
                return 'Nie'
            }
        }
    }
    function checkSerialNumber() {
        if (prodInfo.hasSerialNumber !== undefined) {
            if (prodInfo.hasSerialNumber === null) {
                return ''
            }
            if (prodInfo.hasSerialNumber === true) {
                return 'Tak'
            } else {
                return 'Nie'
            }
        }
    }

    function checkExpiration() {
        if (prodInfo.hasValidityPeriod !== undefined) {
            if (prodInfo.hasValidityPeriod == null) {
                return ''
            }
            if (prodInfo.hasValidityPeriod === true) {
                return 'Tak'
            } else {
                return 'Nie'
            }
        }
    }

    let selectedPackCheckbox = []
    packType.selected.forEach((item) => {
        let ob = {};
        ob.name = item
        selectedPackCheckbox.push(ob)
    })


    let prodArr = [];
    if (prodInfo.carriers !== undefined) {
        prodInfo.carriers.forEach((data) => {
            let ob = {};
            ob.name = data.name
            prodArr.push(ob)
        })
    }

    function isSaved(type) {
        if (prodInfo.carriers) {
            let saved = prodInfo.carriers.find(elem => elem.name === type)
            if (saved !== undefined) return true
        }
        return false
    }




    React.useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const { selected } = packType;
        if (checkIsReadyToShip() !== undefined) {
            setReadyToDeliveryState(checkIsReadyToShip())
        }
        setExpirationDateState(checkExpiration())
        setHasSerialNumber(checkSerialNumber())
        if (prodInfo.stickers) {
            if (prodInfo.stickers.find(elem => elem.name === updownConst)) setUpdown([{ 'name': updownConst, 'state': true }])
            if (prodInfo.stickers.find(elem => elem.name === fragileConst)) setFragile([{ 'name': fragileConst, 'state': true }])
        }
        let arr = [];

        prodArr.map((item) =>
            arr.push(item.name)
        );

        setPackType({
            selected: arr
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkIsReadyToShip()])

    React.useEffect(() => {
        setProdInfo(props.productData)
    }, [props.productData])

    function confirmUpdate() {
        let UpdateDimensions = {
            'id': prodInfo.id,
            'height': prodInfo.height,
            'width': prodInfo.width,
            'length': prodInfo['length'],
            'weight': prodInfo.weight,
            'productReadyToShip': (readyToDeliveryState === 'Tak') ? true : false,
            'hasSerialNumber': (hasSerialNumber === 'Tak') ? true : false,
            'hasValidityPeriod': (expirationDateState === 'Tak') ? true : false,
            'carriers': selectedPackCheckbox,
            "stickers": getStcickerObject()
        }


        updateDimensions(UpdateDimensions).then((data) => {
            if (data) {
                if (data.status === 500 || data.status === 400) {
                    toast.error("Wystąpił błąd podczas aktualiacji. Spróbuj ponownie")
                } else if (data.status === 204) {

                    setTimeout(() => {
                        navigate(-1);
                    }, 500)
                    toast.success('Pomyślnie zaktualizowano');
                    props.refresh()
                }
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            }
        })
        setLoading(true)
    }



    const handleChangeFragileSticker = (event) => {
        setFragile([{ 'name': event.target.name, 'state': event.target.checked }]);
    };

    const handleChangeUpDownSticker = (event) => {
        setUpdown([{ 'name': event.target.name, 'state': event.target.checked }]);
    };

    const handleChangeDelivery = (event) => {
        setReadyToDeliveryState(event.target.value);
    };

    const handleExpirationDate = (event) => {
        setExpirationDateState(event.target.value);
    };


    const handleChangePackage = (event) => {
        const { value, checked } = event.target;
        const { selected } = packType;
        (checked) ?
            setPackType({
                selected: [...selected, value],
            })
            :
            setPackType({
                selected: selected.filter((event) => event !== value)
            });
    };

    const handleChangeHasSerialNumber = (event) => {
        setHasSerialNumber(event.target.value);
    };


    const handleChange = (event) => {
        setProdInfo({
            ...prodInfo,
            [event.target.name]: event.target.value
        });
    };


    return (
        <>
            <Helmet>
                <title>Edytuj produkt | Panel Grupa Krawczyk</title>
            </Helmet>
            <form ref={formRef}>
                <Box>
                    <Grid
                        spacing={2}
                        container
                    >

                        <Grid item xs={12}>
                            <Typography variant='h6' component="h3" >Czy produkt jest gotowy do wysyłki?</Typography>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                Produkt gotowy nie wymaga dodatkowego pakowania.
                            </Typography>
                        </Grid>
                        <Grid item className='mobile-100'>
                            <Grid
                                className='mobile-100'
                                item
                                sx={{
                                    display: 'flex',
                                    pl: 0
                                }}>

                                <FormControl
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={(readyToDeliveryConst !== undefined) ? readyToDeliveryConst : 0}
                                        name="radio-buttons-group"
                                        sx={{
                                            ml: 2,
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}>
                                        {readyToDelivery.map((option) => (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column-reverse',
                                                    width: '48%'
                                                }}
                                                className="desktop-100"
                                                key={option}
                                                value={option}
                                                control={<Radio
                                                    onChange={handleChangeDelivery}
                                                />}
                                                name={option}
                                                label={
                                                    <Avatar sx={{
                                                        ...(option === 'Tak' && {
                                                            bgcolor: '#78909c',
                                                            minWidth: 120,
                                                            width: '100%',
                                                            height: 100,
                                                        }),
                                                        ...(option === 'Nie' && {
                                                            bgcolor: '#e57373',
                                                            minWidth: 120,
                                                            width: '100%',
                                                            height: 100,
                                                        }),
                                                    }}
                                                        variant="square">
                                                        {option}
                                                    </Avatar>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {readyToDeliveryState !== '' &&
                            <>
                                <Grid item xs={12}>

                                    <Typography variant='h6' component="h3" >{readyToDeliveryState === 'Tak' ? ('Podaj wymiar opakowania') : ('Podaj wymiar produktu')}</Typography>

                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Wprowadź wartości w poprawnej jednostce miary.
                                    </Typography>

                                </Grid>

                                <Box>
                                    <Divider />
                                </Box>


                                <Grid item className='mobile-100'>

                                    <TextField
                                        type="Number"
                                        sx={{
                                            minWidth: '220px'
                                        }}
                                        fullWidth
                                        disabled={!hasPermission('Permissions.Warehouse.Edit')}
                                        value={`${prodInfo.length}`}
                                        label="Długość"
                                        name='length'
                                        required
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item className='mobile-100'>
                                    <TextField
                                        type="Number"
                                        sx={{
                                            minWidth: '220px'
                                        }}
                                        fullWidth
                                        disabled={!hasPermission('Permissions.Warehouse.Edit')}
                                        value={`${prodInfo.height}`}
                                        name='height'
                                        required
                                        onChange={handleChange}
                                        label="Wysokość"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item className='mobile-100'>
                                    <TextField
                                        type="Number"
                                        sx={{
                                            minWidth: '220px'
                                        }}
                                        fullWidth
                                        disabled={!hasPermission('Permissions.Warehouse.Edit')}
                                        value={`${prodInfo.width}`}
                                        name='width'
                                        required
                                        onChange={handleChange}
                                        label="Szerokość"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='h6' component="h3" >Podaj wagę produktu </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Wprowadź wartości w poprawnej jednostce wagi.
                                    </Typography>
                                </Grid>
                                <Grid item className='mobile-100'>
                                    <TextField
                                        type="Number"
                                        sx={{
                                            minWidth: '220px'
                                        }}
                                        fullWidth
                                        disabled={!hasPermission('Permissions.Warehouse.Edit')}
                                        value={`${prodInfo.weight}`}
                                        name='weight'
                                        required
                                        onChange={handleChange}
                                        label="Waga"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='h6' component="h3" >Wskaż nośnik do wysyłki </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Wybierz sugerowany nośnik do wysyłki towaru.
                                    </Typography>
                                </Grid>
                                {readyToDeliveryState === "Tak" &&
                                    <Grid item className='mobile-100'>
                                        <FormControl
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <FormGroup
                                                aria-labelledby="for-package-prepoared"
                                                name="forPackagePrepared"
                                                sx={{
                                                    ml: 2,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center'
                                                }}>
                                                {packageTypeForPrepared.map((option) => (
                                                    <FormControlLabel
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column-reverse',
                                                            width: '48%'
                                                        }}
                                                        className="desktop-100"
                                                        key={option}
                                                        value={option}
                                                        control={<Checkbox
                                                            onChange={handleChangePackage}
                                                            defaultChecked={isSaved(option)}
                                                        />}
                                                        name={option}
                                                        label={
                                                            <Avatar sx={{
                                                                ...(option === 'Karton' && {
                                                                    bgcolor: '#ffd180',
                                                                    color: '#000',
                                                                    minWidth: 120,
                                                                    width: '100%',
                                                                    height: 100,
                                                                }),
                                                                ...(option === 'Półpaleta' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.9rem',
                                                                    textAlign: 'center',
                                                                }),
                                                                ...(option === 'Paleta niestandardowa' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    textAlign: 'center',
                                                                }),
                                                                ...(option === 'Paleta przemysłowa' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    whiteSpace: "wrap",
                                                                    textAlign: 'center',
                                                                    // mt: 3,
                                                                    pl: 1,
                                                                    pr: 1
                                                                }),
                                                            }}
                                                                variant="square">
                                                                {option}
                                                            </Avatar>
                                                        }
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                }
                                {readyToDeliveryState === "Nie" &&
                                    <Grid item className='mobile-100'>

                                        <FormControl
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <FormGroup
                                                aria-labelledby="for-package-no-prepared"
                                                // value={(packageTypeForPreparedState != undefined) ? packageTypeForPreparedState : 0}
                                                name="forPackageNoPrepared"
                                                sx={{
                                                    ml: 2,
                                                    flexDirection: 'row',
                                                    justifyContent: 'center'
                                                }}>
                                                {packageTypeForNoPrepared.map((option) => (
                                                    <FormControlLabel
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column-reverse',
                                                            width: '48%'
                                                        }}
                                                        className="desktop-100"
                                                        key={option}
                                                        value={option}
                                                        control={<Checkbox
                                                            onChange={handleChangePackage}
                                                            defaultChecked={isSaved(option)}
                                                        />}
                                                        name={option}
                                                        label={
                                                            <Avatar sx={{
                                                                ...(option === 'Karton' && {
                                                                    bgcolor: '#ffd180',
                                                                    color: '#000',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.9rem',
                                                                    textAlign: 'center'
                                                                }),
                                                                ...(option === 'Półpaleta' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.9rem',
                                                                    textAlign: 'center',
                                                                }),
                                                                ...(option === 'Paleta niestandardowa' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    textAlign: 'center',
                                                                }),
                                                                ...(option === 'Paleta przemysłowa' && {
                                                                    bgcolor: '#8d6e63',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    whiteSpace: "wrap",
                                                                    textAlign: 'center',
                                                                    // mt: 3,
                                                                    pl: 1,
                                                                    pr: 1
                                                                }),
                                                                ...(option === 'Koperta' && {
                                                                    bgcolor: '#e0e0e0',
                                                                    color: '#000',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.9rem',
                                                                    textAlign: 'center',
                                                                    // mt: 3
                                                                }),
                                                                ...(option === 'Folia bąbelkowa' && {
                                                                    bgcolor: '#c9ecff',
                                                                    color: '#000',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    textAlign: 'center',
                                                                    // mt: 3
                                                                }),
                                                                ...(option === 'Stretch' && {
                                                                    bgcolor: '#212121',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    textAlign: 'center',
                                                                    // mt: 3
                                                                }),
                                                                ...(option === 'Tektura' && {
                                                                    bgcolor: '#ffe096',
                                                                    color: '#000',
                                                                    minWidth: 120,
                                                                    height: 100,
                                                                    fontSize: '.8rem',
                                                                    textAlign: 'center',
                                                                    // mt: 3
                                                                }),
                                                            }}
                                                                variant="square">
                                                                {option}
                                                            </Avatar>
                                                        }
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>

                                    </Grid>
                                }


                                <Grid item xs={12}>
                                    <Typography variant='h6' component="h3" >Dodatkowe informacje</Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Czy produkt wymaga specjalnego oznakowania przed wysyłką?
                                    </Typography>
                                </Grid>
                                <Grid
                                    className='mobile-100'
                                    item
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                        // pl: 5
                                    }}>


                                    <FormGroup
                                        sx={{
                                            ml: 2,
                                        }}>
                                        <FormControlLabel
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column-reverse'
                                            }}
                                            control={
                                                <Checkbox
                                                    onChange={handleChangeUpDownSticker}
                                                    defaultChecked={updown[0]['state']}
                                                />}
                                            name="Góra / Dół"
                                            label={
                                                <Avatar variant="square" src={upImg}
                                                    sx={{
                                                        bgcolor: '#78909c',
                                                        minWidth: 120,
                                                        width: '100%',
                                                        height: 120,
                                                    }}
                                                ></Avatar>
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        sx={{
                                            ml: 2,
                                        }}
                                    >
                                        <FormControlLabel
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column-reverse'
                                            }}
                                            name="Produkt podatny na uszkodzenia"
                                            control={<Checkbox
                                                onChange={isActive => handleChangeFragileSticker(isActive)}
                                                defaultChecked={fragile[0]['state']}

                                            />}


                                            label={<Avatar variant="square" src={fragileImg}
                                                sx={{
                                                    bgcolor: '#78909c',
                                                    minWidth: 120,
                                                    width: '100%',
                                                    height: 120,
                                                }}
                                            ></Avatar>}
                                        />
                                    </FormGroup>

                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='h6' component="h3" >Czy produkt posiada datę ważności?</Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                    </Typography>
                                </Grid>
                                <Grid item className='mobile-100'>

                                    <FormControl
                                        sx={{
                                            width: '100%'
                                        }}
                                    >
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={(expirationDateState !== undefined) ? expirationDateState : 0}
                                            name="radio-buttons-group"
                                            sx={{
                                                ml: 2,
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>
                                            {expirationDate.map((option) => (
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column-reverse',
                                                        width: '48%'
                                                    }}
                                                    className="desktop-100"
                                                    key={option}
                                                    value={option}
                                                    control={<Radio
                                                        onChange={handleExpirationDate}
                                                    />}
                                                    name={option}
                                                    label={
                                                        <Avatar sx={{
                                                            ...(option === 'Tak' && {
                                                                bgcolor: '#78909c',
                                                                minWidth: 120,
                                                                width: '100%',
                                                                height: 100,
                                                            }),
                                                            ...(option === 'Nie' && {
                                                                bgcolor: '#e57373',
                                                                minWidth: 120,
                                                                width: '100%',
                                                                height: 100,
                                                            }),
                                                        }}
                                                            variant="square">
                                                            {option}
                                                        </Avatar>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant='h6' component="h3" >Czy produkt posiada numery seryjne?</Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                    </Typography>
                                </Grid>
                                <Grid item className='mobile-100'>

                                    <FormControl
                                        sx={{
                                            width: '100%'
                                        }}
                                    >
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={(hasSerialNumber !== undefined) ? hasSerialNumber : 0}
                                            name="radio-buttons-group"
                                            sx={{
                                                ml: 2,
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>
                                            {hasSerialNumberArr.map((option) => (
                                                <FormControlLabel
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column-reverse',
                                                        width: '48%'
                                                    }}
                                                    className="desktop-100"
                                                    key={option}
                                                    value={option}
                                                    control={<Radio
                                                        onChange={handleChangeHasSerialNumber}
                                                    />}
                                                    name={option}
                                                    label={
                                                        <Avatar sx={{
                                                            ...(option === 'Tak' && {
                                                                bgcolor: '#78909c',
                                                                minWidth: 120,
                                                                width: '100%',
                                                                height: 100,
                                                            }),
                                                            ...(option === 'Nie' && {
                                                                bgcolor: '#e57373',
                                                                minWidth: 120,
                                                                width: '100%',
                                                                height: 100,
                                                            }),
                                                        }}
                                                            variant="square">
                                                            {option}
                                                        </Avatar>
                                                    }
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>

                <Paper
                    sx={{
                        mt: 3,
                        p: '10px 0 0',
                        boxShadow: 'unset',
                        borderRadius: "0"
                    }}
                >
                    <LoadingButton
                        sx={{
                            width: {
                                lg: "unset",
                                md: "100%",
                                xs: "100%"
                            },
                            height: '50px',
                            p: "0 40px",

                        }}
                        disabled={!hasPermission('Permissions.Warehouse.Edit')}
                        color="primary"
                        onClick={() => { formRef.current.reportValidity(); confirmUpdate() }}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                    >
                        Zapisz
                    </LoadingButton>
                </Paper>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    theme='dark'
                    pauseOnHover
                />
            </form >
        </>
    );
}

