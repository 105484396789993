import React from 'react'
import {
    DataGridList,
    SearchBar
} from './productsGrid'
import { productsData, columnsConfig, searchDto } from './services'
import { CollapseList } from './components';
import { DataGridContext } from '../../actions/DataGrid/dataGridContext';
import { NewLoading } from '../../shared/components/DataGridToolbar/customView/customViews.dispatch'

function ProductGrid() {
    let [search, setSearch] = React.useState('')
    let [data, setData] = React.useState({ 'columns': columnsConfig(), 'rows': [], 'total': 0 });
    let [pageNumber, setPageNumber] = React.useState(1)
    let [pageSize, setPageSize] = React.useState(50)
    const [sortModel, setSortModel] = React.useState({});
    let [loading, setLoading] = React.useState(false)
    let [filter, setFilter] = React.useState([])
    const { checked } = React.useContext(DataGridContext);

    const targetValue = React.useCallback((ev) => {
        setSearch(ev.target.value)
    }, []);

    const changePage = React.useCallback((page) => {
        setPageNumber(page)
    }, []);

    const changePageSize = React.useCallback((size) => {
        setPageSize(size)
    }, []);

    const onFilterChange = React.useCallback((filterModel) => {
        setFilter(filterModel)
    }, []);

    const handleSortModelChange = React.useCallback((newModel) => {
        let orderBy = newModel?.[0]?.['field']
        let orderDescending = (newModel?.[0]?.['sort'] === 'asc') ? false : true
        setSortModel({ orderBy, orderDescending })
    }, []);

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true)
            const newRows = await productsData(searchDto(search, pageNumber, pageSize, sortModel, filter, checked))
            const newCols = columnsConfig(filter, search);
            NewLoading({ 'status': true })
            if (!active) {
                return
            }
            setData({ 'columns': newCols, 'rows': newRows.items, 'total': newRows.totalCount })
            NewLoading({ 'status': false })
            setLoading(false)
        })()
        return () => {
            active = false
        }
    }, [search, pageNumber, pageSize, sortModel, filter, checked])



    return (
        <>
            <SearchBar search={targetValue} />
            <CollapseList />
            <DataGridList
                data={data}
                total={data.total}
                changePage={changePage}
                changePageSize={changePageSize}
                loading={loading}
                pageSize={pageSize}
                onFilterChange={onFilterChange}
                handleSortModelChange={handleSortModelChange}
                filter={filter}
                searchValue={search}
                checked={checked}
            />
        </>
    )
}

export default ProductGrid