
import {
  Box,
  Button,
  Container,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import createNewUser from '../../../Services/settings/CreateNewUser';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { permissions } from '../../../config/permissionList';
import { ToastContainer, toast } from 'react-toastify';

const Register = () => {

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      role: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(
          'Podaj poprawny adres email')
        .max(255)
        .required(
          'Email jest wymagany'),
      firstName: Yup
        .string()
        .max(255)
        .required(
          'Imię jest wymagane'),
      lastName: Yup
        .string()
        .max(255)
        .required(
          'Nazwisko jest wymagane'),
      password: Yup
        .string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Hasło powinno zawierać min. 8 znaków, jedną dużą literę, jedną małą literę, jedną cyfrę oraz znak specjalny."
        )
        .required(
          'Hasło jest wymagane'),
      role: Yup
        .string()
        .ensure()
        .required(
          'Wybierz rolę użytkownika'),
    }),
    onSubmit: () => {
      createNewUser(formik.values.firstName, formik.values.lastName, formik.values.email, formik.values.password, formik.values.role)
    }
  });

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="md">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Dodaj nowego użytkownika
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Po założeniu konta, użytkownik może zmienić swoje hasło.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.firstName && formik.errors.firstName)}
              fullWidth
              helperText={formik.touched.firstName && formik.errors.firstName}
              label="Imię"
              margin="normal"
              name="firstName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              fullWidth
              helperText={formik.touched.lastName && formik.errors.lastName}
              label="Nazwisko"
              margin="normal"
              name="lastName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Adres email"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Hasło"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
              // helperText="Hasło powinno zawierać cyfry i wielkie litery."
            />
            <FormControl
              fullWidth
              sx={{ mt: 1 }}
            >
              <InputLabel htmlFor="role">Uprawnienia</InputLabel>
              <Select
                error={Boolean(formik.touched.role && formik.errors.role)}
                value={formik.values.role}
                id="role"
                name="role"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                label="Uprawnienia"
              >
                {permissions.map((item) => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ color: '#d32f2f' }}>{formik.touched.role && formik.errors.role}</FormHelperText>
            </FormControl>

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                // disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  pt: 2,
                  pb: 2,
                  mt: 1
                }}
              >
                Zapisz
              </Button>
            </Box>
          </form>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme='dark'
            pauseOnHover
          />
        </Container>

      </Box>
    </>
  );
};

export default Register;