import { token, host } from '../Connnection';


export default function deleteUser(userId) {

    const dataObject =
    {
        id: userId,
    };
    fetch(`${host}/api/User`, {
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    }).then((response) => {
        if (response.ok) {
         
          
        } else {
            // toast.error("Coś poszło nie tak.");
        }
    })
}