/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';
import { Box, Paper, TextField, Typography, Grid, Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
    useGridApiRef,
    DataGridPro,
    GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import AsyncListSymbols from './asyncListSymbols';
import AsyncSuggestNames from './asyncNamesList';
import QtyField from './productQtyField.js';
import saveNewBundle from '../../Services/productBundles/saveBundle'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditToolbar from './editToolbar.js'
import renderData from '../../data/productBundles/getProductBundlesData';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Helmet } from 'react-helmet'

const steps = ['Dodaj zestaw', 'Dodaj składniki do zestawu', 'Podsumowanie'];

export function ProductSimulation(params) {
    if (params.row.stock / params.row.quantity >= 1) {
        return `✅ ${Math.floor(params.row.stock / params.row.quantity)}`
    } else {
        return '⚠️ 0';
    }
}

let bundleAdd;
export function bundleAdded(state) {
    bundleAdd(state)
}


export default function BundleItemInfo(props) {
    let prodArrIds = []
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [rows, setRows] = React.useState(prodArrIds)
    const [checkBundleSymbol, setCheckBundleSymbol] = React.useState();
    const [objects, setObjects] = React.useState([])
    const [bundleName, setBundleName] = React.useState('');
    const [bundleSymbol, setBundleSymbol] = React.useState('');
    const [bundleBrand, setBundleBrand] = React.useState('');
    const [bundleAddState, setBundleAddState] = React.useState([]);
    const apiRef = useGridApiRef();
    let prodArr = []
    bundleAdd = setBundleAddState

    function renderAllSymbols() {
        renderData(bundleSymbol).then((data) => {
            setCheckBundleSymbol(data)
        })
    }

    React.useEffect(() => {
        renderAllSymbols()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundleSymbol])

    function checkSymbolExist() {
        if (checkBundleSymbol) {
            if (checkBundleSymbol[0]) {
                if (checkBundleSymbol[0]['symbol'] == bundleSymbol)
                    return true
            }
            return false
        }
    }

    objects.forEach((data) => {
        let prod = {}
        prod.symbol = data.value.symbol
        prod.name = data.value.name
        prod.quantity = data.value.quantity
        prodArr.push(prod)
    })


    objects.forEach((data) => {
        let prod = {}
        prod.id = data.value.id
        prod.symbol = data.value.symbol
        prod.name = data.value.name
        prod.quantity = data.value.quantity
        prod.stock = data.value.stock
        prodArrIds.push(prod)
    })

    let fetchObject = {
        'name': bundleName,
        'symbol': bundleSymbol,
        'brand': bundleBrand,
        'isActive': (rows.length === 0) ? false : true,
        'bundleItems': rows.map((item) => { return { 'symbol': item.symbol, 'name': item.name, 'quantity': item.quantity } })
    }

    React.useEffect(() => {
        setRows(prodArrIds.map((item) => { return item }))
    }, [objects])

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {

        document.getElementById("root").click();

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            saveNewBundle(fetchObject)
        };
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setRows([])
        setBundleName('')
        setBundleSymbol('')
        setBundleBrand('')
    };

    const handleCellFocusOut = (
        params,
        event,
    ) => {
        const allRows = apiRef.current.getRowModels(), array = Array.from(allRows, ([key, value]) => ({ key, value }));
        setObjects(array);
        event.defaultMuiPrevented = true;
    };

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
        const allRows = apiRef.current.getRowModels(), array = Array.from(allRows, ([key, value]) => ({ key, value }));
        setObjects(array);
    };


    const handleBudleNameChange = (e) => {
        setBundleName(e.target.value)
    }

    const handleBudleSymbolChange = (e) => {
        setBundleSymbol(e.target.value)

    }

    const handleBudleBrandChange = (e) => {
        setBundleBrand(e.target.value)
    }

    let columns = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            minWidth: 70,
            flex: 1,
            renderCell: AsyncListSymbols

        },
        {
            field: 'name',
            headerName: 'Nazwa',
            minWidth: 70,
            flex: 1,
            renderCell: AsyncSuggestNames
        },
        {
            field: 'quantity',
            headerName: 'Ilość',
            minWidth: 70,
            flex: 1,
            renderCell: QtyField
        },
        {
            field: 'stock',
            headerName: 'Dostępne na magazynie',
            minWidth: 70,
            flex: 1,
        },
        {
            field: 'symulacja',
            headerName: 'Symulacja ilości zestawów dla wybranego produktu',
            minWidth: 70,
            flex: 1,
            valueGetter: ProductSimulation
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Akcje',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Usuń"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ]


    return (

        <Box sx={{ width: '100%', height: '100%' }}>
            <Helmet>
                <title>Dodaj nowy zestaw | Panel Grupa Krawczyk</title>
            </Helmet>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        {bundleAddState === 200 &&
                            <Alert severity="success">Pomyślnie dodano zestaw</Alert>
                        }

                        {bundleAddState.status === 403 &&
                            <Alert severity="error">
                                {(bundleAddState) ? (bundleAddState.code) ? bundleAddState.code : bundleAddState.message : bundleAddState}
                            </Alert>
                        }

                        {bundleAddState.status === 400 &&
                            <Alert severity="error">
                                {(bundleAddState) ? (bundleAddState.code) ? bundleAddState.code : bundleAddState.message : bundleAddState}
                            </Alert>
                        }

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        {bundleAddState.status === 403 &&
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                startIcon={
                                    <ModeEditIcon />
                                }
                                sx={{
                                    mr: 1,
                                    background: '#000',
                                    color: "#fff",
                                    '&:hover': {
                                        background: '#313234',
                                        color: "#fff"
                                    }
                                }}
                            >
                                Popraw zestaw
                            </Button>
                        }
                        {bundleAddState.status === 400 &&
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                startIcon={
                                    <ModeEditIcon />
                                }
                                sx={{
                                    mr: 1,
                                    background: '#000',
                                    color: "#fff",
                                    '&:hover': {
                                        background: '#313234',
                                        color: "#fff"
                                    }
                                }}
                            >
                                Popraw zestaw
                            </Button>
                        }
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button onClick={handleReset} startIcon={<AddIcon />} >Dodaj nowy zestaw</Button>

                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ mt: 5, mb: 1 }}>
                        <>
                            {activeStep === 0 &&
                                <>
                                    <Grid
                                        spacing={2}
                                        container
                                    >
                                        <Grid item xs={6} className='mobile-100'>
                                            <Paper sx={{ boxShadow: "unset" }}>
                                                <TextField
                                                    fullWidth
                                                    label="Nazwa zestawu"
                                                    required
                                                    name='Name'
                                                    value={bundleName}
                                                    onChange={handleBudleNameChange}
                                                >
                                                </TextField>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6} className='mobile-100'>
                                            {/* <Paper sx={{ boxShadow: "unset" }}> */}
                                            <TextField

                                                fullWidth
                                                sx={{
                                                    backgroundColor: "#fff",
                                                    "& .MuiFormHelperText-root": { //<--- here
                                                        backgroundColor: "#f7f7f7",
                                                        margin: 0,
                                                        color: '#ed6c02'
                                                    }
                                                }}
                                                color={(checkSymbolExist() === true) ? "warning" : ''}
                                                helperText={(checkSymbolExist() === true) ? "Zestaw o takim symbolu istnieje już w bazie" : ''}
                                                focused={checkSymbolExist()}
                                                label="Indywidualny symbol zestawu"
                                                required
                                                name='Symbol'
                                                value={bundleSymbol}
                                                onChange={handleBudleSymbolChange}
                                            >
                                            </TextField>
                                            {/* </Paper> */}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item sx={{ marginTop: '20px' }}>
                                        <Paper sx={{ boxShadow: "unset" }}>
                                            <TextField
                                                fullWidth
                                                label="Marka"
                                                required
                                                name='Brand'
                                                value={bundleBrand}
                                                onChange={handleBudleBrandChange}
                                            >
                                            </TextField>
                                        </Paper>
                                    </Grid>

                                </>
                            }

                            {activeStep === 1 &&

                                <Box
                                    sx={{
                                        minHeight: '500px',
                                        height: '100%',
                                        display: "flex",
                                        flexDirection: "column"
                                    }}>
                                    <DataGridPro
                                        sx={{
                                            backgroundColor: "#fff",
                                        }}
                                        rows={rows}
                                        disableColumnFilter
                                        disableColumnMenu
                                        disableColumnResize
                                        disableMultipleColumnsSorting
                                        disableColumnSelector
                                        rowHeight={80}
                                        apiRef={apiRef}
                                        onCellFocusOut={handleCellFocusOut}
                                        components={{
                                            Toolbar: EditToolbar,
                                        }}
                                        componentsProps={{
                                            toolbar: { apiRef },
                                        }}
                                        columns={columns}
                                    />
                                </Box>

                            }
                            {activeStep === 2 &&

                                <>
                                    <Grid
                                        spacing={2}
                                        container
                                        sx={{ height: '100%' }}
                                    >
                                        <Grid item xs={12} >
                                            <Typography sx={{ mt: 2, mb: 1, fontWeight: 600 }}>Szczegóły zestawu</Typography>
                                            <TableContainer component={Paper}
                                            // sx={{maxHeight: 400, height: '100%'}}
                                            >
                                                <Table
                                                    // sx={{ maxWidth: 650 }}
                                                    stickyHeader
                                                    aria-label="simple table">
                                                    <TableHead className='fixed_headers'>
                                                        <TableRow>
                                                            <TableCell sx={{ maxWidth: 150, fontWeight: 600 }} align="left" >Nazwa zestawu</TableCell>
                                                            <TableCell sx={{ maxWidth: 150, fontWeight: 600 }} align="left">Symbol zestawu</TableCell>
                                                            <TableCell sx={{ maxWidth: 150, fontWeight: 600 }} align="left">Marka</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody sx={{ maxHeight: 400, height: '100%', overflow: 'auto' }}>
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">
                                                                {bundleName}
                                                            </TableCell>
                                                            <TableCell align="left">{bundleSymbol}</TableCell>
                                                            <TableCell align="left">{bundleBrand}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                        <Grid item xs={12} >
                                            <Typography sx={{ mt: 2, mb: 1, fontWeight: 600 }}>Produkty w zestawie</Typography>
                                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                <TableContainer sx={{ maxHeight: 300 }}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{ width: 150, fontWeight: 600 }} align="left">Nazwa</TableCell>
                                                                <TableCell sx={{ width: 150, fontWeight: 600 }} align="left">Symbol</TableCell>
                                                                <TableCell sx={{ width: 150, fontWeight: 600 }} align="left">Ilość</TableCell>
                                                                <TableCell sx={{ width: 150, fontWeight: 600 }} align="left">Symulacja ilości zestawów dla wybranego produktu</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((item) => (
                                                                <TableRow
                                                                    key={item.id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell align="left">{item.name}</TableCell>
                                                                    <TableCell align="left">{item.symbol}</TableCell>
                                                                    <TableCell align="left">{item.quantity}</TableCell>
                                                                    <TableCell align="left">{(item.stock / item.quantity >= 1) ?
                                                                        `✅ ${Math.floor(item.stock / item.quantity)}` : '⚠️ 0'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                            <Paper sx={{ p: 3, mt: 3 }}>
                                                Ilość kompletnych zestawów, którą możesz aktualnie uzyskać dla wprowadzonej listy składników:&nbsp;
                                                <strong>{Math.min.apply(null, rows.map(item => (item.stock / item.quantity >= 1) ? Math.min(Math.floor(item.stock / item.quantity)) : 0))}</strong>
                                            </Paper>
                                        </Grid>
                                    </Grid>


                                </>

                            }
                        </>
                    </Box>


                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Wróć
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            sx={{ mt: 3, background: '#000', color: '#fff', '&:hover': { background: '#313234', color: '#fff' } }}
                            onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Dodaj zestaw' : 'Dalej'}
                        </Button>
                    </Box>
                </React.Fragment>
            )
            }


        </Box >
    );
}


