import React from 'react'
import PrincipleAdd from './principle.add';
import PrincipleList from './principle.list'
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet'
import CeneoPrincipleProvider from './context/ceneoPrinciple.context'
import PrincipleModal from './principle.modal'
import { ToastContainer } from 'react-toastify';

const PrincipleCeneo = () => (
    <CeneoPrincipleProvider>
        <Helmet>
            <title>Konfiguracja reguł ceneo | Panel Grupa Krawczyk</title>
        </Helmet>
        <Paper sx={{ height: '100%', p: 2 }}>
            <PrincipleAdd />
            <PrincipleList />
        </ Paper>
        <PrincipleModal />
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme='dark'
            pauseOnHover
        />
    </CeneoPrincipleProvider>

)

export default PrincipleCeneo