import { token, host } from '../../Connnection';
import { IAddSymbols } from '../../../@types/integration/integration.d.tsx'



export default async function DeleteRowGrid(object: IAddSymbols) {
    try {
        let res = await fetch(`${host}/api/PrincipleCheckbox/DeleteSymbols`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })
        return await res.json();
    } catch (error) {
        console.log(error);
    }
}
