import React from 'react';
import putRole from '../../Services/settings/PutRole'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { permissions } from '../../config/permissionList';

export function PermissionsList(props) {
    const [role, setRole] = React.useState('');
    const handleChange = (event) => {
        setRole(event.target.value);
        putRole(props.userid, event.target.value)
    };

    return (
        <Select
            label="Uprawnienia"
            value={role}
            onChange={handleChange}

        >
            {permissions.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
        </Select>
    )
}