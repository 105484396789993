import * as React from 'react';
import { AdvancedDiscountingContextType } from './IAdvancedDiscountingContext';

export const AdvancedDiscountingContext = React.createContext(null) as AdvancedDiscountingContextType;

const AdvancedDiscountingProvider: React.FC<React.ReactNode> = ({ children }) => {
    const data: string[] = ['test']

    return (
        <AdvancedDiscountingContext.Provider value={{ data }}>
            {children}
        </AdvancedDiscountingContext.Provider>
    );
};

export default AdvancedDiscountingProvider;