import { IconButton, Typography, Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import {
    useAddCondition,
    useEditRule
} from "../../api/integrations/integration.list";
import React from 'react'
import { useDeleteCondition } from '../../api/integrations/integration.list';

interface ConditionHeaderProps {
    data: any;
    schema: any;
    isEdit: boolean;
    integrationId: string | undefined;
    ruleId: number;
    fieldId: number | undefined;
    onSave: (data: any, editRule: any, addCondition: any, onEdit: () => void) => void;
    onEdit: () => void;
    onDelete: (deleteCondition: any, data: any) => void
}
export default function ConditionHeaderInner({ integrationId, ruleId, fieldId, data, schema, isEdit, onSave, onEdit, onDelete }: ConditionHeaderProps) {
    const translation = React.useMemo(() => schema?.find((d: any) => d.name == data.property), [data])
    const operatorTranslation = React.useMemo(() => translation?.allowedOperations.find((d: any) => d.operator.name == data.operator), [data])
    const deleteCondition = useDeleteCondition(integrationId, ruleId, fieldId, (oldData, newData) => [...oldData, newData])
    const editRule = useEditRule(integrationId, ruleId, fieldId, (oldData, newData) => [...oldData, newData])
    const addCondition = useAddCondition(integrationId, ruleId, (oldData, newData) => [...oldData, newData])

    return (
        <>
            <Typography variant='subtitle2' component="h6">{`${translation?.uiNamePl || "Wybierz opcję"} -> ${operatorTranslation?.operator?.uiNamePl || "Wybierz opcję"} -> ${data.value || "Wprowadź wartość"}`}</Typography>
            <div>
                {!isEdit ?
                    (<Button variant="text" size="small" onClick={onEdit} startIcon={<EditIcon sx={{ fontSize: 10 }} />}>
                        Edytuj
                    </Button>) :
                    (<Button variant="text" size="small" onClick={() => onSave(data, editRule, addCondition, onEdit)} startIcon={<EditIcon sx={{ fontSize: 10 }} />}>
                        Zapisz
                    </Button>)}
                <IconButton color="primary" aria-label="delete condition" onClick={() => onDelete(deleteCondition, data)}>
                    <ClearIcon />
                </IconButton>
            </div>

        </>
    )
}
