const menuItems = [
    {
        id: 1,
        label: "Ogólne",
        header: "Ogólne ustawienia",
        active: true,
        subTitle: "Lorem",
        attribute: "general",
    },
    {
        id: 2,
        label: "Historia cen",
        header: "Historia",
        active: false,
        attribute: "priceHistory",
    },
    {
        id: 3,
        label: "Kategorie",
        header: "Lista kategorii",
        active: true,
        attribute: "categories",
    },
    {
        id: 4,
        label: "Stany magazynowe",
        active: true,
        attribute: "stock",
    },
    {
        id: 5,
        label: "Wymiarowanie",
        header: "Wymiary produktów",
        active: true,
        attribute: "warehouseDimensions",
    },
    {
        id: 6,
        label: "Edycja grup rabatowych",
        active: true,
        attribute: "discountGroupEdit",
    },
    {
        id: 7,
        label: "Historia zmian",
        active: true,
        attribute: "productHistory",
    },
     {
        id: 8,
        label: "Marketplace",
        active: true,
        attribute: "marketplace",
    }

]

export {
    menuItems
}