import React from 'react'
import {
  DataGridPro,
  GridColumns
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { columns } from './config/tradeTermColumns'


export default function TradeTermList(props) {

  return (

    <div style={{ display: 'flex', height: 500 }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          columns={columns}
          rows={props.data}
        />
      </div>
    </div>
  )
}
