import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography, useTheme, Skeleton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js';


export const LineChart = (props) => {
    let [loading, setLoading] = React.useState(true)
    const theme = useTheme();

    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])

    const data = {
        datasets: [
            {
                backgroundColor: '#3F51B5',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: [18, 5, 19, 27, 29, 19, 20, 44, 55],
                label: 'Ten rok',
                maxBarThickness: 10
            },
            {
                backgroundColor: '#EEEEEE',
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: [11, 20, 12, 29, 30, 25, 13, 11, 22],
                label: 'Ostatni rok',
                maxBarThickness: 10
            }
        ],
        labels: ['1 luty', '2 luty', '3 luty', '4 luty', '5 luty', '6 luty', '7 luty', '8 luty', '9 luty']
    };

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                }
            }
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card {...props} sx={{ position: "relative" }} >

            <Card sx={{
                backgroundColor: '#f7f7f7',
                zIndex: "999",
                position: 'absolute',
                height: '100%',
                width: "100%",
                opacity: '0.7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="subtitle1" component="p">Tymczasowo nieaktywne</Typography>
            </Card>
            {loading == false ? (
                <>
                    <CardHeader
                        title="Wykres"
                    />
                    <Divider />
                    <CardContent>
                        <Box
                            sx={{
                                height: 400,
                                position: 'relative'
                            }}
                        >
                            <Bar
                                data={data}
                                options={options}
                            />
                        </Box>
                    </CardContent>
                    <Divider />
                </>
            ) : (
                <Box sx={{ height: 400, m: 1 }}>
                    {[...Array(10)].map((_) => (
                        <Skeleton sx={{ my: 2, mx: 1 }} />
                    ))}
                </Box>

            )}

        </Card>
    );
};
Chart.register(CategoryScale)