import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Avatar,
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { getInitials } from '../../common/get-initials';
import getUsersList from '../../Services/settings/UsersListService'
import Switch from "react-switch";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { StyledHeadCell } from '../../common/userListStyle'
import putAllowAccess from '../../Services/settings/PutAllowAccess'
import { PermissionsList } from './userListSelectPermission'
import DeleteUser from './deleteUser'
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PrivateRouteVisible } from '../../routes';
import hasPermission from '../../Services/auth/CheckPermission';

let updateUser;
export async function usersList() {
    let getUserObject = await getUsersList();
    let usersList = getUserObject.users
    let usersListData = [];
    usersList.forEach(name => {
        let row = {};
        row.id = name.id
        row.email = name.email
        row.firstname = name.firstName
        row.lastname = name.lastName
        row.profilePicture = name.profilePicture
        row.roleName = name.roleName
        row.isActive = name.isActive
        usersListData.push(row);
    });
    updateUser(usersListData)
}

function RenderUserList() {
    const [allUsers, setAllUsers] = useState([]);

    useNavigate();
    const currentURL = window.location.href
    let url = new URL(currentURL);
    let search_params = url.searchParams;
    let deleteUserId = search_params.get('delete');

    useEffect(() => {
        updateUser = setAllUsers
        usersList();
    }, [deleteUserId]);



    const handleChange = (activeStatus, itemId) => {
        const productIndex = allUsers.findIndex(function (
            item,
            index
        ) {
            return item.id === itemId;
        });

        let products = [...allUsers];
        let product = { ...products[productIndex] };
        product.isActive = activeStatus;
        products[productIndex] = product;

        setAllUsers(products);
        putAllowAccess(itemId, activeStatus);
    };

    return (
        <div>
            <Card >
                <PerfectScrollbar>
                    <Box sx={{ width: '100%' }} container justify={"center"}>
                        <Table style={{ overflowX: "auto" }}>
                            <TableHead
                                sx={{
                                    backgroundColor: "#f7f7f7"
                                }}>
                                <TableRow>
                                    <StyledHeadCell>
                                        Imię i nazwisko
                                    </StyledHeadCell>
                                    <StyledHeadCell>
                                        Email
                                    </StyledHeadCell>
                                    <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Settings.Users.List.Edit') }}>
                                        <StyledHeadCell>
                                            Rola
                                        </StyledHeadCell>
                                        <StyledHeadCell>
                                            Aktywny
                                        </StyledHeadCell>
                                        <StyledHeadCell>
                                            Usuń
                                        </StyledHeadCell>
                                    </PrivateRouteVisible>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allUsers.map(function (item, index) {
                                    return (
                                        <TableRow
                                            hover
                                            key={item.id}

                                        >
                                            <TableCell>
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex'
                                                    }}
                                                >
                                                    <Avatar
                                                        src={item.profilePicture}
                                                        sx={{ mr: 2 }}
                                                    >
                                                        {getInitials(item.firstname)}
                                                    </Avatar>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="body1"
                                                    >

                                                        {item.firstname} {item.lastname}
                                                    </Typography>

                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                {item.email}
                                            </TableCell>
                                            <PrivateRouteVisible auth={{ isAuth: hasPermission('Permissions.Settings.Users.List.Edit') }}>
                                                <TableCell>
                                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                        <InputLabel id="demo-simple-select-standard-label">{item.roleName}</InputLabel>
                                                        <PermissionsList userid={item.id} />
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <div className="bar-color">
                                                        <Switch
                                                            checked={item.isActive}
                                                            onChange={isActive => handleChange(isActive, item.id)}
                                                            data-id={`${item.id}`}

                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <DeleteUser userid={item.id} />
                                                </TableCell>
                                            </PrivateRouteVisible>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                </PerfectScrollbar>
            </Card>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme='dark'
                pauseOnHover
            />
        </div>
    );
}

export default RenderUserList;
