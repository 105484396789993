import { host, token } from '../../../../Services/Connnection'
import { ColumnsParams } from '../Models/Columns';

export class ApiServiceDataGridFilter {

    async GetColumnValues(params: ColumnsParams): Promise<string[]> {
        let url = new URL(`${host}/api/AdvancedProduct/GetColumnValues`);
        const response = await fetch(url.href, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'content-disposition': 'attachment',
            },
            body: JSON.stringify(params)
        });
        return await response.json()
    }

}