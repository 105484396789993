import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Paper, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useNavigate } from 'react-router-dom'
import { CustomNoRowsOverlay } from '../../common/NoRowsOverLay';
import renderData from '../../data/wareHouse/SearchProductListData';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Checkbox } from '@mui/material';
import { Helmet } from 'react-helmet'


export default function QuickFilteringGrid() {
  const navigate = useNavigate();

  const searchStorage = JSON.parse(window.localStorage.getItem('searchQuery'));
  let [search, setSearch] = React.useState(searchStorage);
  let [rows, setRows] = React.useState([]);
  let [buttonSearch, setButtonSearch] = React.useState('');
  let [defaultSearch, setDefaultSearch] = React.useState('');

  const inputRef = React.useRef();

  const [pageSize, setPageSize] = React.useState(50);
  const hasBackTick = search.includes('`')
  var fields = search.split('`');

  React.useEffect(() => {
    inputRef.current?.focus();
  });

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  const handleChange = (event) => {
    setDefaultSearch(
      event.target.value
    );
  };

  React.useEffect(() => {
    document.body.addEventListener('keyup', function (event) {
      if (event) {
        inputRef.current?.focus();
      }
      if (event.key === '`') {
        setDefaultSearch('')
      }
    });


    if (hasBackTick === true) setDefaultSearch(fields.slice(-1)[0]); setSearch(fields.slice(-1)[0])
    renderData(search).then(function (data) {
      setRows(data)

      if (search !== undefined && hasBackTick === false) {
        localStorage.setItem('searchQuery', JSON.stringify(search));

        if (hasBackTick === true) {
          localStorage.setItem('searchQuery', JSON.stringify(defaultSearch))
        }
      } else {
        localStorage.setItem('searchQuery', '"0"')
      }

      const prodCompleted = data.find(elem => elem.ean === search)
      if (searchStorage !== search && prodCompleted.warehouseDataProcessed === false) {
        if (data.length === 1) { navigate(`/warehouse/edit/${data[0]['id']}`) }
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Helmet>
        <title>Wyszukiwarka produktów | Panel Grupa Krawczyk</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: 1,
          display: "flex",
          flexDirection: "column"
        }}>
        <Paper
          sx={{
            mb: 2,
            boxShadow: "0"
          }}>

          <TextField
            sx={{
              width: "100%",
              border: "0",
              pr: 0,
            }}
            onChange={(ev) => {
              setButtonSearch(ev.target.value);
              handleChange(ev)
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                setSearch(ev.target.value)
              }
            }}
            value={defaultSearch}
            placeholder="Wprowadź numer ean, symbol lub nazwę produktu"
            autoFocus
            variant="outlined"
            size="small"
            inputRef={setTextInputRef}
            name="searchField"
            InputProps={{
              startAdornment:
                <ContentPasteSearchIcon
                  fontSize="small"
                  sx={{
                    mr: 2
                  }}
                />,
              endAdornment:
                <Button
                  color="primary"
                  value={buttonSearch}
                  onClick={(ev) => {
                    setSearch(ev.currentTarget.value)
                  }}
                >
                  <SearchIcon />
                </Button>
            }}
          />

        </Paper>
        <DataGridPro
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          pagination
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          onRowClick={(params, event) => {
            navigate(`/warehouse/edit/${params.row.id}`)
          }}
          sx={{
            backgroundColor: "#fff",
          }}
          rows={rows}
          columns={columns}
          rowHeight={70}
        />
      </Box>
    </Box>
  );
}

const IsChecked = (params) => {

  return (
    <Checkbox
      icon={<CheckCircleOutlineOutlinedIcon />}
      checkedIcon={<CheckCircleIcon sx={{ color: 'green' }} />}
      defaultChecked={params.row.warehouseDataProcessed}
    />
  );
};

let columns = [
  { field: 'id', headerName: 'id', minWidth: 70, flex: 1, hide: 'true' },
  {
    field: 'warehouseDataProcessed',
    headerName: 'Gotowy',
    width: 80,
    type: 'boolean',
    renderCell: IsChecked
  },
  { field: 'ean', headerName: 'EAN', minWidth: 70, flex: 1 },
  { field: 'symbol', headerName: 'Symbol', minWidth: 70, flex: 1 },
  { field: 'name', headerName: 'Nazwa produktu', minWidth: 70, flex: 1 },
]