import React from 'react';
import HttpIcon from '@mui/icons-material/Http';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Box,
    Typography,
    Button
} from '@mui/material';
import { ProductDataService } from '../../services/dataServices/ProductDataService.tsx';

let formatter = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
});



const Cell = ({ marketplace }) => {
    const Product = new ProductDataService();

    const { id, marketplaceName, marketplaceType, price, quantity, url } = marketplace
    return (
        <TableRow
            key={id}
        >
            <TableCell>{marketplaceName}</TableCell>
            <TableCell>{marketplaceType}</TableCell>
            <TableCell>{formatter.format(price)}</TableCell>
            <TableCell>{quantity}</TableCell>
            <TableCell>
                <IconButton href={url}  >
                    <HttpIcon />
                </IconButton>
            </TableCell>
            <TableCell>
                <Button onClick={() => Product.deleteMarketplace(id)}>Usuń</Button>
            </TableCell>
        </TableRow>
    )
}

const MarketplaceListComponent = ({ data }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Marketplace</TableCell>
                        <TableCell>Nazwa konta</TableCell>
                        <TableCell>Cena</TableCell>
                        <TableCell>Ilość</TableCell>
                        <TableCell>Url</TableCell>
                        <TableCell>Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data?.marketplaces?.length !== 0) ? (
                        data?.marketplaces?.map((marketplace) => (
                            <Cell key={marketplace?.id} marketplace={marketplace} />
                        ))
                    ) : (
                        <Box>
                            <Typography component="p" sx={{ p: 2 }}>Nie znaleziono wyników 😟</Typography>
                        </Box>
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export {
    MarketplaceListComponent
}