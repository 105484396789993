import { TextField, Autocomplete, Grid } from "@mui/material";
import React from "react";
import {
  // FieldType,
  IFormBuilder,
  //  Type
} from "./IFormBuilder";

const FieldGenerator = ({ formBuilder, handleChange, row }: any) => {
  return (
    <>
      {formBuilder.map((d: any): any => {

        if (d.visible) {
          switch (d.fieldType) {
            case "text":
              return (
                <Grid key={d.inputName} item xs={6}>
                  <TextField
                    name={d.inputName}
                    label={d.inptLabel}
                    value={row[d.inputName]}
                    sx={{ width: "100%" }}
                    variant="standard"
                    onChange={(event) => handleChange(event)}
                  />
                </Grid>
              );
            case "autocomplete":
              return (
                <Grid key={d.inputName} item xs={6}>
                  <Autocomplete
                    onChange={(event, newValue: any) =>
                      handleChange(event, newValue.id, d.inputName)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name={d.inputName}
                        value={row.inputName}
                        label={d.inptLabel}
                        variant="standard"
                      />
                    )}
                    options={d?.values}
                  />
                </Grid>
              );

            default:
              return <div key={d.inputName}></div>;
          }
        } else {
          <></>;
        }
      })}
    </>
  );
};

export default function FormBuilder({
  formBuilder,
  handleChange,
  row,
}: {
  formBuilder: IFormBuilder[];
  handleChange: any;
  row: any;
}): JSX.Element {
  return (
    <Grid container spacing={2}>
      <FieldGenerator
        formBuilder={formBuilder}
        handleChange={handleChange}
        row={row}
      />
    </Grid>
  );
}
