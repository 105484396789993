import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import TradeTermInput from './tradeTermInput';
import TradeTermList from './tradeTermList';
import { getTradeTermData } from '../../../data/suppliers/tradeTerm/tradeTermData'
import EditModal from './editModal';
import addTradeTerm from '../../../Services/suppliers/tradeTerm/addTradeTerm';


let closeModalState
export function closeModal(state) {
    closeModalState(false)
}

let modalOpen
export function isOpen(state) {
    modalOpen(state)
}

let paramData
export function getData(state) {
  paramData(state)
}

let re;
export function refresh(state) {
  re(state)
}

export default function TradeTermLayout() {
    const [data, setData] = React.useState([]);
    const [modalData, setModalData] = React.useState([]);
    const [r, setR] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    closeModalState = setOpen
    re = setR
    modalOpen = setOpen;
    paramData = setModalData;
    React.useEffect(() => {
        getTradeTermData().then((d) => {
            setData(d)
        })
        setR(false)
    }, [r])


    const refresh = (ev) => {
        setR(true)
    }

    return (
        <Paper sx={{ height: '100%', my: 2, p: 2 }}>
            <EditModal isOpen={open} modalData={modalData} />
            <Grid
                spacing={2}
                container
            >
                <Grid item xs={12}>
                    <Typography variant='h6' component="h3" >Dodaj nową grupę rabatową</Typography>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        Wypełnij podstawowe dane
                    </Typography>
                </Grid>

                <TradeTermInput type={'add'} refreshList={refresh} fetch={addTradeTerm}/>
                <Grid item xs={12} >
                    <TradeTermList data={data} />
                </Grid>
            </Grid>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme='dark'
                pauseOnHover
            />
        </Paper>

    )
}

