import React from 'react'
import { TextField, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context'

export default function AddNewSymbols() {
  const { loading, handleAddSymbols, addNewSymbol, symbols } = React.useContext(CeneoPrincipleContext);

  return (
    <Grid
      sx={{ p: 2 }}
      container
    >
      <Grid item xs={12}  sx={{ pb: 2 }}>
        {/* <Typography variant='h6' component="h3">Dodaj nowe symbole</Typography> */}
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          W celu dodania wielu symboli, każdą pozycję należy umieścić w osobnej lini.
        </Typography>
      </Grid>
      <Grid item xs={12} className='mobile-100'>
        <TextField
          variant="outlined"
          size="large"
          onChange={addNewSymbol}
          sx={{ width: '100%' }}
          multiline={true}
          rows={4}
        />
      </Grid>
      <Grid item xs={12} sx={{ pr: 2, pt: 2 }} className='mobile-100'>
        <LoadingButton
          size="large"
          color="primary"
          onClick={() => { handleAddSymbols(symbols) }}
          loading={loading}
          loadingPosition="end"
          endIcon={<SendIcon />}
          variant="contained"
        >
          Zapisz listę
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
