import React from "react";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import HttpIcon from '@mui/icons-material/Http';
import { IsChecked } from "../../Components/ProductGrid/ProcessedStateChecked";
import { Button } from "@mui/material";
import DataGridPriceHistoryPopup from '../../../../shared/components/PriceHistoryPopup'
import { DataNotFound, FontBold, DataError } from '../../../../shared/components/DataGridStyledComponents'

const filterOperators = getGridStringOperators().filter(
    (operator) => operator.value === 'contains' || operator.value === 'equals',
);

const LinkButton = (params) => {
    return (
        (params.formattedValue) ? (
            <Button
                sx={{ border: "0", color: "#000" }
                }
                variant="text"
                onClick={() => {
                    window.open(params.formattedValue);
                }}
            >
                <HttpIcon />
            </Button >
        ) : ('Brak linku'))

};

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}
function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}

const NoData = (params) => {
    return <DataNotFound> Brak danych </DataNotFound>
}
let PriceFormatter = (params) => {
    return new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: (params?.row?.currency) ? params?.row?.currency : 'PLN',
    });
}

const PriceFormat = (params) => {
    if (params?.formattedValue !== 0)
        return <FontBold> {PriceFormatter(params)?.format(params?.formattedValue)} </FontBold>
    return PriceFormatter(params)?.format(params?.formattedValue)
}

const ProcentAlert = (params) => {
    if (params.formattedValue >= 50)
        return <DataError>{params.formattedValue}%</DataError>
}

const CheckValue = (params) => {
    if (params?.formattedValue !== null && params?.formattedValue !== undefined && params?.formattedValue !== "") {
        if (params.field.includes('Price'))
            return <PriceFormat {...params} />
        if (params.field.includes('Cost'))
            return <PriceFormat {...params} />
        if (params.field.includes('lengthCm') || params.field.includes('heightCm') || params.field.includes('widthCm'))
            return `${params?.formattedValue} cm`
        if (params.field.includes('Grams'))
            return `${params?.formattedValue} kg`
        if (params.field.includes('Quantity') || params.field.includes('quantity'))
            return `${params?.formattedValue} szt.`
        if (params.field.includes('taxRate') || params.field.includes('rebate'))
            return `${params?.formattedValue}%`
        if (params.field.includes('Change'))
            return <ProcentAlert>{params?.formattedValue} %</ ProcentAlert>
        return params?.formattedValue
    } else {
        return <NoData {...params} />
    }

}

let columns = [
    {
        field: 'processed',
        headerName: 'Gotowy',
        minWidth: 90,
        type: 'boolean',
        filterOperators: filterOperators,
        renderCell: (params) => <IsChecked {...params} />
    },
    {
        field: 'historicalData',
        headerName: 'Hisotria cen',
        minWidth: 90,
        filterOperators: filterOperators,
        renderCell: (params) => <DataGridPriceHistoryPopup {...params} />
    },
    {
        field: 'websiteId',
        headerName: 'websiteId',
        minWidth: 70,
        filterOperators: filterOperators,
    },
    {
        field: 'websiteName',
        headerName: 'Nazwa strony',
        minWidth: 120,
        filterOperators: filterOperators,
    },
    {
        field: 'id',
        headerName: 'ID',
        minWidth: 70,
        filterOperators: filterOperators,
    },
    {
        field: 'dateAdded',
        headerName: 'Data utworzenia',
        minWidth: 160,
        type: 'date',
        filterOperators: filterOperators,
        valueGetter: (params) => formatDate(new Date(params.row.dateAdded))
    },
    {
        field: 'symbol',
        headerName: 'Symbol',
        minWidth: 80,
        filterOperators: filterOperators,
        renderCell: (params) => <CheckValue {...params} />
    },
    {
        field: 'suppierSymbol1',
        headerName: 'Symbol dostawcy #1',
        minWidth: 80,
        filterOperators: filterOperators,
        renderCell: (params) => <CheckValue {...params} />
    },
    {
        field: 'suppierSymbol2',
        headerName: 'Symbol dostawcy #2',
        minWidth: 80,
        filterOperators: filterOperators,
        renderCell: (params) => <CheckValue {...params} />
    },
    {
        field: 'ean',
        headerName: 'EAN',
        minWidth: 80,
        renderCell: (params) => <CheckValue {...params} />,
        filterOperators: filterOperators
    },
    {
        field: 'symbolAdditional',
        headerName: 'Symbol dodatkowy #1',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'symbolIs',
        headerName: 'Symbol isprzet #1',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'gtinCode',
        headerName: 'Symbol GTIN',
        minWidth: 80,
        renderCell: (params) => <CheckValue {...params} />,
        filterOperators: filterOperators
    },
    {
        field: 'cnCode',
        headerName: 'Symbol CN',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'pkwiuCode',
        headerName: 'Symbol PKWIU',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'productImageUrl',
        headerName: 'Zdjęcie',
        minWidth: 80,
        filterOperators: filterOperators,
        renderCell: (params) => <LinkButton {...params} />
    },
    {
        field: 'name',
        headerName: 'PL - Nazwa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 250,
        filterOperators: filterOperators
    },
    {
        field: 'nameEn',
        headerName: 'EN - Nazwa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 250,
        filterOperators: filterOperators
    },
    {
        field: 'extraNamePl1',
        headerName: 'PL - Dodatkowa nazwa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 250,
        filterOperators: filterOperators
    },
    {
        field: 'extraNameEn1',
        headerName: 'EN - Dodatkowa nazwa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 250,
        filterOperators: filterOperators
    },
    {
        field: 'extraNameEn2',
        headerName: 'EN - Dodatkowa nazwa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 250,
        filterOperators: filterOperators
    },

    {
        field: 'shortDescriptionPl',
        headerName: 'PL - Krótki opis',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 200,
        filterOperators: filterOperators
    },
    {
        field: 'extraDescriptionPl',
        headerName: 'PL - Dodatkowy opis',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 200,
        filterOperators: filterOperators
    },
    {
        field: 'shortDescriptionEn',
        headerName: 'EN - Krótki opis',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 200,
        filterOperators: filterOperators
    },
    {
        field: 'extraDescriptionEn',
        headerName: 'EN - Dodatkowy opis',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 200,
        filterOperators: filterOperators
    },
    {
        field: 'stockQuantity',
        headerName: 'Ilość dostępnych produktów',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 30,
        filterOperators: filterOperators
    },
    {
        field: 'stockName',
        headerName: 'Nazwa magazynu',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 220,
        filterOperators: filterOperators
    },
    {
        field: 'minimumOrderQuantity',
        headerName: 'Minimalna ilość zamówienia',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 30,
        filterOperators: filterOperators
    },
    {
        field: 'expectedAvailabilityName',
        headerName: 'Przewidywana dostępność',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 220,
        filterOperators: filterOperators
    },
    {
        field: 'expectedAvailabilityDate',
        headerName: 'Przewidywana dostępność: data',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'dateDelivery',
        headerName: 'Data dostarczenia',
        minWidth: 80,
        filterOperators: filterOperators
    },
    {
        field: 'currency',
        headerName: 'Waluta',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 20,
        filterOperators: filterOperators
    },
    {
        field: 'taxRate',
        headerName: 'VAT',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 20,
        filterOperators: filterOperators
    },
    {
        field: 'rebate',
        headerName: 'Upust',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 30,
        filterOperators: filterOperators
    },
    {
        field: 'discountGroup',
        headerName: 'Grupa rabatowa',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 30,
        filterOperators: filterOperators
    },
    {
        field: 'quantityBulkPackage',
        headerName: 'Ilość w opakowaniu zbiorczym',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'nettoWeightGrams',
        headerName: 'Waga netto (kg)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'grossWeightGrams',
        headerName: 'Waga brutto (gram)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'lengthCm',
        headerName: 'Długość (cm)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'widthCm',
        headerName: 'Szerokość (cm)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'heightCm',
        headerName: 'Wysokość (cm)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'category',
        headerName: 'Kategoria',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'badge',
        headerName: 'Flaga',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'brand',
        headerName: 'Marka',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 40,
        filterOperators: filterOperators
    },
    {
        field: 'isDiscontinued',
        headerName: 'Wycofany',
        minWidth: 40,
        type: 'boolean',
        filterOperators: filterOperators
    },
    {
        field: 'shippingCost',
        headerName: 'Koszty dostawy (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'listPriceNet',
        headerName: 'Cena katalogowa (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'listPriceGross',
        headerName: 'Cena katalogowa (brutto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'listPriceNetChange',
        headerName: 'Zmiana ceny katalogowej',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'regularMarketPriceNet',
        headerName: 'Cena detaliczna (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'regularMarketPriceGross',
        headerName: 'Cena detaliczna (brutto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'regularMarketPriceNetChange',
        headerName: 'Zmiana ceny detalicznej',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'discountPriceNet',
        headerName: 'Cena promocyjna (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'discountPriceGross',
        headerName: 'Cena promocyjna (brutto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'discountPriceNetChange',
        headerName: 'Zmiana ceny promocyjnej',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'wholesalePriceNet',
        headerName: 'Cena hurtowa (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'wholesalePriceGross',
        headerName: 'Cena hurtowa (brutto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'wholesalePriceNetChange',
        headerName: 'Zmiana ceny hurtowej',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'purchasePriceNet',
        headerName: 'Cena zakupu (netto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'purchasePriceGross',
        headerName: 'Cena zakupu (brutto)',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'purchasePriceNetChange',
        headerName: 'Zmiana ceny zakupu',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'promotion',
        headerName: 'Promocja',
        minWidth: 120,
        type: 'boolean',
        filterOperators: filterOperators
    },
    {
        field: 'productReplacementName',
        headerName: 'Nazwa zamiennika',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    {
        field: 'productReplacementSymbol',
        headerName: 'Symbol zamiennika',
        renderCell: (params) => <CheckValue {...params} />,
        minWidth: 120,
        filterOperators: filterOperators
    },
    
    {
        field: "productLinkPl",
        headerName: "Link PL",
        minWidth: 120,
        filterOperators: filterOperators,
        renderCell: (params) => <LinkButton {...params} />
    },
    {
        field: "productLinkEn",
        headerName: "Link EN",
        minWidth: 120,
        filterOperators: filterOperators,
        renderCell: (params) => <LinkButton {...params} />
    },
];

export {
    columns
}