import { IsChecked } from '../../components/productBundles/elements/isActiveCheckbox';
import { StockInPill } from '../../components/productBundles/elements/stockPill';
import { DeleteButton } from '../../components/productBundles/elements/deleteButton'


function getCreationTime(params){
    return (new Date(params.row.creationTime)).toLocaleDateString('pl-PL')
}

function getLastModificationTime(params){
    return (new Date(params.row.lastModificationTime)).toLocaleDateString('pl-PL')
}

export const columns = [
    {
        field: 'isActive',
        headerName: 'Aktywny',
        width: 80,
        renderCell: IsChecked
    },
    {
        field: 'calculatedStock',
        headerName: 'Ilość utworzonych zestawów',
        width: 120,
        renderCell: StockInPill
    },
    { field: 'name', headerName: 'Nazwa produktu', width: 300, flex: 1 },
    { field: 'symbol', headerName: 'Symbol', width: 200, flex: 1 },
    { field: 'brand', headerName: 'Marka', width: 120, flex: 1 },
    {
        field: 'creationTime',
        headerName: 'Data utworzenia',
        width: 200,
        flex: 1,
        valueGetter: getCreationTime,
    },
    {
        field: 'lastModificationTime',
        headerName: 'Data modyfikacji',
        width: 200,
        flex: 1,
        valueGetter: getLastModificationTime,
    },
    {
        field: 'delteBundle',
        headerName: 'Usuń ',
        width: 60,
        renderCell: DeleteButton
    },
];
