const pageNumberDTO = (number) => {
    const pageNumberDTO = {};
    pageNumberDTO.pageNumber = number;
    return pageNumberDTO
}

const pageSizeDTO = (number) => {
    const pageSizeDTO = {};
    pageSizeDTO.pageSize = number;
    return pageSizeDTO
}

const sortModelDTO = (model) => {
    const sortModelDTO = {};
    sortModelDTO.orderBy = model.orderBy
    sortModelDTO.orderDescending = model.orderDescending
    return sortModelDTO
}

const translateOperator = (operator) => {
    switch (operator) {
        case 'contains':
            return 'Contain'
        case 'equals':
            return 'Exact'
    }
    return ''
}

const filterFetch = (filterValue) => {
    let filters = [];
    if (filterValue) {
        (filterValue.items?.length > 0) &&
            filterValue.items.forEach((data) => {
                let object = {};
                object.column = data.columnField
                object.filter = data.value
                object.filterType = translateOperator(data.operatorValue)
                filters.push(object)
            })
    }
    return filters
}

export const searchDto = (search, pageNumber, pageSize, sortModel, filterObject, checked) => {
    let searchDTO = []
    searchDTO.name = (checked?.name) ? search : ''
    searchDTO.symbol = (checked?.symbol) ? search : ''
    searchDTO.additionalSymbol = (checked?.additionalSymbol) ? search : ''
    searchDTO.brand = (checked?.brand) ? search : ''
    searchDTO.ean = (checked?.ean) ? search : ''
    searchDTO.exactSearch = (checked?.exactSearch) && true
    searchDTO.createdSource = ''
    searchDTO.orderBy = ''
    searchDTO.orderDescending = false
    searchDTO.pageSize = 50
    searchDTO.filters = filterFetch(filterObject)
    return {
        ...searchDTO,
        ...pageNumberDTO(pageNumber),
        ...pageSizeDTO(pageSize),
        ...sortModelDTO(sortModel),
    }
}
