import React from 'react';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { CustomViews } from '../../../../shared/components/DataGridToolbar/customView/customViews.dispatch'
import DataGridToolbarSelectAll from './_dataGridToolbarSelectAll'
import DataGridToolbarExport from '../../../../shared/components/DataGridToolbarExport/DataGridToolbarExport';

export const ToolBarCustom = React.forwardRef((props, ref) => {
    return (
        <GridToolbarContainer
            ref={ref}
            sx={{
                color: '#000'
            }}>
            <CustomViews page="productHunter" />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <DataGridToolbarSelectAll />
            <DataGridToolbarExport />
        </GridToolbarContainer>
    );
});
