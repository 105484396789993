import React, { useState } from 'react';
import { ConditionHeader, Rule } from '../../style/rulesBuilder.styled';
import ActionsHeader from './ActionsHeader';
import { ActionsWhenCondition, AllowedActions } from '../../interfaces/integrations';
import ActionsForm from './ActionsForm';

interface ActionProps {
  actions: ActionsWhenCondition;
  integrationId: string | undefined;
  ruleId: number;
  actionsSchema: AllowedActions[] | undefined;
  onDelete: (deleteActionsOnConditionsMet: any, id: number, actions: any) => void;
  onSave: (editAction: any, addAction: any, data: any, onEdit: any) => void;
  onChange: any;
  type: string
}

function Actions(data: ActionProps) {
  const [isEdit, setIsEdit] = useState(false);

  const { onDelete, onSave, actions, ruleId, integrationId, actionsSchema, onChange, type } = data;

  const handleEdit = () => {
    setIsEdit((prev) => !prev);
  }



  return (
    <Rule index={0}>
      <ConditionHeader>
        <ActionsHeader
          actions={actions}
          onEdit={handleEdit}
          onSave={onSave}
          onDelete={onDelete}
          isEdit={isEdit}
          integrationId={integrationId}
          ruleId={ruleId}
          type={type}
          schema={actionsSchema}
        />
      </ConditionHeader>
      {isEdit && (
        <React.Fragment>
          <ActionsForm actionsSchema={actionsSchema} actions={actions} onChange={onChange} />
        </React.Fragment>
      )}
    </Rule>
  );
}

export default Actions;