import React from 'react'
import { IntegrationProvider } from '../../context/integrations/integration.context';
import RulesBuilder from '../../components/integrations/dragNdrop/RulesBuilder';

export default function IntegrationBuilder() {
   
    return (
        <IntegrationProvider>
            <RulesBuilder />
        </IntegrationProvider>
    )
}
