import * as React from 'react';
import { Box, Paper, TextField } from '@mui/material';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
import renderData from '../../data/productBundles/getProductBundlesData';
import AddNewBundleBtn from './addNewBundleBtn'
import SearchIcon from '@mui/icons-material/Search';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import DetailPanelContent from './bundleDetail';
import { columns } from '../../data/productBundles/bundleListColumns'
import { productBundleSettings } from '../../data/localStorage'
import LinearProgress from '@mui/material/LinearProgress';
import { Helmet } from 'react-helmet'

export let isLoadingState;
export let isDelete;

export function checkIfDelete(state) {
  isDelete(state)
}

let totalCount;
export function totalCountFunc(count) {
  totalCount(count)
 
}

let fetchLoad;
export function fetchLoading(state) {
  fetchLoad(state)
}

export default function FormDetailPanel() {
  const [rows, setRows] = React.useState([]);
  const [pageSize, setPageSize] = React.useState((productBundleSettings) ? productBundleSettings.pageSize : 20);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [search, setSearch] = React.useState('')
  const [deleteState, setDeleteState] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearched, setIsearched] = React.useState(false);
  let [totalCountFuncState, setTotalCountFunc] = React.useState();


  fetchLoad = setIsLoading
  totalCount = setTotalCountFunc
  isDelete = setDeleteState
  isLoadingState = setLoading

  const pageSettings = {
    'pageSize': pageSize
  }
  let skipCount = pageNumber * pageSize


  localStorage.setItem('productBundle', JSON.stringify(pageSettings))


  React.useEffect(() => {
    renderData(search, pageSize, skipCount, true).then((data) => {
      setRows(data)
      setDeleteState(false)
    })
    setIsLoading(true)
    setIsearched(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearched, deleteState, loading, pageSize, pageNumber])

 

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 750, []);

  


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Helmet>
        <title>Zestawy produktowe | Panel Grupa Krawczyk</title>
      </Helmet>
      <Paper sx={{ mb: 2, boxShadow: 'unset' }}>
        <TextField
          sx={{
            width: "100%",
            border: "0",
            pr: 0
          }}
          onChange={(ev) => {
            setSearch(ev.target.value)
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              setSearch(ev.target.value.trim())
              setIsearched(true)
            }
          }}
          placeholder="Wprowadź nazwę lub id zestawu"
          autoFocus
          value={search}
          name="searchField"
          InputProps={{
            startAdornment:
              <ContentPasteSearchIcon
                fontSize="small"
                sx={{
                  mr: 2
                }}
              />,
            endAdornment:
              <Button
                color="primary"
                value={search}
                onClick={(ev) => {
                  setSearch(ev.currentTarget.value.trim())
                  setIsearched(true)
                }}
              >
                <SearchIcon />
              </Button>
          }}
        />
      </Paper>
      <Box
        sx={{
          height: '100%',

        }}>
        <DataGridPro
          sx={{

            backgroundColor: '#fff',
          }}
          columns={columns}
          rows={rows}
          disableExtendRowFullWidth={false}
          pageSize={pageSize}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rowCount={totalCountFuncState}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          rowsPerPageOptions={[20, 50, 100, 200]}
          onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          pagination
          paginationMode="server"
          rowThreshold={0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
        <AddNewBundleBtn />
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme='dark'
        pauseOnHover
      />
    </Box>
  );
}

