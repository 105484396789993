import { token, bundleHost } from '../Connnection';
import { toast } from 'react-toastify';

export default function updateBundle(dataObject, bundleId) {

    fetch(`${bundleHost}/api/app/bundle/${bundleId}`, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })
        .then(resp => {
            setTimeout(() => {
                if (resp.status === 403) toast.warn("Taki zestaw już istnieje! 😠")
            }, 500)
        })
        .catch((err) =>
            console.log(err)
        )
}
