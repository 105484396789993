import { token, host } from '../../Connnection';


export default function deleteStatusRange(statusId) {

    fetch(`${host}/api/Websites/Status/Delete`, {
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(statusId)
    }).then((response) => {
        if (response.ok) {
         
          
        } else {
            // toast.error("Coś poszło nie tak.");
        }
    })
}