import getProduct from '../../Services/wareHouse/GetProduct';

export default async function renderData(prodId) {
    let getDataProd = await getProduct(prodId);

    let row = {};
    row.id = getDataProd.id
    row.name = getDataProd.name
    row.symbol = getDataProd.symbol
    row.description = getDataProd.description
    row.additionalSymbol = getDataProd.additionalSymbol
    row.ean = getDataProd.ean
    row.brand = getDataProd.brand
    row.isWithdrawn = getDataProd.isWithdrawn
    row.height = getDataProd.height
    row.width = getDataProd.width
    row.length = getDataProd.length
    row.weight = getDataProd.weight
    row.carriers = getDataProd.carriers
    row.createdDate = getDataProd.createdDate
    row.hasSerialNumber = getDataProd.hasSerialNumber
    row.hasValidityPeriod = getDataProd.hasValidityPeriod
    row.productReadyToShip = getDataProd.productReadyToShip
    row.stickers = getDataProd.stickers
    row.warehouseDataProcessed = getDataProd.warehouseDataProcessed
    return row;
}