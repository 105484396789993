import { token, host } from '../../Connnection';
import { ImportId } from '../../../Pages/ProductManago-Import/AccordionResult';
import { GetImportId } from './prototypes/getImportId';

export default function uploadFile(file) {
    let form = new FormData();
    form.append('File', file);

    fetch(`${host}/api/Upload/UploadFile`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
        },
        body: form
    })
        .then(resp => {
            resp.json().then((data) => {
                ImportId(new GetImportId(data.id, data.name, data.uploadedDate))
            })
        })
        .catch((err) =>
            console.log(err)
        )

}
