import React from 'react';
import { Helmet } from 'react-helmet'
import { Box, Paper, Typography, Grid, Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import WebsiteRangeList from './websiteRangeList'
import { websiteDetailed } from '../../../data/dashboard/websiteDiki'
import { useParams } from 'react-router-dom'
import updateWebsite from '../../../Services/settings/productHunter/updateWebsite'
import putProductRange from '../../../Services/settings/productHunter/putProductRange'
import NewRange from './newRange'

let del;
export function deleteItem(state) {
    del(state)
}

let save;
export function saveItemState(state) {
    save(state)
}

let edit;
export function editItemState(state) {
    edit(state)
}

function WebsiteSettingsEdit(props) {
    const urlParam = useParams()
    const [data, setData] = React.useState({ 'checkAvailability': false, 'statusRanges': [] });
    const [saveItem, setSaveItem] = React.useState(false);
    const [deleteRange, setDeleteRange] = React.useState(false);
    const [editState, setEditState] = React.useState();

    edit = setEditState
    save = setSaveItem
    del = setDeleteRange;
    React.useEffect(() => {
        websiteDetailed(urlParam.id).then((data) => {
            setData(data[0])
            setSaveItem(false)
            setEditState(false)
        })
        setDeleteRange(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveItem, deleteRange])

    let updateWebsiteObj = {
        'websiteId': urlParam.id,
        'websiteName': data.label,
        'checkAvailability': !data.checkAvailability,
    }

    const handleChangeActive = (event) => {
        setData({
            ...data,
            ['checkAvailability']: event.target.checked
        });
        updateWebsite(updateWebsiteObj)
    }

    return (
        <Box sx={{ height: '100%', mt: 1 }}>
            <Helmet>Edytuj parametry | Panel Grupa Krawczyk</Helmet>
            <Paper sx={{ height: '100%', p: 3 }}>
                <Grid
                    spacing={2}
                    container

                >
                    <Grid item xs={12}>
                        <Typography variant='h6' component="h3" >{data.label}</Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            Ustawienia ogólne
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(data) ? data.checkAvailability : 0}
                                    onChange={(ev) => handleChangeActive(ev)}
                                />
                            }
                            label={
                                "Włącz synchronizację"
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' component="h3" >Terminy realizacji</Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            Wprowadź przedziały
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        <NewRange
                        request={putProductRange}
                        type={"Add"}
                        buttonVal={'Dodaj'}
                        />
                        <Grid item xs={12}>
                            <Grid item sx={{ maxWidth: '900px' }}>
                                <WebsiteRangeList statusRange={data.statusRanges} isEdited={editState} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box >
    );
}

export default WebsiteSettingsEdit;