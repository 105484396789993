import * as React from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SkeletonBoxes } from './skeletonBoxes.js';



export function CheckedProducts(props) {
 
  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      {props.loading === false ? (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item
              sx={{ width: '70%' }}
            >

              <Grid sx={{
                whiteSpace: 'nowrap',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>

                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  Ilość Sprawdzonych produktów
                </Typography>

              </Grid>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {props.data.productsProcessed}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: 'success.main',
                  height: 56,
                  width: 56
                }}
              >
                <CheckCircleIcon />
              </Avatar>
            </Grid>
          </Grid>
          <Box
            sx={{
              pt: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography
              color="textSecondary"
              variant="caption"
            >
              Skaner produktów
            </Typography>
          </Box>
        </CardContent>) : (
        <Box sx={{ width: '100%' }}>
          <SkeletonBoxes />
        </Box>
      )}
    </Card>
  )
};