import React from 'react'
import { useParams } from "react-router-dom";
import {
  useGetAvailableRules
} from '../../api/integrations/integration.list';
import {
  Grid,
  //  TextField,
  Typography
} from '@mui/material';
import Conditions from './conditions';
import ActionsMet from './ActionsMet';
import { IntegrationRules } from '../../interfaces/integrations'
import AddNewRule from './addNewRule';
import { confirmAlert } from 'react-confirm-alert';
import useAlerts from '../../hooks/useAlerts.hook';
import ActionsNotMet from './ActionsNotMet';

interface RuleProps {
  rules: IntegrationRules,
}

export default React.memo(function IntegrationRulesModule({ rules: rule }: RuleProps) {
  const { id: integrationId } = useParams();
  const { data: schema, isLoading } = useGetAvailableRules(integrationId);
  const [condition, setCondition] = React.useState(rule.conditions)
  const alerts = useAlerts();

  React.useEffect(() => {
    setCondition(rule.conditions)
  }, [rule.conditions])
  let property: any;
  let type: any;

  const handleChangeCondition = React.useCallback((e: any, id: number, name?: string) => {
    if (e.target?.name === "property")
      property = schema?.allowedConditions.find((d: any) => d.name === e.target.value)?.allowedOperations
    if (e.target?.name === "operator") {
      type = property.find((d: any) => d.operator.name === e.target.value)?.type
    }
    console.log(e)
    console.log(name)
    setCondition(prevConditions => {
      const indexToEdit = prevConditions.findIndex(item => item.id === id);
      if (indexToEdit === -1) {
        return prevConditions;
      }
      const updatedObject = {
        ...prevConditions[indexToEdit],
        ...(name !== "Time" && {
          [e.target.name]: e.target.value,
          value: (e.target.name === 'value') && e.target.value,
        }),
        ...(name === "Time" && {
          name: e.$d,
          value: (name === 'Time') && e.$d,
        }),
        ...(type && {
          type: type
        })

      };
      const updatedConditions = [
        ...prevConditions.slice(0, indexToEdit),
        updatedObject,
        ...prevConditions.slice(indexToEdit + 1)
      ];
      return updatedConditions;
    });
  }, [schema]);

  const handleAddCondition = React.useCallback(() => {
    const newCondition = {
      id: Date.now(),
      property: "",
      operator: "",
      type: "Decimal",
      value: "",
      isNew: true
    };
    setCondition([
      ...condition,
      newCondition
    ])
  }, [condition])

  const handleDeleteCondition = React.useCallback((deleteCondition: any, data: any) => {
    confirmAlert({
      title: '',
      message: 'Czy na pewno chcesz usunąć ten warunek?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => {
            if (!data.isNew) {
              deleteCondition.mutateAsync("").then(() => alerts?.onSuccess("Pomyślnie usunięto")).catch((err: any) => alerts?.onError(`Wystąpił błąd podczas usuwania: ${err}`));
              setCondition(condition.filter(obj => obj.id !== data.id));
            } else {
              alerts?.onSuccess('Pomyślnie usunięto');

              setCondition(condition.filter(obj => obj.id !== data.id));
            }
          },
        },
        {
          label: 'Nie',
          onClick: () => null
        }
      ]
    },
    );
  }, [condition])

  const handleSave = React.useCallback(async (data: any, editRule: any, addCondition: any, onEdit: () => void) => {
    onEdit()
    const index: any = condition.find((w: any) => w.id === data.id)
    if (index?.isNew === undefined)
      editRule.mutateAsync({ ...index }).then(() => alerts?.onSuccess("Pomyślnie edytowano")).catch((err: any) => alerts?.onError(`Wystąpił błąd podczas zapisu: ${err}`));
    if (index.isNew) {
      const { data: apiData, status } = await addCondition.mutateAsync({ ...index })
      if (status === 201) {
        alerts?.onSuccess("Pomyślnie zapisano")
        delete index.isNew
        setCondition(prevConditions => {
          const indexToEdit = prevConditions.findIndex(item => item.id === data.id);
          if (indexToEdit === -1) {
            return prevConditions;
          }
          const updatedObject = {
            ...prevConditions[indexToEdit],
            ...apiData
          };
          const updatedConditions = [
            ...prevConditions.slice(0, indexToEdit),
            updatedObject,
            ...prevConditions.slice(indexToEdit + 1)
          ];
          return updatedConditions;
        });
      }
      else {
        alerts?.onError(`Wystąpił błąd podczas zapisu: ${data}`)
      }
    }
  }, [condition])

  if (isLoading) return <>Wczytywanie ...</>
  return (
    <Grid container sx={{ p: 2, borderRadius: 1, mt: 1, }}>
      <Grid item xs={6} >
        <Typography variant='subtitle2' component="h6" sx={{ fontWeight: 700 }}>Warunki</Typography>
        {condition?.map((c, index: number) =>
          <React.Fragment key={index}>
            <Conditions index={index} ruleId={rule.id} conditions={c} schema={schema?.allowedConditions} onDelete={handleDeleteCondition} onChange={handleChangeCondition} onSave={handleSave} />
          </React.Fragment>
        )}
        <AddNewRule onAddCondition={handleAddCondition} innerText="Nowy warunek" />
      </Grid>
      <Grid item xs={6}>
        <ActionsMet
          actionsMet={rule.actionsWhenConditionsMet}
          actionsSchema={schema?.allowedActions}
          integrationId={integrationId}
          ruleId={rule.id}
        />
        <ActionsNotMet
          actionsNotMet={rule.actionsWhenConditionsAreNotMet}
          actionsSchema={schema?.allowedActions}
          integrationId={integrationId}
          ruleId={rule.id} />
      </Grid>
    </Grid >
  )
})
