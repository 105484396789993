import getDataWebsiteDiki from '../../Services/productHunter/WebsiteDictionaryService'


export async function promiseCategory() {
  let getWebsiteObject = await getDataWebsiteDiki(false, null);
  var categoryData = [];
  let websiteName = getWebsiteObject.websites
  websiteName.forEach(name => {
    let row = {};
    row.id = name.id
    row.label = name.websiteName
    row.latestProductDate = name.latestProductDate
    row.showNotification = false
    categoryData.push(row);
  });
  return categoryData
}


export async function websiteDetailed(urlParam) {
  let getWebsiteObject = await getDataWebsiteDiki(true, urlParam);
  var detailedData = [];
  let websiteName = getWebsiteObject.websites
  websiteName.forEach(name => {
    let row = {};
    row.id = name.id
    row.label = name.websiteName
    row.checkAvailability = name.checkAvailability
    row.statusRanges = name.statusRanges
    detailedData.push(row);
  });
  return detailedData
}