import React from 'react'
import { Box } from '@mui/material';
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context';


export default function CustomToolbar() {
    const { HandleRowDelete } = React.useContext(CeneoPrincipleContext);

    return (
        <Box sx={{ p: 2 }}>
            <HandleRowDelete massDelete />
        </Box>

    )
}
