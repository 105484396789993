// import { ProductList } from '../../models/ProductList';
import { ProductSearch } from '../../models/ProductsSerach';
import { host, token } from '../../../../Services/Connnection'
import { IProductManagoApiService } from './IApiService';

export class ProductManagoApiService implements IProductManagoApiService {

    async ExportProducts(_params: ProductSearch): Promise<string> {
        let url = new URL(`${host}/api/Product/ExportProducts`);
        const response = await fetch(url.href, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'content-disposition': 'attachment',
            },
            body: JSON.stringify(_params)
        });
        return await response.text()
    }

}

