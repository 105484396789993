import * as React from 'react';
import { FormControlLabel, FormHelperText, FormControl, Checkbox, TextField, Typography, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { principleForm } from './config/principleForm';
import { CeneoPrincipleContext } from './context/ceneoPrinciple.context'
import SendIcon from '@mui/icons-material/Send';


export default function PrincipleAdd(props) {
    const { updatePrinciple, newPrinciple, handleSave, handleEdit, loading } = React.useContext(CeneoPrincipleContext);
    const [isEdit, setIsEdit] = React.useState(true)


    const editMode = props.edit
    const handleChange = (ev, attr) => {
        updatePrinciple({
            ...newPrinciple,
            [ev.target.name]:
                (attr === 'text-field') ?
                    ((ev.target.name === 'priceBelow' || ev.target.name === 'maxDayAvailability') && ev.target.value === "") ? null : ev.target.value :
                    ev.target.checked
        })
    }

    let isRequired = (d, req) => {
        if (d?.validation?.required === true) {
            if (newPrinciple?.[d?.attribute] !== '' && newPrinciple?.[d?.attribute] !== 0) return { isValid: false, message: "" }
            return { isValid: true, message: "To pole jest wymagane" }
        }
    }

    const disableBtn = () => {
        if (newPrinciple?.['name'] !== ''
            && (newPrinciple?.['priceGross'] !== 0
                && newPrinciple?.['priceGross'] !== '')
            && newPrinciple?.['stock'] !== 0
            && newPrinciple?.['stock'] !== ''
            // eslint-disable-next-line no-mixed-operators
            && newPrinciple?.['ceneoBuyNow']
            // eslint-disable-next-line no-mixed-operators
            || newPrinciple?.['googleMarchent']
        ) return false
        return true
    }


    const checkboxDisabled = (d) => {
        if (newPrinciple?.ceneoBuyNow === true) return { googleMarchent: true }
        if (newPrinciple?.googleMarchent === true) return { ceneoBuyNow: true }
    }

    const disabledCheckBox = checkboxDisabled()

    return (
        <Grid
            sx={{ p: 2 }}
            container
        >
            <Grid xs={6}>
                <Typography variant='h6' component="h3" sx={{ pb: 2 }}>{(editMode) ? 'Edytuj regułę' : 'Wprowadź nową regułę'}</Typography>
            </Grid>

            {(editMode) && (
                <Grid xs={6} sx={{ textAlign: 'right' }}>
                    <Button
                        // size="small"
                        color="primary"
                        variant="outlined"
                        onClick={() => { setIsEdit(!isEdit) }}
                    >
                        {(!isEdit) ? 'Wyłącz edycję' : 'Edytuj'}
                    </Button>
                </Grid>
            )}

            {(principleForm.map((d) => (
                (d.component === "text-field") ? (
                    <Grid key={d.attribute} item {...d?.col} sx={{ pr: 2 }} className='mobile-100'>
                        <TextField
                            fullWidth

                            // error={(isRequired(d)?.isValid)}
                            helperText={isRequired(d)?.message}
                            type={d?.type}
                            label={d.label}
                            name={d.attribute}
                            size="small"
                            value={
                                (editMode) && newPrinciple[d?.attribute]
                            }
                            disabled={(editMode) && isEdit}
                            onChange={(ev) => handleChange(ev, d.component)}
                            sx={{ my: 1 }}
                        />
                    </Grid>
                ) : (d.component === "checkbox-group") && (
                    <Grid key={d.attribute} item {...d?.col} sx={{ pr: 2 }} className='mobile-100'>
                        <FormControl
                        // error={!(newPrinciple?.['ceneoBuyNow'] || newPrinciple?.['googleMarchent']) ? true : false}
                        >
                            <FormControlLabel control={
                                <Checkbox
                                    name={d?.attribute}
                                    checked={(editMode) && newPrinciple[d?.attribute]}
                                    disabled={(editMode) ? isEdit || disabledCheckBox?.[d?.attribute] : disabledCheckBox?.[d?.attribute]}
                                    onChange={(ev) => {
                                        handleChange(ev, d.component);
                                    }}
                                />
                            }
                                label={d.label} />
                            {(!editMode) && (d.attribute === "ceneoBuyNow" || d.attribute === "googleMarchent") && (
                                <FormHelperText>
                                    {!(newPrinciple?.['ceneoBuyNow'] || newPrinciple?.['googleMarchent']) && "Zaznacz jedną opcję"}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                )
            )))}

            <Grid item xs={12} sx={{ pr: 2, pt: 2 }} className='mobile-100'>
                <LoadingButton
                    size="large"
                    color="primary"
                    onClick={() => { (editMode) ? handleEdit() : handleSave() }}
                    loading={loading}
                    loadingPosition="end"
                    disabled={(editMode) ? isEdit || disableBtn() : disableBtn()}
                    endIcon={<SendIcon />}
                    variant="contained"
                >
                    {(editMode) ? 'Zapisz ustawienia reguły' : 'Dodaj nową regułę'}
                </LoadingButton>
            </Grid>
        </Grid >

    )
}
