import * as React from 'react';
import Typography from '@mui/material/Typography';
import { popupClose } from './websiteRangeList'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { style } from '../../../common/modalStyle'
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import NewRange from './newRange'
import updateRange from '../../../Services/settings/productHunter/updateRange'


export default function CustomizedDialogs(props) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(props.isOpen)
    }, [props])

    const handleClose = () => {
        setOpen(false);
        popupClose(false)
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ backgroundColor: '#1e1f21', color: '#fff', p: 2 }}>
                            <Typography variant="h6" component="h2">
                                Edytuj zakres
                            </Typography>
                        </Box>
                        <Box sx={{ p: 4 }}>
                            <NewRange
                                id={props.statusRangeId}
                                qtyMin={props.quantityMin}
                                qtyMax={props.quantityMax}
                                nameAvai={props.nameAvailability}
                                modalOpen={open}
                                buttonVal={'Edytuj'}
                                request={updateRange}
                                type={'Edit'}
                            />
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}