import * as React from 'react';
import { Button } from '@mui/material'
import postData from '../../../../Services/integration/ceneoPrinciple/postData'
import getList from '../../../../Services/integration/ceneoPrinciple/getData'
import getListById from '../../../../Services/integration/ceneoPrinciple/getDataById'
import deleteData from '../../../../Services/integration/ceneoPrinciple/deletePrinciple'
import putData from '../../../../Services/integration/ceneoPrinciple/putData'
import patchSymbols from '../../../../Services/integration/ceneoPrinciple/patchSymbols'
import DeleteRowGrid from '../../../../Services/integration/ceneoPrinciple/gridRowDelete'
import { toast } from 'react-toastify';


export const CeneoPrincipleContext = React.createContext(null);

const CeneoPrincipleProvider = ({ children }) => {

    let defaultCheck = {
        name: '',
        priceGross: 0,
        stock: 0,
        maxDayAvailability: null,
        priceBelow: null,
        ceneoOnlyInStock: false,
        ceneoBuyNow: false,
        googleMarchent: false,
        isActive: false,
    }

    const [newPrinciple, setNewPrinciple] = React.useState(defaultCheck);
    const [principleList, setPrincipleList] = React.useState([]);
    const [modal, setModal] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [modalId, setModalId] = React.useState(0)
    const [symbols, setSymbols] = React.useState([])
    const [selectedRows, setSelectedRows] = React.useState();

    const addNewSymbol = (ev) => {
        var spliced = ev.target.value.split("\n")
        setSymbols(spliced)
    }

    const handleModal = (state, currentId) => {
        setModal(state)
        if (state === false) { setNewPrinciple(defaultCheck) }
        setModalId(currentId)
        let active = true;
        (async () => {

            if (state)
                getListById(currentId).then((d) => {
                    setNewPrinciple(d)
                })

            if (!active) {
                return;
            }

        })();
        return () => {
            active = false;
        };
    }

    const updatePrinciple = (getData) => {
        setNewPrinciple(getData);
    };

    const handleListRefresh = () => {
        getList().then((d) => {
            setPrincipleList(d?.principleCheckboxes)
        })
    }

    React.useEffect(() => {
        handleListRefresh()
    }, []);

    const handleSave = async () => {
        let active = true;

        (async () => {
            setLoading(true);
            await postData(newPrinciple).then((data) => {
                (data?.status === 404 || data?.status === 400) ?
                    toast.error(`Wystąpił błąd: ${data.title}`) :
                    toast.success("Pomyślnie dodano regułę")
            })

            if (!active) {
                return;
            }
            handleListRefresh()
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }

    const handleEdit = () => {
        let active = true;
        let thisIsObject = Object.keys(newPrinciple).filter(key =>
            key !== 'symbols').reduce((obj, key) => {
                obj[key] = newPrinciple[key];
                return obj;
            }, {}
            );
        (async () => {
            setLoading(true);

            await putData(thisIsObject).then((data) => {
                (data?.status === 404 || data?.status === 400) ?
                    toast.error(`Wystąpił błąd: ${data.title}`) :
                    toast.success("Pomyślnie edytowano regułę")
            })

            if (!active) {
                return;
            }
            handleListRefresh()
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }


    const handleDeletePrinciple = (principleID) => {
        let active = true;
        (async () => {
            await deleteData({ id: principleID }).then((data) => {
                (data?.status === 404 || data?.status === 400) ?
                    toast.error(`Wystąpił błąd: ${data.title}`) :
                    toast.success("Pomyślnie usunięto regułę")
            })
            let filtered = principleList.filter(function (value, index, arr) {
                return value.id !== principleID;
            });
            if (!active) {
                return;
            }
            setPrincipleList(filtered)
        })();

        return () => {
            active = false;
        };

    }

    function addDelSymbols(id, symbols) {
        this.id = id
        this.symbols = symbols
    }

    const refreshModalDG = () => {
        getListById(modalId).then((d) => {
            setNewPrinciple(d)
        })
    }
    const handleAddSymbols = (data) => {
        let active = true;


        (async () => {
            await patchSymbols(new addDelSymbols(modalId, data)).then((data) => {
                (data?.status === 404 || data?.status === 400) ?
                    toast.error(`Wystąpił błąd: ${data.title}`) :
                    toast.success("Pomyślnie wprowadzono symbole")
            })

            if (!active) {
                return;
            }
            refreshModalDG()
        })();

        return () => {
            active = false;
        };
    }



    function datagridSymbols(principleId, symbols) {
        this.principleId = principleId
        this.symbols = symbols
    }

    const handleSelectedSymbols = (data) => {
        let symbols = []
        data?.forEach(d => {
            symbols.push(d?.symbol)
        })
        setSelectedRows(symbols)
    }



    const HandleRowDelete = (params) => {
        const deleteRecord = (data) => {
            let active = true;
            (async () => {
                await DeleteRowGrid(new datagridSymbols(modalId, (params.massDelete) ? data : [data.symbol])).then((data) => {
                    (data?.status === 404 || data?.status === 400) ?
                        toast.error(`Wystąpił błąd: ${data.title}`) :
                        toast.success((params.massDelete) ? "Pomyślnie usunięto symbole" : "Pomyślnie usunięto symbol")
                })
                if (!active) {
                    return;
                }
                refreshModalDG();
            })();

            return () => {
                active = false;
            }
        }

        return (
            <Button
                variant={(params.massDelete) ? "contained" : "text"}
                onClick={() => {
                    (params.massDelete) ?
                        deleteRecord(selectedRows) :
                        deleteRecord(params.row)
                }}
            >{(params.massDelete) ? "Usuń zaznaczone" : "Usuń"}</Button>
        )
    }

    return (
        <CeneoPrincipleContext.Provider value={{
            newPrinciple,
            updatePrinciple,
            handleSave,
            handleEdit,
            loading,
            principleList,
            handleDeletePrinciple,
            modal,
            handleModal,
            handleAddSymbols,
            addNewSymbol,
            symbols,
            HandleRowDelete,
            handleSelectedSymbols,
        }}>
            {children}
        </CeneoPrincipleContext.Provider>
    );
};

export default CeneoPrincipleProvider;