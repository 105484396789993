import React from 'react'
import SupplierListGrid from '../../../shared/components/DiscountingSystem/SupplierListGrid'
import SupplierListSerachBar from '../../../shared/components/DiscountingSystem/SupplierListSerachBar';
import { DataGridModelData } from './models/DataGridModelData';
import { Supplier } from './models/Supplier';
import { SupplierListDataService } from './services/DataService/SupplierListDataService';

const DataService = new SupplierListDataService();

export default function SupplierList() {
  const [suppliers, setSuppliers] = React.useState<DataGridModelData>({ "rows": [], "cols": [] })
  const [searchValue, setSearchValue] = React.useState<string>('')

  React.useEffect(() => {
    let active = true;
    (async () => {
      let supplierList = await DataService.getSuppliers(searchValue);
      let columns = DataService.getColumnsConfig();
      if (!active) {
        return
      }
      setSuppliers({ 'rows': supplierList, 'cols': columns })
    })()
    return () => {
      active = false
    }
  }, [searchValue])

  const handleSearch = (value: string): void => {
    setSearchValue(value)
  }

  const handleAdd = (_data: Supplier): void => {
    let supplierList = DataService.refreshSupplierList();
    setSuppliers({ ...suppliers, 'rows': supplierList })
  }

  return (
    <>
      <SupplierListSerachBar handleSearch={handleSearch} />
      <div style={{ display: 'flex', height: 'calc(100% - 56px)', background: "#fff", borderRadius: '10px' }}>
        <div style={{ flexGrow: 1 }}>
          <SupplierListGrid data={suppliers} handleAdd={handleAdd} />
        </div>
      </div>
    </>
  )
}
