import getTradeTerm from '../../../Services/suppliers/tradeTerm/getTradeTerms'


export async function getTradeTermData() {
    let fetch = await getTradeTerm();

    let a = []
    fetch.forEach((d) => {
        function translate() {

            // eslint-disable-next-line default-case
            switch (d.calculationType.name) {
                case 'DealersPriceRebate':
                    return 'Od ceny hurtowej'
                case 'ListPriceRebate':
                    return 'Od ceny katalogowej'
            }
        }

        let o = {}
        o.id = d.id
        o.name = d.name
        o['supplierId'] = d.supplier.id
        o['supplier.name'] = d.supplier.name
        o['supplier.nip'] = d.supplier.nip
        o['calculationType'] = d.calculationType.name
        o['calculationType.label'] = translate()
        o.percentageValue = d.percentageValue
        a.push(o)
    })

    return a
}