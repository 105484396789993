import * as React from 'react';
import { Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { menuItems } from '../../config/menuItems';
import {
  StyledTab,
  a11yProps,
  TabPanel
} from '../../utils/tabPanel.tsx'
import RenderForm from '../../services/FormGeneratorServices/formGenerator'


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function TabLayout({ productData }) {

  let navigate = useNavigate();
  let query = useQuery();
  let tabId = query.get("tabid")

  const [value, setValue] = React.useState(tabId - 1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigate = (index) => {
    navigate(`?tabid=${index}`, { replace: true });
  }

  return (
    <div style={{ display: 'flex', height: '100%', marginTop: '0px', background: "#fff", borderRadius: '10px' }}>
      <div style={{ flexGrow: 1 }}>

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="Product Edit Tab Panel"
          sx={{
            display: "grid",
            margin: 0,
            borderRadius: "10px",
            background: "#f7f7f7"
          }}
        >
          {menuItems.map(data => (
            <StyledTab key={data.id} label={data.label} onClick={(e) => handleNavigate(data.id)} {...a11yProps(data.id)} />
          ))}
        </Tabs>

        {menuItems.map((data) => (
          <TabPanel key={data.id} value={value} index={data.id - 1} >
            <RenderForm attr={data.attribute} header={data?.header} productData={productData} />
          </TabPanel>
        ))}
        {/* <CustomFabs value={value} action={handleFabAction} /> */}
      </div>
    </div>

  );
}

