import { token, bundleHost } from '../Connnection';
import { toast } from 'react-toastify';
import { bundleAdded } from '../../components/productBundles/bundleStepperContainer'

export default function saveNewBundle(dataObject) {

    fetch(`${bundleHost}/api/app/bundle`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
    })
        .then(resp => {
            if (resp.status === 200) bundleAdded(resp.status)
            resp.json().then((data) => { bundleAdded({ 'message': data.error.message, 'code': data.error.code, 'status': resp.status }) })

            setTimeout(() => {
                if (resp.status === 400) {
                    toast.warn("Błąd! 😠 Uzupełnij wszystkie wymagane pola");
                }
                if (resp.status === 204) {
                    toast.success("Zaktualizowano pomyślnie 🎉");
                }

            }, 500)
        })

        .catch((err) =>
            console.log(err)
        )
}
