import React from 'react'
import { Id, OrderBox } from '../../style/rulesBuilder.styled'
import ClearIcon from '@mui/icons-material/Clear';
import { IntegrationRules } from '../../interfaces/integrations';
import { IconButton, Avatar, Chip, FormControlLabel, Switch } from '@mui/material';
import {
    useDeleteRule, useGetIntegrationRulesStatus,
    useEditIntegrationRulesStatus
} from '../../api/integrations/integration.list';
import { AxiosResponse } from 'axios';
import { UseMutationResult } from 'react-query';
import useAlerts from '../../hooks/useAlerts.hook';
import { confirmAlert } from 'react-confirm-alert';

interface RuleProps {
    integrationId: string | undefined;
    item: IntegrationRules,
    onDelete: (id: number, deleteRule: UseMutationResult<AxiosResponse<any, any>>) => void;
}


export default function RuleHeaderInner({ integrationId, item, onDelete }: RuleProps) {
    const deleteRule = useDeleteRule(integrationId, item.id, (oldData, newData) => [...oldData, newData])
    const editRuleStatus = useEditIntegrationRulesStatus(integrationId, item.id, (oldData, newData) => [...oldData, newData])
    const { isLoading, isSuccess, data } = useGetIntegrationRulesStatus(integrationId, item.id)
    const [active, setActive] = React.useState<any>(false);
    const alerts = useAlerts();

    React.useEffect(() => {
        setActive(data?.disabled)
    }, [isSuccess])

    const handleChange = React.useCallback(() => {
        const newValue = !active;
        confirmAlert({
            message: `Czy na pewno chcesz ${active ? "włączyć" : "wyłączyć"} ten warunek?`,
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => {
                        setActive(newValue);
                        editRuleStatus
                            .mutateAsync({ disabled: newValue })
                            .then(() => alerts?.onSuccess(`Pomyślnie ${active ? "włączono" : "wyłączono"} warunek`))
                            .catch((err: any) => alerts?.onError(`Wystąpił błąd: ${err}`));
                    }
                },
                {
                    label: 'Nie',
                    onClick: () => null
                }
            ]
        });

    }, [active]);

    return (
        <>
            <Id>
                <Chip avatar={<Avatar sx={{ background: '#ecf7ed', width: 18, height: 18, fontSize: '.9rem' }}>
                    {item.order}
                </Avatar>} label="Priorytet" />
            </Id>
            <OrderBox>
                {!isLoading ? (<FormControlLabel
                    label={`Warunek ${!active ? "aktywny" : "nieaktywny"}`}
                    control={
                        <Switch
                            checked={!active}
                            onChange={handleChange}
                        />
                    }
                />) : (<>Wczytywanie</>)}

                <IconButton color="primary" aria-label="delete rule" onClick={() => onDelete(item.id, deleteRule)}>
                    <ClearIcon />
                </IconButton>
            </OrderBox>

        </>
    )
}
